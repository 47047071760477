import { CountryResponse, EmployeesResponse, LocationResponse, SizeResponse, WeekResponse } from '~/models/CommonModel';
import { constants } from '~/utils/constants';
export class CommonService {
    private rootURL = `${constants.API_URL}/MasterData/Common`;

    public async getListLocation(): Promise<LocationResponse | null> {
        const url = `${this.rootURL}/list-dropdown-project`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as LocationResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // list employees
    public async getListEmployees(kw?: string): Promise<EmployeesResponse | null> {
        const url = `${this.rootURL}/list-dropdown-sale-ee?${kw ? `kw=${kw}` : ''}`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as EmployeesResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // list size
    public async getListSize(): Promise<SizeResponse | null> {
        const url = `${this.rootURL}/list-dropdown-size`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as SizeResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // list week
    public async getListWeek(keyword?: number): Promise<WeekResponse | null> {
        const url = `${this.rootURL}/list-dropdown-week-of-year?${keyword ? `keyword=${keyword}` : ''}`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as WeekResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // list employees
    public async getListCountry(keyword?: string): Promise<CountryResponse | null> {
        const url = `${this.rootURL}/list-dropdown-country?${keyword ? `keyword=${keyword}` : ''}`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as CountryResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
}
