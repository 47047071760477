import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Select } from 'antd';
import mock from '~/pages/Dashboard/mock';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import classNames from 'classnames/bind';
import { formatNumber } from '~/utils/formatHelper';
import { Line } from '@ant-design/charts';
import { EmployeeModel, LocationModel } from '~/models/CommonModel';
import { ProductService } from '~/services/ProductService';
import { NoOfEnquiriesByMonthModel } from '~/models/TypeChartModel';
import { DatePicker, Space } from 'antd';
import CollapseWrapper from '../CollapseWrapper';

import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';

const { RangePicker } = DatePicker;

const month = [
    {
        value: 'January',
        key: 1,
    },
    {
        value: 'February',
        key: 2,
    },
    {
        value: 'March',
        key: 3,
    },
    {
        value: 'April',
        key: 4,
    },
    {
        value: 'May',
        key: 5,
    },
    {
        value: 'June',
        key: 6,
    },
    {
        value: 'July',
        key: 7,
    },
    {
        value: 'August',
        key: 8,
    },
    {
        value: 'September',
        key: 9,
    },
    {
        value: 'October',
        key: 10,
    },
    {
        value: 'November',
        key: 11,
    },
    {
        value: 'December',
        key: 12,
    },
];

interface ChartEnquiryByMonthProps {
    title: string;
    listEmployees: EmployeeModel[];
    listLocation: LocationModel[];
    onChange: (value: string) => Promise<void>;
    handleGetValuePIC: (
        keyPIC: string,
        dateCompleteFrom: string | null,
        dateCompleteTo: string | null,
        project: string | null,
    ) => Promise<void>;
}

const ChartEnquiryByMonth = (props: ChartEnquiryByMonthProps) => {
    const cx = classNames.bind(style);
    const { title, listEmployees, listLocation, onChange, handleGetValuePIC } = props;
    const [noOfEnquiriesByMonth, setNoOfEnquiriesByMonth] = useState<NoOfEnquiriesByMonthModel[]>([]);
    const [keyPIC, setKeyPIC] = useState('');
    // const [keyMonth, setKeyMonth] = useState<number[]>([]);
    const [fromMonth, setFromMonth] = useState('');
    const [toMonth, setToMonth] = useState('');
    // Thời gian hoàn thành
    const [dateCompleteFrom, setdateCompleteFrom] = useState<string | null>(null);
    const [dateCompleteTo, setdateCompleteTo] = useState<string | null>(null);

    // From date => to date (sử dụng cho chart, chỉ áp dụng cho chart này)
    const [dateFrom, setdateFrom] = useState<string | null>(dateCompleteFrom);
    const [dateTo, setdateTo] = useState<string | null>(dateCompleteTo);
    // Project
    const [project, setProject] = useState<string | null>(null);

    // const [ListEmployeeSearch, setListEmployeeSearch] = useState<EmployeeModel[]>([]);
    const colorDefaults = ['#274822', '#90a955', '#4f772d', '#DFDBD6', '#A5A5A5'];
    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    };
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const config11 = {
        // data: mock.NoOfQueriesByMonth,
        data: noOfEnquiriesByMonth,
        xField: 'month',
        isStack: true,
        yField: 'value',
        seriesField: 'type',
        // color: ['#4472C4', '#ED7D31'],
        color: colorDefaults,
        // padding: 20,
        appendPadding: [10, 0, 0, 0],
        yAxis: {
            label: {
                style: {
                    fontSize: windowSize.innerWidth > 2500 ? 25 : 15,
                    fill: 'black',
                },
            },
        },
        xAxis: {
            label: {
                style: {
                    fontSize: windowSize.innerWidth > 2500 ? 25 : 15,
                    fill: 'black',
                },
            },
        },
        label: {
            layout: [
                {
                    type: 'hide-overlap',
                },
            ],
            style: {
                fontSize: windowSize.innerWidth > 2500 ? 25 : 15,
                textAlign: 'right',
            },
            formatter: (item: any) => item.value,
        },
        meta: {
            value: {
                formatter: (v: any) => {
                    return `${formatNumber(v, 0)}`;
                },
            },
        },
    };

    var option: EChartsOption;
    //   Option của chart
    option = {
        color: colorDefaults,
        tooltip: {
            trigger: 'axis',

            textStyle: {
                fontFamily: 'inherit',
                fontSize: 13,
            },
            borderRadius: 3,
            borderWidth: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 1,
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#DFDBD6',
                    color: 'black',
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 400,
                },
            },
            // Custom chart sử dụng className (tailwind)
            // className:
            //     '[&>div>:nth-child(1)>:nth-child(2)>div>:nth-child(1)>:nth-child(1)]:!rounded-[1px] ' +
            //     '[&>div>:nth-child(1)>:nth-child(2)>div>:nth-child(1)>:nth-child(1)]:!w-[19px] ' +
            //     '[&>div>:nth-child(1)>:nth-child(2)>div>:nth-child(1)>:nth-child(1)]:!h-[12px] ' +
            //     '[&>div>:nth-child(1)>:nth-child(1)]:!font-semibold ' +
            //     '[&>div>:nth-child(1)>:nth-child(1)]:!text-textColor',
        },

        legend: {
            textStyle: {
                fontFamily: 'Brown',
                fontSize: 13,
                color: 'black',
                fontWeight: 'bold',
            },
            right: '2%',
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: noOfEnquiriesByMonth.length ? Array.from(new Set(noOfEnquiriesByMonth.map((i) => i.month))) : [],
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                fontSize: 13,
                fontFamily: 'Brown',
                fontWeight: 400,
                color: '#77838F',
                lineHeight: 12,
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                show: false,
                fontSize: 13,
                fontFamily: 'Brown',
                fontWeight: 400,
                color: '#77838F',
                lineHeight: 12,
            },
        },

        series: [
            {
                name: noOfEnquiriesByMonth.length ? noOfEnquiriesByMonth[0].type : '',
                // type: 'line',
                // symbolSize: 8,
                // symbol: 'circle',
                type: 'bar',
                stack: 'one',
                itemStyle: {
                    borderColor: '#FFFFFF',
                    borderWidth: 1,
                },

                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                label: {
                    show: true,
                    position: 'inside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: '#CCC',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
                // data: [2620935140, 96332874002, 2007326110],
                data: noOfEnquiriesByMonth.length
                    ? noOfEnquiriesByMonth.filter((v) => v.type === noOfEnquiriesByMonth[0].type).map((i) => i.value)
                    : [],
            },
            {
                name: noOfEnquiriesByMonth.length ? noOfEnquiriesByMonth[1].type : '',
                // type: 'line',
                // symbolSize: 8,
                // symbol: 'circle',
                type: 'bar',
                stack: 'one',
                itemStyle: {
                    borderColor: '#FFFFFF',
                    borderWidth: 1,
                },

                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                label: {
                    show: true,
                    position: 'inside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: '#4A4F57',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
                // data: [2620935140, 96332874002, 2007326110],
                data: noOfEnquiriesByMonth.length
                    ? noOfEnquiriesByMonth.filter((v) => v.type === noOfEnquiriesByMonth[1].type).map((i) => i.value)
                    : [],
            },
        ],
    };

    // FUNCTIONS ==================
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const handleChangeMonth = (
        value: string,
        option:
            | {
                value: string;
                key: number;
            }
            | {
                value: string;
                key: number;
            }[],
    ) => {
        if (Array.isArray(option)) {
            // const test = option.map((month) => {
            // });
            // setKeyMonth(
            //     option
            //         .map((item) => {
            //             return item.key;
            //         })
            //         .sort(function (a, b) {
            //             return a - b;
            //         }),
            // );
        }
    };

    const handleChangeEmployees = (value: string, option: EmployeeModel | EmployeeModel[]) => {
        if (!Array.isArray(option)) {
            setKeyPIC(option.key);
            handleGetValuePIC(option.key, dateCompleteFrom, dateCompleteTo, project);
        } else {
            setKeyPIC('');
            handleGetValuePIC('', dateCompleteFrom, dateCompleteTo, project);
        }
    };
    const handleChangeSearch = async (value: string) => {
        onChange(value);
    };

    useEffect(() => {
        const getNoOfEnquiriesByMonth = async () => {
            const api = new ProductService();
            const result = await api.getNoOfEnquiriesByMonth({
                pic: keyPIC,
                dateCompleteFrom: dateCompleteFrom,
                dateCompleteTo: dateCompleteTo,
                project: project,
                fromMonth: fromMonth,
                toMonth: toMonth,
            });
            if (result) {
                setNoOfEnquiriesByMonth(result.data);
            }
        };
        getNoOfEnquiriesByMonth();
    }, [keyPIC, fromMonth, toMonth, dateCompleteFrom, dateCompleteTo, project]);

    // set lại value date from to khi dateComplete thay đổi
    // dateComplete lấy làm mặc định cho các chart nếu datefrom/dateTo không chỉ sửa thì lấy mặc định là dateCompleteFrom/to
    useEffect(() => {
        setdateFrom(dateCompleteFrom);
        setdateTo(dateCompleteTo);
    }, [dateCompleteFrom, dateCompleteTo]);

    listEmployees.unshift({ key: '', value: 'All' });

    return (
        <>
            <Grid item xs={12} lg={12} style={{ display: 'flex', gap: '10px' }}>
                <Select
                    showSearch
                    onChange={(value, option) => handleChangeEmployees(value, option)}
                    style={{ width: 200 }}
                    placeholder="Search to Select PIC"
                    autoClearSearchValue={true}
                    onSearch={handleChangeSearch}
                    options={listEmployees}
                    filterOption={false}
                />
                <div>
                    <Select
                        onChange={(value, option) => {
                            setProject(value ? value : null);
                            handleGetValuePIC(keyPIC, dateCompleteFrom, dateCompleteTo, value ? value : null);
                        }}
                        placeholder="Project/Location"
                        style={{ width: 200 }}
                    >
                        <Select.Option label="All" value="">
                            All
                        </Select.Option>
                        {listLocation.map((item, indx) => {
                            return (
                                <Select.Option key={indx} label={item.value} value={item.key}>
                                    {item.value}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                <div>
                    <RangePicker
                        onChange={(values: any, dateStrings) => {
                            setdateCompleteFrom(dateStrings[0] ? dateStrings[0] : null);
                            setdateCompleteTo(dateStrings[1] ? dateStrings[1] : null);
                            handleGetValuePIC(
                                keyPIC,
                                dateStrings[0] ? dateStrings[0] : null,
                                dateStrings[1] ? dateStrings[1] : null,
                                project,
                            );
                        }}
                        placeholder={['From Date', 'To Date']}
                        picker="date"
                        bordered={true}
                        style={{ fontSize: '20px' }}
                    />
                </div>
            </Grid>
            <Grid item xs={12} lg={12}>
                <CollapseWrapper title={title}>
                    <Box paddingTop={'12px'}>
                        {/* <RangePicker
                            onChange={async (values: any, dateStrings) => {
                                setdateFrom(dateStrings[0] ? dateStrings[0] : null);
                                setdateTo(dateStrings[1] ? dateStrings[1] : null);
                                const api = new ProductService();
                                const result = await api.getNoOfEnquiriesByMonth({
                                    pic: keyPIC,
                                    dateCompleteFrom: dateStrings[0] ? dateStrings[0] : dateCompleteFrom,
                                    dateCompleteTo: dateStrings[1] ? dateStrings[1] : dateCompleteTo,
                                    project: project,
                                    fromMonth: fromMonth,
                                    toMonth: toMonth,
                                });
                                if (result) {
                                    setNoOfEnquiriesByMonth(result.data);
                                }
                            }}
                            placeholder={['From Date', 'To Date']}
                            picker="date"
                            bordered={true}
                            value={dateFrom ? [moment(dateFrom), moment(dateTo)] : undefined}
                            style={{ fontSize: '20px' }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        /> */}

                        <ReactECharts option={option} />
                    </Box>
                </CollapseWrapper>
            </Grid>
        </>
    );
};

export default React.memo(ChartEnquiryByMonth);
