import { Box, Grid } from '@mui/material';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import classNames from 'classnames/bind';
import { formatNumber } from '~/utils/formatHelper';
import Table from 'antd/es/table';
import { enquirySiteVisitByYearsModel, enquirySiteVisitByYearsResponse } from '~/models/TypeChartModel';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import { Select } from 'antd';
import CollapseWrapper from '../CollapseWrapper';

interface TableProductEnquirySumProps {
    dataSource: enquirySiteVisitByYearsResponse | undefined;
    columns: ColumnsType<enquirySiteVisitByYearsModel>;
    title: string;
    onChange: (key?: string, newValue?: [number]) => void;
    name: string;
    year: number[];
}

const TableProductEnquirySum = (props: TableProductEnquirySumProps) => {
    const { title, dataSource, columns, onChange, name, year: yearValue } = props;
    const cx = classNames.bind(style);

    // FUNCTIONS ==================

    return (
        <Grid item xs={12} lg={6}>
            <CollapseWrapper title={title}>
                <Box paddingTop={'12px'}>
                    <Table
                        size="small"
                        dataSource={dataSource?.data.enquirySiteVisitByYears}
                        columns={columns}
                        pagination={false}
                        scroll={{ y: 200 }}
                        summary={(pageData) => {
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row className={cx('total-row-dark')}>
                                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>
                                            <div className="text-right">
                                                {formatNumber(dataSource?.data.enquiry, 0, false, true)}
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>
                                            <div className="text-right">
                                                {formatNumber(dataSource?.data.siteVisit, 0, false, true)}
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>
                                            <div className="text-right" style={{ whiteSpace: 'nowrap' }}>
                                                {formatNumber(dataSource?.data.demand, 0, false, true)}
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>
                                            <div className="text-right">
                                                {formatNumber(dataSource?.data.deal, 0, false, true)}
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>
                                            <div className="text-right">{`${formatNumber(
                                                Number(dataSource?.data.rateEnquiry) * 100,
                                                0,
                                                false,
                                                true,
                                            )}%`}</div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>
                                            <div className="text-right">
                                                {`${
                                                    dataSource?.data.rateSite &&
                                                    `${formatNumber(
                                                        Number(dataSource?.data.rateSite) * 100,
                                                        0,
                                                        false,
                                                        true,
                                                    )}%`
                                                }`}
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={7}>
                                            <div className="text-right">
                                                {formatNumber(dataSource?.data.available, 0, false, true)}
                                            </div>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                </Box>
            </CollapseWrapper>
        </Grid>
    );
};

export default React.memo(TableProductEnquirySum);
