import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { DatePicker, Select } from "antd";
import mock from "~/pages/Dashboard/mock";
import style from "../../pages/Dashboard/Dashboard.module.scss";
import classNames from "classnames/bind";
import { Column } from "@ant-design/charts";
import { formatNumber } from "~/utils/formatHelper";
import { CountryModel, EmployeeModel } from "~/models/CommonModel";
import { CommonService } from "~/services/CommonService";
import { ProductService } from "~/services/ProductService";
import { DemandByCountryModel } from "~/models/TypeChartModel";
import CollapseWrapper from "../CollapseWrapper";
import { EChartsOption } from "echarts";
import ReactECharts from "echarts-for-react";
import moment from "moment";
const { Option } = Select;

interface ChartTotalDemandProps {
  title: string;
  listEmployees: EmployeeModel[];
  searchPIC: string | null;
  dateCompleteFrom: string | null;
  dateCompleteTo: string | null;
  project: string | null;
  subTitle?: string;
}

const ChartTotalDemand = (props: ChartTotalDemandProps) => {
  const cx = classNames.bind(style);
  const {
    title,
    listEmployees,
    searchPIC,
    dateCompleteFrom,
    dateCompleteTo,
    project,
    subTitle,
  } = props;

  const [listTotalDemand, setListTotalDemand] = useState<
    DemandByCountryModel[]
  >([]);
  const [listCountry, setListCountry] = useState<CountryModel[]>([]);
  const [keyCountry, setKeyCountry] = useState<string | null>(null);
  const [keyPIC, setKeyPIC] = useState<number | null>(null);
  const colorDefaults = [
    "#BD912D",
    "#ED7D2F",
    "#245E92",
    "#9DC3E5",
    "#F23841",
    "#EFA3A7",
    "#70AD45",
    "#A9D388",
    "#41376b",
    "#b56089",
    "#6538a1",
    "#9753ca",
    "#dfadf6",
    "#047fff",
    "#dff04c",
    "#04ba70",
  ];

  const [currentYears, setCurrentYears] = useState<number[]>([]);

  // From date => to date (sử dụng cho chart, chỉ áp dụng cho chart này)
  const [dateFrom, setdateFrom] = useState<string | null>(null);
  const [dateTo, setdateTo] = useState<string | null>(null);
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };
  const [windowSize, setWindowSize] = useState(getWindowSize());
  // total demand area of the enquiries by marke

  var option: EChartsOption;
  //   Option của chart
  option = {
    color: colorDefaults,
    tooltip: {
      trigger: "axis",

      textStyle: {
        fontFamily: "inherit",
        fontSize: 13,
      },
      borderRadius: 3,
      borderWidth: 0,
      shadowOffsetX: 0,
      shadowOffsetY: 1,
      shadowBlur: 3,
      shadowColor: "rgba(0, 0, 0, 0.25)",
      axisPointer: {
        type: "none",
      },
      // Custom chart sử dụng className (tailwind)
      // className:
      //     '[&>div>:nth-child(1)>:nth-child(2)>div>:nth-child(1)>:nth-child(1)]:!rounded-[1px] ' +
      //     '[&>div>:nth-child(1)>:nth-child(2)>div>:nth-child(1)>:nth-child(1)]:!w-[19px] ' +
      //     '[&>div>:nth-child(1)>:nth-child(2)>div>:nth-child(1)>:nth-child(1)]:!h-[12px] ' +
      //     '[&>div>:nth-child(1)>:nth-child(1)]:!font-semibold ' +
      //     '[&>div>:nth-child(1)>:nth-child(1)]:!text-textColor',
    },
    legend: {
      textStyle: {
        fontFamily: "Brown",
        fontSize: 13,
        color: "black",
        fontWeight: "bold",
      },
      right: 0,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      // boundaryGap: [0, 0.01],
      data: listTotalDemand.length ? listTotalDemand.map((i) => i.name) : [],
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        fontSize: 13,
        fontFamily: "Brown",
        fontWeight: 400,
        color: "#77838F",
        lineHeight: 12,
        hideOverlap: false,
        interval: 0,
        rotate: 30,
      },
    },
    yAxis: {
      type: "value",
      // name: 'Giá trị',
      alignTicks: true,
      axisLine: {
        show: true,
        lineStyle: {
          color: "#F2F2F2",
        },
      },
      axisTick: {
        show: false,
      },
      nameTextStyle: {
        color: "#000",
      },
      axisLabel: {
        show: false,
        fontSize: 13,
        fontFamily: "Brown",
        fontWeight: 400,
        color: "#77838F",
        lineHeight: 12,
      },
    },
    series: [
      {
        // name: 'IPA GLA(sqm)',
        type: "bar",
        // tooltip: {
        //     valueFormatter: function (value) {
        //         return formatNumber(value as number, 0) + '';
        //     },
        // },
        // barWidth: '20%',
        label: {
          show: true,
          position: "outside",
          valueAnimation: true,
          fontFamily: "Brown",
          fontSize: 13,
          fontWeight: 500,
          color: "black",
          formatter: function (value) {
            return formatNumber(value.value as number, 0) + "";
          },
        },
        // data: [292, 952, 320],
        data: listTotalDemand.length ? listTotalDemand : [],
      },
    ],
  };

  // FUNCTIONS ==================
  const handleChange = (value: [number]) => {
    setCurrentYears(value);
  };
  const handleChangeEmployees = (
    value: string,
    option: EmployeeModel | EmployeeModel[]
  ) => {
    if (!Array.isArray(option)) {
      setKeyPIC(Number(option.key));
    }
  };
  const handleChangeCountry = (
    value: string,
    option: CountryModel | CountryModel[]
  ) => {
    if (!Array.isArray(option)) {
      setKeyCountry(option.key);
    }
  };
  const handleChangeSearch = async (value: string) => {
    const api = new CommonService();
    await api.getListEmployees(value);
  };
  const handleChangeSearchCountry = async (value: string) => {
    const api = new CommonService();
    await api.getListCountry(value);
  };
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    const getListCountry = async () => {
      const api = new CommonService();
      const result = await api.getListCountry("");
      if (result) {
        setListCountry(result.data);
      }
    };
    listCountry.unshift({ key: "", value: "All" });

    const getDemandByCountry = async () => {
      const api = new ProductService();
      const result = await api.getDemandByCountry({
        pic: searchPIC,
        dateCompleteFrom: dateCompleteFrom,
        dateCompleteTo: dateCompleteTo,
        project: project,
        countryCode: keyCountry,
        years: currentYears,
      });
      if (result) {
        // setListTotalDemand(result.data);

        const data = result.data;
        data.forEach((d, index) => {
          Object.assign(d, {
            value: d.total,
            name: d.value,
            itemStyle: { color: colorDefaults.at(index) },
          });
        });

        setListTotalDemand(data);
      }
    };
    getDemandByCountry();
    getListCountry();
  }, [
    keyCountry,
    currentYears,
    searchPIC,
    dateCompleteFrom,
    dateCompleteTo,
    project,
  ]);

  // set lại value date from to khi dateComplete thay đổi
  // dateComplete lấy làm mặc định cho các chart nếu datefrom/dateTo không chỉ sửa thì lấy mặc định là dateCompleteFrom/to
  useEffect(() => {
    setdateFrom(dateCompleteFrom);
    setdateTo(dateCompleteTo);
  }, [dateCompleteFrom, dateCompleteTo]);

  return (
    <Grid item xs={12} lg={6}>
      <CollapseWrapper title={title} subtitle={subTitle}>
        <Box paddingTop={"12px"}>
          {/* <div style={{ display: 'flex', gap: '10px' }}>
                       
                        <DatePicker.RangePicker
                            onChange={async (values: any, dateStrings) => {
                                setdateFrom(dateStrings[0] ? dateStrings[0] : null);
                                setdateTo(dateStrings[1] ? dateStrings[1] : null);
                                const api = new ProductService();
                                const result = await api.getDemandByCountry({
                                    pic: searchPIC,
                                    dateCompleteFrom: dateStrings[0] ? dateStrings[0] : dateCompleteFrom,
                                    dateCompleteTo: dateStrings[1] ? dateStrings[1] : dateCompleteTo,
                                    project: project,
                                    countryCode: keyCountry,
                                    years: currentYears,
                                });
                                if (result) {
                                    setListTotalDemand(result.data);
                                }
                            }}
                            placeholder={['From Date', 'To Date']}
                            value={dateFrom ? [moment(dateFrom), moment(dateTo)] : undefined}
                            picker="date"
                            bordered={true}
                            style={{ fontSize: '20px' }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        />
                    </div> */}

          <ReactECharts option={option} />
        </Box>
      </CollapseWrapper>
    </Grid>
  );
};

export default React.memo(ChartTotalDemand);
