import { useEffect, useState } from "react";

import classNames from "classnames/bind";
import style from "./TagDetail.module.scss";
import moment from "moment";

import { Card } from "@mui/material";
import { ProductModel } from "~/models/ProductModel";
import { constants } from "~/utils/constants";
import { Link, useSearchParams } from "react-router-dom";
import { UserAgent } from "@quentin-sommer/react-useragent";

interface TagDetailProps {
  data: ProductModel;
}

const cx = classNames.bind(style);

function TagDetail({ data }: TagDetailProps) {
  const [dataValue, setdataValue] = useState(data);
  const [searchParams] = useSearchParams();

  const param = searchParams.get("IsViewDetail");
  const isViewDetail = param ? JSON.parse(param.toLowerCase()) : false;

  useEffect(() => {
    setdataValue(data);
  }, [data]);

  return (
    <Card
      variant="outlined"
      className={cx("card")}
      style={{ backgroundColor: `${dataValue.color}` }}
    >
      <div className={cx("card-wrapper")}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          }}
          className={cx("content")}
        >
          {/* Client */}
          {/* {dataValue.productStatus !== "Available" && (
            <>
              <span>Client :</span>
              <span>{dataValue.client}</span>
            </>
          )} */}
          {/* GLA */}
          <span>GLA :</span>
          <span>
            {dataValue.gla.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
            sqm
          </span>
          {/* PIC */}
          {/* {dataValue.productStatus !== "Available" && (
            <>
              <span>PIC :</span>
              <span>{dataValue.pic}</span>
            </>
          )} */}
          {/*Handover date   */}
          {dataValue.productStatus === "Handover" && (
            <>
              <span>Handover date :</span>
              <span>
                {dataValue.handoverDate
                  ? moment(dataValue.handoverDate).format("DD/MM/YYYY")
                  : ""}
              </span>
            </>
          )}
          {dataValue.productStatus === "UnderOffer" ||
          dataValue.productStatus === "IPASent" ? (
            <>
              {/*Rent Fee  */}
              {/* <span>Rent Fee :</span>
              <span>{dataValue.rentalFee}</span> */}
              {/* Valid until */}
              {/* <span>Valid until :</span>
              <span>{dataValue.validUntil}</span> */}
            </>
          ) : (
            ""
          )}
          {/* Rental free period */}
          {/* {dataValue.productStatus !== 'Available' && <span>Rental free period :</span>} */}
          <span>Width:</span>
          <span>{dataValue.width ? dataValue.width + " m" : ""}</span>
          <span>Lenght:</span>
          <span>{dataValue.length ? dataValue.length + " m" : ""}</span>
        </div>
      </div>
      <div className={cx("card-footer")}>
        {/* nếu là mobile thì cho nhảy sang trang product */}
        <UserAgent mobile>
          <Link
            to={"/product/" + dataValue.productId}
            className={cx("card-link")}
          >
            View Details
          </Link>
        </UserAgent>
        {/* còn nếu là pc thì nhảy sang trang update item bên web mvc  */}
        <UserAgent computer>
          {isViewDetail === true && (
            <a
              href={`${constants.PRODUCT_DETAILS_URL}${dataValue.productId}`}
              className={cx("card-link")}
            >
              View Details
            </a>
          )}
        </UserAgent>
      </div>
    </Card>
  );
}

export default TagDetail;
