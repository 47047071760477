/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import style from "../../pages/Dashboard/Dashboard.module.scss";
import classNames from "classnames/bind";
import { Card, Col, Row } from "antd";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import topology from "../../pages/Dashboard/dataVN.json";

import pointImage from "../../../src/assets/images/place2.png";
import { formatNumber } from "~/utils/formatHelper";
import TableDetailProjectSummary from "../TableReport/tableDetailProjectSummary";
import { ProductService } from "~/services/ProductService";
import { chartVNModel } from "~/models/ProductModel";

interface ChartMapVNProps {
  title: string;
}

const ChartMapVN = (props: ChartMapVNProps) => {
  const cx = classNames.bind(style);
  const { title } = props;

  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());
  // total landbank
  const [totalLandbank, setTotalLandbank] = useState(0);
  // data chart map
  const [dataChartMapVN, setDataChartMapVN] = useState<chartVNModel[]>([]);

  const data = [
    // [],
    ["vn-hd", 21],
    ["vn-bg", 29],
    ["vn-331", 37],
    ["vn-la", 39],
    ["vn-3623", 40],
  ];

  // Create the chart

  const options = {
    chart: {
      // width: 100 + '%',
      height: windowSize.innerWidth > 2500 ? 1800 + "px" : 450 + "px",
    },
    title: {
      enabled: false,
      text: "",
    },
    plotOptions: {
      series: {
        color: "#90",
      },
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },

    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    colorAxis: {
      min: 1,
      max: 1000,
      type: "logarithmic",
      minColor: "#90a955",
      maxColor: "#274822",
    },

    legend: { enabled: false },

    series: [
      {
        mapData: topology,
        data: data,
        name: "Thành phố",
        states: {
          hover: {
            color: "#6a994e",
          },
        },
        // accessibility: {
        //     point: {
        //         valueDescriptionFormat: '{xDescription}. Lat: {point.lat:.2f}, lon: {point.lon:.2f}.',
        //     },
        // },
        marker: {
          lineWidth: 1,
          lineColor: "#fff",
        },
        dataLabels: {
          enabled: false,
          format: "{point.name}",
        },
        tooltip: {
          headerFormat: "",
          // @ts-ignore
          pointFormatter: function () {
            // @ts-ignore
            return `<b>${this.properties.name}</b>`;
          },
        },
      },
      {
        type: "mappoint",
        name: "Thành phố",

        dataLabels: {
          format: "{point.id}",
          style: {
            color: "#385723",
            fontFamily: "Brown",
          },
        },
        marker: {
          symbol: `url(${pointImage})`,
        },
        tooltip: {
          style: {
            fontWeight: "bold",
            fontFamily: "Brown",
          },

          headerFormat: "",
          // pointFormat: '{point.id} <br /> {point.phases}',
          useHTML: true,
          // @ts-ignore
          pointFormatter: function () {
            // @ts-ignore
            const { options } = this;
            // console.log(options.phases.map((item: any) => item.projectName));

            return `
                        <div style="font-family: 'Brown'">
                          <div> <b>${options.convince}</b></div><br/>
                          ${options.phases.map((item: any) => {
                            return `
                            <b>Phase:</b> ${item.projectName}<br />
                            <b>Land area:</b> ${formatNumber(
                              item.landArea,
                              0
                            )}(sqm)<br />
                            <b>GLA:</b> ${formatNumber(item.gla, 0)}(sqm)<br />
                            <b>Status:</b> ${item.status} <br/> 
                            <b>IPA Signed:</b> ${item.totalIPASigned} <br/> 
                            <b>PLC signed:</b> ${item.totalPLC} <br/> 
                            <b>Occupancy rate:</b> ${
                              item.occupancyRate
                            } <br/> <br />`;
                          })}
                        </div>`;
          },
          shared: true,
        },
        // data: [
        //     {
        //         id: 'Ho Nai - Dong Nai',
        //         convince: 'Dong Nai',
        //         image: 'https://www.clipartmax.com/png/middle/220-2201975_computer-icons-map-pin-clip-art-location-icon-png-gray.png',
        //         lat: 10.939822394632783,
        //         lon: 106.93784952163696,
        //         phases: [
        //             { projectName: 'Ho Nai - EPS', landArea: 15000, gla: 12214, status: 'Operation' },
        //             {
        //                 projectName: 'Ho Nai - Phase 2.1',
        //                 landArea: 75480,
        //                 gla: 50583.52,
        //                 status: 'Under construction',
        //             },
        //             {
        //                 projectName: 'Ho Nai - Phase 2.2',
        //                 landArea: 72447,
        //                 gla: 46363.32,
        //                 status: 'Under construction',
        //             },
        //         ],
        //     },
        //     {
        //         id: 'Nhon Trach - Dong Nai',
        //         convince: 'Dong Nai',
        //         image: 'https://www.clipartmax.com/png/middle/220-2201975_computer-icons-map-pin-clip-art-location-icon-png-gray.png',

        //         lat: 10.71163497703979,
        //         lon: 106.93302154541016,
        //         phases: [
        //             {
        //                 projectName: 'Nhon Trach - Lock & Lock',
        //                 landArea: 69599,
        //                 gla: 41802.8,
        //                 status: 'Operation',
        //             },
        //         ],
        //     },
        //     {
        //         id: 'An Phat - Hai Duong',
        //         convince: 'Hai Duong',
        //         image: 'https://www.clipartmax.com/png/middle/220-2201975_computer-icons-map-pin-clip-art-location-icon-png-gray.png',
        //         lat: 20.937733215864437,
        //         lon: 106.28808975219727,
        //         phases: [
        //             {
        //                 projectName: 'KCN An Phat',
        //                 landArea: 89246,
        //                 gla: 56053.08,
        //                 status: 'Undeveloped',
        //             },
        //         ],
        //     },
        //     {
        //         id: 'Deep C - Hai Phong',
        //         convince: 'Hai Phong',
        //         image: 'https://www.clipartmax.com/png/middle/220-2201975_computer-icons-map-pin-clip-art-location-icon-png-gray.png',
        //         lat: 20.820814799951133,
        //         lon: 106.73686136977906,
        //         phases: [
        //             {
        //                 projectName: 'Deep C - 10.6ha',
        //                 landArea: 106050,
        //                 gla: 71408.4,
        //                 status: 'Under construction',
        //             },
        //             {
        //                 projectName: 'Deep C - 12.6ha',
        //                 landArea: 126107,
        //                 gla: 81397.44,
        //                 status: 'Under construction',
        //             },
        //         ],
        //     },
        //     {
        //         id: 'Phu An Thanh - Long An',
        //         convince: 'Long An',
        //         image: 'https://www.clipartmax.com/png/middle/220-2201975_computer-icons-map-pin-clip-art-location-icon-png-gray.png',
        //         lat: 10.677561684300205,
        //         lon: 106.45331382751465,
        //         phases: [
        //             {
        //                 projectName: 'Phu An Thanh - Phase 1',
        //                 landArea: 68533.8613265723,
        //                 gla: 44807.36,
        //                 status: 'Under construction',
        //             },
        //             {
        //                 projectName: 'Phu An Thanh - Phase 2',
        //                 landArea: 66226.1386734277,
        //                 gla: 45708.72,
        //                 status: 'Undeveloped',
        //             },
        //         ],
        //     },
        //     {
        //         id: 'Tan Hung - Bac Giang',
        //         convince: 'Bac Giang',
        //         image: 'https://www.clipartmax.com/png/middle/220-2201975_computer-icons-map-pin-clip-art-location-icon-png-gray.png',
        //         lat: 21.35146063940227,
        //         lon: 106.29143714904785,
        //         phases: [
        //             {
        //                 projectName: 'Tan Hung - Phase 1 - Zone A',
        //                 landArea: 88590,
        //                 gla: 56675.2,
        //                 status: 'Operation',
        //             },
        //             {
        //                 projectName: 'Tan Hung - Phase 1 - Zone B',
        //                 landArea: 129593,
        //                 gla: 83134.4,
        //                 status: 'Operation',
        //             },
        //             {
        //                 projectName: 'Tan Hung - Phase 1 - Zone C',
        //                 landArea: 131897,
        //                 gla: 0,
        //                 status: 'Operation',
        //             },
        //             {
        //                 projectName: 'Tan Hung - Phase 2 - General',
        //                 landArea: 745200,
        //                 gla: 311153.93213681,
        //                 status: 'MOU',
        //             },
        //         ],
        //     },
        //     // {
        //     //     id: 'Nha anh Tiến',
        //     //     lat: 16.334240048971864,
        //     //     lon: 107.74732410907745,
        //     // },
        // ],

        data: dataChartMapVN,
      },
    ],
  };

  useEffect(() => {
    const getDataAPI = async () => {
      const api = new ProductService();
      const result = await api.getMapReport();
      if (result) {
        setDataChartMapVN(result);
      }
    };

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    getDataAPI();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // console.log(windowSize.innerWidth);

  return (
    <Box width="100%" className={cx("box-wrapper")}>
      {/* <h3 className={cx('title', 'py-20')}>{title}</h3> */}
      <Row gutter={16} style={{ marginBottom: 5 }}>
        <Col span={24}>
          <Card
            size="small"
            headStyle={{
              padding: "0px 16px !important",
              fontSize: `${windowSize.innerWidth > 2500 ? "35px" : "15px"}`,
              color: "#587E38",
              background: "rgba(92, 108, 83, 0.2)",
              borderBottom: 0,
              marginBottom: 0,
            }}
            bodyStyle={{
              padding: "0 16px 10px 16px",
              fontSize: `${windowSize.innerWidth > 2500 ? "40px" : "24px"}`,
              color: "#587E38",
              fontWeight: "bold",
              background: "rgba(92, 108, 83, 0.2)",
            }}
            title="Total Land Bank(sqm)"
          >
            {formatNumber(totalLandbank, 0)}
          </Card>
        </Col>
        {/* <Col span={8}>
                    <Card
                        title="Total GFA(sqm)"
                        size="small"
                        headStyle={{
                            padding: '0px 16px !important',
                            fontSize: `${windowSize.innerWidth > 2500 ? '35px' : '15px'}`,
                            color: '#587E38',
                            background: 'rgba(92, 108, 83, 0.2)',
                            borderBottom: 0,
                            marginBottom: 0,
                        }}
                        bodyStyle={{
                            padding: '0 16px 10px 16px',
                            fontSize: `${windowSize.innerWidth > 2500 ? '40px' : '24px'}`,
                            color: '#587E38',
                            fontWeight: 'bold',
                            background: 'rgba(92, 108, 83, 0.2)',
                        }}
                    >
                        {formatNumber(944106, 0)}
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        size="small"
                        headStyle={{
                            padding: '0px 16px !important',
                            fontSize: `${windowSize.innerWidth > 2500 ? '35px' : '15px'}`,
                            color: '#587E38',
                            background: 'rgba(92, 108, 83, 0.2)',
                            borderBottom: 0,
                            marginBottom: 0,
                        }}
                        bodyStyle={{
                            padding: '0 16px 10px 16px',
                            fontSize: `${windowSize.innerWidth > 2500 ? '40px' : '24px'}`,
                            color: '#587E38',
                            fontWeight: 'bold',
                            background: 'rgba(92, 108, 83, 0.2)',
                        }}
                        title="Total GLA(sqm)"
                    >
                        {formatNumber(901302, 0)}
                    </Card>
                </Col> */}
      </Row>
      <div style={{ display: "none" }}>
        <TableDetailProjectSummary
          onToTalChange={(total) => setTotalLandbank(total)}
        />
      </div>
      <Box width={"100%"}>
        <HighchartsReact
          // containerProps={{ style: { height: '100%' } }}
          constructorType={"mapChart"}
          highcharts={Highcharts}
          options={options}
        />
      </Box>
    </Box>
  );
};

export default React.memo(ChartMapVN);
