import classNames from 'classnames/bind';
import style from './Header.module.scss';
import { Pie } from '@ant-design/charts';
import { Grid } from '@mui/material';
import { TypeChartModel } from '~/models/TypeChartModel';
import { ProjectModel } from '~/models/ProjectModel';
import { formatNumber } from '~/utils/formatHelper';
import { UserAgent } from '@quentin-sommer/react-useragent';

const cx = classNames.bind(style);
interface PageTitleProps {
    data: ProjectModel;
}

function PageTitle({ data }: PageTitleProps) {
    const dataChart = data.types;
    const color = dataChart.map((item) => {
        return item.color;
    });
    // config của antd chart
    const config = {
        appendPadding: 10,
        width: 125,
        height: 125,
        padding: 2,
        data: dataChart,
        angleField: 'area',
        colorField: 'name',
        color: color,
        radius: 1,
        innerRadius: 0.8,
        label: {
            type: 'outer',
            offset: '-50%',
            content: '',
            style: {
                textAlign: 'center',
                fontSize: 14,
                fontFamily: 'Brown',
            },
        },

        interactions: [
            {
                type: 'element-active',
            },
            {
                type: 'pie-statistic-active',
            },
            {
                type: 'tooltip',
            },
        ],
        meta: {
            area: {
                formatter: (v: any) => {
                    return `${formatNumber(v, 2)}`;
                },
            },
        },
    };

    return (
        <Grid container spacing={2} className={cx('header')}>
            {/* hiển thị trên mobile */}
            <UserAgent mobile>
                <Grid item xs={12} width={'100%'} maxWidth={'100%'}>
                    <h2 className={cx('title')}>
                        <span>leasing updates</span>
                        <span>{data.projectName}</span>
                    </h2>
                </Grid>
                <Grid item xs={12}>
                    <div className={cx('chart-wrapper', 'mobile')}>
                        <div className={cx('total')}>
                            <h3>Total GLA</h3>
                            <h2>
                                {data.totalGLA.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} <span>sqm</span>
                            </h2>
                        </div>
                        <div className={cx('chart')}>
                            <h4 className={cx('chart-title')}>Occupancy rate</h4>
                            {/* Hiển thị chart */}
                            <Pie
                                {...config}
                                legend={{
                                    layout: 'vertical',
                                    position: 'right',
                                    offsetX: -10,
                                    maxItemWidth: 100,
                                    flipPage: true,
                                    itemName: {
                                        style: {
                                            fontFamily: 'Brown',
                                        },
                                    },
                                }}
                                statistic={{
                                    title: false,
                                    content: {
                                        offsetY: 4,
                                        style: {
                                            fontSize: '26px',
                                            fontFamily: 'Brown',
                                        },
                                        customHtml: (container, view, datum, data: any) => {
                                            // hiện % của Ocupied
                                            // const text = `${(
                                            //     (data.find((x: TypeChartModel) => x.name === 'Handover').area /
                                            //         data.reduce((r: any, d: any) => r + d.area, 0)) *
                                            //     100
                                            // ).toFixed(0)}%`;
                                            // Số % được tính bằng IPA signed (lấy % rồi làm tròn) + Handover (lấy % rồi làm tròn)
                                            const text = datum
                                                ? `${(
                                                      (datum.area / data.reduce((r: any, d: any) => r + d.area, 0)) *
                                                      100
                                                  ).toFixed(0)}%`
                                                : `${(
                                                      (data.find((x: TypeChartModel) => x.name === 'IPA signed')
                                                          ? data.find((x: TypeChartModel) => x.name === 'IPA signed')
                                                                .area / data.reduce((r: any, d: any) => r + d.area, 0)
                                                          : data[0].area /
                                                            data.reduce((r: any, d: any) => r + d.area, 0)) *
                                                          100 +
                                                      (data.find((x: TypeChartModel) => x.name === 'Handover')
                                                          ? data.find((x: TypeChartModel) => x.name === 'Handover')
                                                                .area / data.reduce((r: any, d: any) => r + d.area, 0)
                                                          : data[0].area /
                                                            data.reduce((r: any, d: any) => r + d.area, 0)) *
                                                          100
                                                  ).toFixed(0)}%`;
                                            return text;
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </Grid>
            </UserAgent>

            {/* Hiển thị trên mobile và pc tương đối giống nhau , tuy nhiên gặp phải lỗi hiện thị khác nhau giữa ios và android */}

            {/* Hiển thị trên pc */}
            <UserAgent computer>
                <Grid item xs={12} md={12} sm={12} lg={6} width={'100%'} maxWidth={'100%'}>
                    <h2 className={cx('title')}>
                        <span>leasing updates</span>
                        <span>{data.projectName}</span>
                    </h2>
                </Grid>
                <Grid item xs={12} md={12} sm={12} lg={6}>
                    <div className={cx('chart-wrapper')}>
                        <div className={cx('total')}>
                            <h3>Total GLA</h3>
                            <h2>
                                {data.totalGLA.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} <span>sqm</span>
                            </h2>
                        </div>
                        <div className={cx('chart')}>
                            <h4 className={cx('chart-title')}>Occupancy rate</h4>
                            {/* Hiển thị chart */}
                            <Pie
                                {...config}
                                legend={{
                                    layout: 'vertical',
                                    position: 'right',
                                    offsetX: -10,
                                    maxItemWidth: 100,
                                    flipPage: true,
                                    itemName: {
                                        style: {
                                            fontFamily: 'Brown',
                                        },
                                    },
                                }}
                                statistic={{
                                    title: false,
                                    content: {
                                        offsetY: 4,
                                        style: {
                                            fontSize: '26px',
                                            fontFamily: 'Brown',
                                        },
                                        customHtml: (container, view, datum, data: any) => {
                                            // hiện % của Ocupied
                                            // const text = `${(
                                            //     (data.find((x: TypeChartModel) => x.name === 'Handover').area /
                                            //         data.reduce((r: any, d: any) => r + d.area, 0)) *
                                            //     100
                                            // ).toFixed(0)}%`;
                                            const text = datum
                                                ? `${(
                                                      (datum.area / data.reduce((r: any, d: any) => r + d.area, 0)) *
                                                      100
                                                  ).toFixed(0)}%`
                                                : `${(
                                                      (data.find((x: TypeChartModel) => x.name === 'IPA signed')
                                                          ? data.find((x: TypeChartModel) => x.name === 'IPA signed')
                                                                .area / data.reduce((r: any, d: any) => r + d.area, 0)
                                                          : data[0].area /
                                                            data.reduce((r: any, d: any) => r + d.area, 0)) *
                                                          100 +
                                                      (data.find((x: TypeChartModel) => x.name === 'Handover')
                                                          ? data.find((x: TypeChartModel) => x.name === 'Handover')
                                                                .area / data.reduce((r: any, d: any) => r + d.area, 0)
                                                          : data[0].area /
                                                            data.reduce((r: any, d: any) => r + d.area, 0)) *
                                                          100
                                                  ).toFixed(0)}%`;
                                            return text;
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </Grid>
            </UserAgent>
        </Grid>
    );
}

export default PageTitle;
