import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Bar } from '@ant-design/charts';
import { formatNumber } from '~/utils/formatHelper';
import classNames from 'classnames/bind';
import { productSummaryList } from '~/models/TypeChartModel';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import { ProductService } from '~/services/ProductService';

import { each, groupBy } from '@antv/util';
import CollapseWrapper from '../CollapseWrapper';

// import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';

interface ChartProductSummaryProps {
    title: string;
    subTitle?: string;
}

const ChartProductSummary = (props: ChartProductSummaryProps) => {
    const { title, subTitle } = props;
    //====================== USE STATE ========================
    const [productSummary, setProductSummary] = useState<productSummaryList[]>([]);
    const [arrProductSummary, setArrProductSummary] = useState<productSummaryList[][]>([]);

    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    };
    const chartRef = useRef<HTMLDivElement | null>(null);

    const cx = classNames.bind(style);
    const colorDefaults = ['#274822', '#4f772d', '#90a955', '#DFDBD6', '#A5A5A5'];

    var option: echarts.EChartsOption;
    //   Option của chart
    option = {
        color: colorDefaults,
        tooltip: {
            trigger: 'axis',

            textStyle: {
                fontFamily: 'inherit',
                fontSize: 13,
            },
            borderRadius: 3,
            borderWidth: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 1,
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#DFDBD6',
                    color: 'black',
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 400,
                },
            },
        },
        legend: {
            textStyle: {
                fontFamily: 'Brown',
                fontSize: 13,
                color: 'black',
                fontWeight: 'bold',
            },
            right: '2%',
        },

        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',

            // axisLine: {
            //     show: false,
            // },
            // axisTick: {
            //     show: false,
            // },
            axisLabel: {
                fontSize: 13,
                fontFamily: 'Brown',
                fontWeight: 400,
                color: '#77838F',
                lineHeight: 12,
                formatter: function (value: any) {
                    return value / 1000 + '';
                },
            },
        },
        yAxis: [
            {
                type: 'category',
                // name: 'Giá trị',
                data: productSummary.length ? Array.from(new Set(productSummary.map((i) => i.status))) : [],
                // alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#F2F2F2',
                    },
                },
                axisTick: {
                    show: false,
                },
                nameTextStyle: {
                    color: '#000',
                },
                axisLabel: {
                    fontSize: 13,
                    fontFamily: 'Brown',
                    fontWeight: 400,
                    color: '#77838F',
                    lineHeight: 12,
                },
            },
        ],
        // series: [
        //     {
        //         name: productSummary.length ? productSummary[0].type : '',
        //         type: 'bar',
        //         stack: 'total',
        //         tooltip: {
        //             valueFormatter: function (value) {
        //                 return formatNumber(value as number, 0) + ' sqm';
        //             },
        //         },
        //         label: {
        //             show: true,
        //             position: 'inside',
        //             valueAnimation: true,
        //             fontFamily: 'Brown',
        //             fontSize: 13,
        //             fontWeight: 500,
        //             color: 'black',
        //             formatter: function (value) {
        //                 return value.data ? formatNumber((value.data as number) / 1000, 0) + '' : '';
        //             },
        //         },
        //         labelLine: {
        //             show: true,
        //         },
        //         // data: [292, 952, 320],
        //         data: productSummary.length
        //             ? productSummary.filter((i) => i.type === productSummary[0].type).map((j) => j.value)
        //             : [],
        //     },
        //     {
        //         name: productSummary.length ? productSummary[1].type : '',
        //         type: 'bar',
        //         stack: 'total',
        //         tooltip: {
        //             valueFormatter: function (value) {
        //                 return formatNumber(value as number, 0) + ' sqm';
        //             },
        //         },
        //         label: {
        //             show: true,
        //             position: 'inside',
        //             valueAnimation: true,
        //             fontFamily: 'Brown',
        //             fontSize: 13,
        //             fontWeight: 500,
        //             color: 'black',
        //             formatter: function (value) {
        //                 return value.data ? formatNumber((value.data as number) / 1000, 0) + '' : '';
        //             },
        //         },
        //         labelLine: {
        //             show: true,
        //         },
        //         // data: [292, 952, 320],
        //         data: productSummary.length
        //             ? productSummary.filter((i) => i.type === productSummary[1].type).map((j) => j.value)
        //             : [],
        //     },
        //     {
        //         name: productSummary.length ? productSummary[2].type : '',
        //         type: 'bar',
        //         stack: 'total',
        //         tooltip: {
        //             valueFormatter: function (value) {
        //                 return formatNumber(value as number, 0) + ' sqm';
        //             },
        //         },
        //         label: {
        //             show: true,
        //             position: 'inside',
        //             valueAnimation: true,
        //             fontFamily: 'Brown',
        //             fontSize: 13,
        //             fontWeight: 500,
        //             color: 'black',
        //             formatter: function (value) {
        //                 return value.data ? formatNumber((value.data as number) / 1000, 0) + '' : '';
        //             },
        //         },
        //         labelLine: {
        //             show: true,
        //         },

        //         // data: [292, 952, 320],
        //         data: productSummary.length
        //             ? productSummary.filter((i) => i.type === productSummary[2].type).map((j) => j.value)
        //             : [],
        //     },
        //     {
        //         name: productSummary.length ? productSummary[3].type : '',
        //         type: 'bar',
        //         stack: 'total',
        //         tooltip: {
        //             valueFormatter: function (value) {
        //                 return formatNumber(value as number, 0) + ' sqm';
        //             },
        //         },
        //         label: {
        //             show: true,
        //             position: 'inside',
        //             valueAnimation: true,
        //             fontFamily: 'Brown',
        //             fontSize: 13,
        //             fontWeight: 500,
        //             color: 'black',
        //             formatter: function (value) {
        //                 return value.data ? formatNumber((value.data as number) / 1000, 0) + '' : '';
        //             },
        //         },
        //         labelLine: {
        //             show: true,
        //         },

        //         // data: [292, 952, 320],
        //         data: productSummary.length
        //             ? productSummary.filter((i) => i.type === productSummary[3].type).map((j) => j.value)
        //             : [],
        //     },



        //     {
        //         type: 'bar',
        //         stack: 'total',
        //         tooltip: {
        //             show: false,
        //         },
        //         color: 'transparent',
        //         label: {
        //             show: true,
        //             position: 'outside',
        //             valueAnimation: true,
        //             fontFamily: 'Brown',
        //             fontSize: 13,
        //             fontWeight: 'bold',
        //             color: 'black',
        //             formatter: function (value) {
        //                 const group = Array.from(new Set(productSummary.map((s) => s.total)));
        //                 return formatNumber(group[value.dataIndex] / 1000, 0) + '';
        //             },
        //         },
        //         // Lấy ra số lượng type yAxis
        //         data: Array.from(new Set(productSummary.map((s) => s.total))).map((v) => 0),
        //     },
        // ],
        series: ((() => {

            if (arrProductSummary.length) {
                const temp = arrProductSummary[0].map((item) => ({
                    name: item.type,
                    type: 'bar',
                    stack: 'total',
                    tooltip: {
                        valueFormatter: function (value) {
                            return formatNumber(value as number, 0) + ' sqm';
                        },
                    },
                    label: {
                        show: true,
                        position: 'inside',
                        valueAnimation: true,
                        fontFamily: 'Brown',
                        fontSize: 13,
                        fontWeight: 500,
                        color: 'black',
                        formatter: function (value) {
                            return value.data ? formatNumber((value.data as number) / 1000, 0) + '' : '';
                        },
                    },
                    labelLine: {
                        show: true,
                    },
                    // data: [292, 952, 320],
                    data: productSummary.length
                        ? productSummary.filter((i) => i.type === item.type).map((j) => j.value)
                        : [],
                }) as echarts.SeriesOption)
                temp.push({
                    type: 'bar',
                    stack: 'total',
                    tooltip: {
                        show: false,
                    },
                    color: 'transparent',
                    label: {
                        show: true,
                        position: 'outside',
                        valueAnimation: true,
                        fontFamily: 'Brown',
                        fontSize: 13,
                        fontWeight: 'bold',
                        color: 'black',
                        formatter: function (value) {
                            const group = Array.from(new Set(productSummary.map((s) => s.total)));
                            return formatNumber(group[value.dataIndex] / 1000, 0) + '';
                        },
                    },
                    // Lấy ra số lượng type yAxis
                    data: Array.from(new Set(productSummary.map((s) => s.total))).map((v) => 0),
                })
                return temp
            }
            return [

            ]
        })())
    };

    useEffect(() => {
        if (!chartRef.current) return;

        const chart = echarts.init(chartRef.current);

        chart.setOption(option);

        const handleResize = () => {
            chart.resize();
        };

        // Lắng nghe sự kiện thay đổi kích cỡ cửa sổ
        window.addEventListener('resize', handleResize);

        // Dọn dẹp khi component bị gỡ khỏi DOM
        return () => {
            window.removeEventListener('resize', handleResize);
            chart.dispose();
        };
    }, [productSummary]);

    useEffect(() => {
        const getProductSummary = async () => {
            const api = new ProductService();
            const result = await api.GetProductSummary();
            if (result) {
                setProductSummary(result.data);
                // Sử dụng Map để nhóm dữ liệu theo "status"
                const groupedData = new Map();
                result.data.forEach((item) => {
                    const { status, ...rest } = item;
                    if (!groupedData.has(status)) {
                        groupedData.set(status, []);
                    }
                    groupedData.get(status).push({ status, ...rest });
                });
                // Chuyển kết quả về mảng nếu cần
                const dataConvert = Array.from(groupedData.values());
                setArrProductSummary(dataConvert)

            }
        };
        getProductSummary();
    }, []);

    return (
        // <Grid item xs={12} lg={6}>
        <Box flex="1">
            <CollapseWrapper title={title} subtitle={subTitle}>
                <div ref={chartRef} style={{ width: '100%', height: '300px' }}></div>
            </CollapseWrapper>
        </Box>
        // </Grid>
    );
};

export default React.memo(ChartProductSummary);
