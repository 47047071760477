export type IconProps = {
    width?: string;
    height?: string;
    className?: string;
    style?: React.CSSProperties;
    color?: string;
};

export const ChevronUp = ({ width = '11', height = '10', className, style }: IconProps) => (
    <svg
        className={'fill-white ' + className}
        width={width}
        height={height}
        style={style}
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.6">
            <path d="M5.4999 2.0981C5.63618 2.09794 5.77094 2.12478 5.89517 2.17682C6.0194 2.22886 6.13025 2.30491 6.22031 2.39989L10.3082 6.71465C10.4374 6.85763 10.5013 7.04187 10.4861 7.22799C10.4709 7.4141 10.3779 7.58731 10.227 7.71058C10.076 7.83385 9.87915 7.8974 9.67842 7.88763C9.47769 7.87786 9.28904 7.79555 9.15281 7.65831L5.57823 3.88521C5.56845 3.87486 5.55641 3.86656 5.5429 3.86087C5.52939 3.85519 5.51473 3.85224 5.4999 3.85224C5.48506 3.85224 5.4704 3.85519 5.45689 3.86087C5.44339 3.86656 5.43134 3.87486 5.42156 3.88521L1.84698 7.65831C1.78105 7.73127 1.70008 7.79125 1.60882 7.83474C1.51757 7.87823 1.41787 7.90434 1.31559 7.91153C1.21332 7.91873 1.11052 7.90687 1.01325 7.87665C0.915984 7.84642 0.826201 7.79845 0.749184 7.73555C0.672166 7.67265 0.609468 7.59608 0.564772 7.51036C0.520077 7.42463 0.494287 7.33148 0.488918 7.23636C0.48355 7.14125 0.498712 7.0461 0.533512 6.95651C0.568312 6.86691 0.622048 6.78468 0.691563 6.71465L4.77823 2.40143C4.86846 2.30622 4.97947 2.22993 5.1039 2.17763C5.22833 2.12533 5.36332 2.09822 5.4999 2.0981Z" />
        </g>
    </svg>
);
export const Blueprint = ({ width = '13', height = '13', className, style }: IconProps) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        style={style}
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path
                d="M4225 5105 c-82 -29 -98 -57 -268 -483 -129 -323 -157 -401 -157
   -442 l0 -50 -1158 0 -1159 0 -17 80 c-57 267 -281 493 -556 561 -90 22 -270
   18 -367 -10 -245 -69 -434 -257 -514 -511 l-24 -75 0 -1780 0 -1780 24 -75
   c79 -251 253 -427 506 -511 l70 -24 2258 -2 2257 -3 0 2065 0 2065 -165 0
   -165 0 0 47 c0 38 -32 128 -157 443 -138 345 -163 400 -198 436 -58 61 -129
   77 -210 49z m227 -480 c70 -176 128 -323 128 -327 0 -5 -128 -8 -285 -8 -182
   0 -285 4 -285 10 0 11 235 602 252 633 6 12 18 17 36 15 24 -3 34 -23 154
   -323z m-3535 -30 c146 -46 271 -152 339 -286 67 -135 64 -43 64 -1643 l0
   -1449 -57 55 c-154 145 -318 211 -523 210 -193 -1 -357 -65 -495 -194 l-75
   -70 0 1456 c0 1609 -3 1520 63 1640 61 108 131 180 238 240 123 70 303 87 446
   41z m3293 -1335 l0 -871 -122 3 -123 3 -3 868 -2 867 125 0 125 0 0 -870z
   m410 0 l0 -870 -120 0 -120 0 0 870 0 870 120 0 120 0 0 -870z m-820 -347 c0
   -691 4 -1061 10 -1085 16 -56 69 -119 125 -147 l49 -26 311 0 c287 0 313 1
   349 19 50 25 97 72 122 122 18 38 19 74 22 1102 l2 1062 80 0 80 0 0 -1895 0
   -1895 -1866 0 -1866 0 74 80 73 79 228 1 227 0 0 199 0 199 25 6 c14 4 69 25
   121 48 l96 41 139 -139 139 -139 292 293 293 292 -139 139 -139 139 35 84 c20
   46 41 100 48 121 l12 37 199 0 199 0 0 415 0 415 -199 0 -199 0 -12 38 c-7 20
   -29 74 -48 120 l-36 83 140 140 139 139 -293 292 -292 293 -139 -139 -140
   -140 -83 36 c-46 19 -100 41 -120 48 l-38 12 0 199 0 199 -165 0 -165 0 0 80
   0 80 1155 0 1155 0 0 -1047z m-2150 542 c0 -132 3 -176 13 -179 116 -32 261
   -87 332 -126 l90 -49 125 125 125 124 175 -175 175 -175 -123 -123 -123 -123
   56 -109 c45 -89 77 -175 121 -322 2 -10 47 -13 179 -13 l175 0 0 -245 0 -245
   -175 0 c-132 0 -177 -3 -179 -12 -45 -152 -78 -241 -123 -327 l-54 -104 123
   -124 123 -123 -175 -175 -175 -175 -125 124 -125 124 -89 -48 c-90 -49 -180
   -85 -288 -115 l-58 -16 0 -175 0 -174 -100 0 c-55 0 -100 3 -100 8 1 4 8 34
   16 67 12 44 17 131 21 323 3 144 6 262 7 262 21 0 137 26 186 42 289 93 505
   316 597 615 24 80 27 103 27 248 0 145 -3 168 -27 248 -80 261 -249 458 -490
   573 -62 29 -167 60 -269 79 l-28 5 0 330 0 330 80 0 80 0 0 -175z m-50 -676
   c129 -35 224 -92 325 -194 103 -102 159 -196 196 -331 31 -110 31 -268 0 -378
   -73 -262 -277 -463 -537 -531 -97 -25 -94 -27 -94 65 0 56 4 82 13 84 50 12
   161 52 174 61 14 10 11 19 -23 79 -42 72 -41 72 -119 43 l-40 -15 -3 198 c-1
   109 -1 291 0 404 l3 205 58 -24 c84 -34 122 -62 172 -128 62 -82 87 -154 87
   -252 0 -87 -22 -163 -67 -230 l-25 -36 33 -28 c17 -16 46 -40 63 -55 l32 -26
   24 38 c70 105 97 184 105 307 17 273 -171 527 -434 586 l-53 12 0 84 c0 97 -5
   94 110 62z m3020 -731 c0 -251 33 -228 -325 -228 l-282 0 -24 26 c-23 25 -24
   31 -27 205 l-3 179 330 0 331 0 0 -182z m-3764 -739 c32 -6 97 -28 143 -51
   237 -114 367 -387 306 -646 -49 -208 -230 -388 -436 -432 -70 -16 -237 -8
   -304 13 -160 51 -311 201 -369 366 -24 71 -33 233 -16 310 44 204 222 386 428
   436 70 17 169 18 248 4z"
            />
            <path
                d="M3140 910 l0 -580 825 0 825 0 0 580 0 580 -825 0 -825 0 0 -580z
   m1480 0 l0 -410 -660 0 -660 0 0 410 0 410 660 0 660 0 0 -410z"
            />
            <path d="M3470 1075 l0 -85 80 0 80 0 0 85 0 85 -80 0 -80 0 0 -85z" />
            <path d="M3800 1075 l0 -85 330 0 330 0 0 85 0 85 -330 0 -330 0 0 -85z" />
            <path d="M3470 745 l0 -85 330 0 330 0 0 85 0 85 -330 0 -330 0 0 -85z" />
            <path
                d="M4297 823 c-4 -3 -7 -42 -7 -85 l0 -78 85 0 85 0 0 85 0 85 -78 0
   c-43 0 -82 -3 -85 -7z"
            />
            <path
                d="M683 1150 c-76 -11 -172 -60 -229 -117 -241 -241 -102 -648 239 -697
   l47 -7 0 84 0 84 -41 6 c-105 14 -190 110 -197 223 -6 87 16 141 78 197 127
   115 313 77 386 -78 13 -27 24 -62 24 -77 l0 -28 78 0 c43 0 81 2 84 5 10 11
   -12 117 -35 169 -75 164 -253 261 -434 236z"
            />
        </g>
    </svg>
);
export const Handshake = ({ width = '15', height = '15', className, style }: IconProps) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        style={style}
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path
                d="M475 3969 c-46 -27 -45 1 -45 -835 l0 -793 24 -28 c24 -28 26 -28
   135 -28 106 0 112 1 137 26 21 22 62 144 235 715 114 379 209 706 209 726 0
   52 -31 82 -120 116 -195 76 -520 133 -575 101z"
            />
            <path
                d="M2580 3769 c-57 -11 -191 -75 -272 -130 -148 -101 -346 -327 -424
   -483 -31 -63 -37 -88 -38 -140 -1 -51 3 -70 20 -93 65 -88 230 -59 368 65 129
   117 303 206 425 218 57 6 59 6 102 -32 46 -41 1092 -996 1145 -1046 l32 -30
   48 25 c27 14 71 48 97 76 l47 50 -28 78 c-266 727 -387 1050 -396 1059 -17 16
   -903 366 -966 381 -62 14 -95 15 -160 2z"
            />
            <path
                d="M4450 3744 c-258 -28 -524 -93 -575 -140 -18 -17 -25 -33 -25 -61 0
   -59 503 -1421 536 -1451 23 -20 35 -22 140 -22 109 0 116 1 139 25 l25 24 0
   795 0 794 -31 26 c-34 29 -34 29 -209 10z"
            />
            <path
                d="M1141 3034 c-148 -489 -162 -539 -149 -562 20 -38 414 -431 578 -576
   272 -242 630 -532 865 -700 72 -52 84 -57 118 -53 21 3 47 13 57 22 29 27 43
   77 30 111 -6 16 -105 124 -221 240 -228 229 -234 239 -188 298 16 20 29 26 62
   26 40 0 45 -4 307 -265 l266 -265 48 0 c75 0 123 34 139 101 15 61 -6 90 -239
   331 -124 128 -230 243 -235 256 -20 52 25 112 84 112 34 0 58 -23 377 -355
   111 -115 216 -218 233 -227 116 -65 248 66 186 184 -8 15 -135 144 -284 287
   -148 144 -275 272 -283 286 -25 49 18 115 75 115 38 0 72 -30 461 -407 200
   -194 245 -233 276 -238 112 -22 198 103 134 194 -41 58 -1169 1081 -1192 1081
   -60 -1 -206 -85 -305 -175 -110 -101 -223 -148 -356 -149 -73 0 -99 4 -140 22
   -176 80 -223 289 -114 505 41 80 117 192 185 273 l55 64 -334 0 -334 0 -162
   -536z"
            />
        </g>
    </svg>
);
export const Factory = ({ width = '14', height = '15', className, style }: IconProps) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        style={style}
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path
                d="M3735 4978 c-45 -16 -130 -78 -225 -162 -47 -42 -107 -87 -134 -101
   -84 -42 -195 -31 -363 38 -34 14 -64 23 -67 20 -10 -10 27 -68 81 -124 172
   -180 408 -140 517 89 87 179 136 187 434 70 57 -22 105 -38 109 -35 12 12 -22
   70 -68 118 -83 86 -193 119 -284 87z"
            />
            <path
                d="M3817 4393 c-4 -32 -45 -566 -92 -1188 -48 -621 -88 -1136 -90 -1143
   -3 -9 -46 -12 -170 -12 l-165 0 0 676 c0 586 -2 675 -14 671 -8 -3 -227 -155
   -486 -336 -258 -182 -475 -331 -480 -331 -6 0 -10 120 -10 335 0 184 -2 335
   -4 335 -3 0 -222 -153 -488 -339 l-483 -339 -3 339 c-1 186 -6 339 -10 339 -4
   -1 -223 -153 -487 -339 l-480 -338 -3 -686 -2 -687 -175 0 -175 0 0 -610 0
   -610 2560 0 2560 0 0 610 0 610 -100 0 -100 0 0 350 0 350 -185 0 c-140 0
   -187 3 -190 13 -2 6 -38 527 -79 1157 -42 630 -79 1164 -82 1188 l-5 42 -278
   0 -278 0 -6 -57z m-2217 -2873 l0 -360 -385 0 -385 0 0 353 c0 195 3 357 7
   360 3 4 177 7 385 7 l378 0 0 -360z m1338 -3 l2 -357 -387 2 -388 3 -3 345
   c-1 190 0 351 3 358 3 10 85 12 387 10 l383 -3 3 -358z m1342 3 l0 -360 -385
   0 -385 0 0 353 c0 195 3 357 7 360 3 4 177 7 385 7 l378 0 0 -360z"
            />
        </g>
    </svg>
);
export const Digger = ({ width = '15', height = '15', className, style }: IconProps) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        style={style}
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path
                d="M1013 4400 c-29 -10 -66 -28 -81 -39 -43 -30 -100 -96 -115 -134 -8
-18 -124 -370 -258 -782 l-244 -750 -3 -194 c-4 -220 3 -259 54 -320 l34 -39
0 -181 0 -181 140 0 140 0 0 194 c0 168 2 195 17 207 9 8 24 32 34 54 16 34
19 67 19 216 0 125 4 190 15 225 8 27 155 288 326 579 171 291 322 552 336
580 31 61 42 189 24 278 -18 85 -74 189 -128 237 -78 69 -203 89 -310 50z
m187 -222 c122 -84 66 -268 -81 -268 -71 0 -130 53 -144 130 -8 48 28 117 75
142 48 25 109 23 150 -4z"
            />
            <path
                d="M1531 3848 c-7 -24 -53 -112 -102 -196 -49 -84 -89 -157 -89 -161 0
-5 21 -16 48 -26 170 -65 407 -196 567 -314 119 -88 263 -231 325 -323 25 -37
48 -68 51 -68 3 0 20 73 38 163 18 89 39 192 48 230 l15 67 99 0 100 0 -33 46
c-58 81 -204 211 -336 298 -177 118 -367 211 -606 297 -105 38 -110 38 -125
-13z"
            />
            <path
                d="M2387 2553 c-66 -318 -121 -588 -124 -600 l-5 -23 1206 0 c663 0
1206 3 1205 8 0 4 -100 124 -222 267 l-223 260 -467 3 -467 2 0 330 0 330
-393 0 -392 0 -118 -577z m703 52 l0 -335 -280 0 c-154 0 -280 3 -280 8 0 4
30 154 67 335 l66 327 214 0 213 0 0 -335z"
            />
            <path d="M2602 1703 l3 -138 893 -3 892 -2 0 140 0 140 -895 0 -896 0 3 -137z" />
            <path
                d="M340 1679 c-155 -30 -294 -165 -329 -320 -15 -62 -13 -195 3 -254 28
-106 94 -196 184 -253 93 -58 105 -60 617 -66 259 -3 501 -2 539 3 60 6 73 11
97 39 43 47 41 102 -5 148 l-34 34 -151 0 -151 0 -34 33 c-81 77 -179 193
-204 240 -26 50 -27 59 -30 230 l-4 177 -226 -1 c-125 -1 -247 -5 -272 -10z"
            />
            <path
                d="M2170 1455 c-63 -20 -147 -81 -190 -138 -54 -71 -75 -137 -75 -237 0
-74 4 -94 31 -150 37 -79 79 -128 142 -168 102 -65 26 -62 1432 -62 1406 0
1330 -3 1432 62 63 39 105 89 140 164 90 192 5 421 -191 513 l-56 26 -1310 2
c-1100 1 -1317 0 -1355 -12z m213 -201 c122 -85 113 -280 -16 -347 -88 -45
-186 -28 -251 43 -71 79 -73 187 -3 267 70 79 186 96 270 37z m598 8 c94 -49
134 -163 90 -257 -34 -74 -111 -125 -189 -125 -56 0 -134 43 -168 92 -25 36
-29 50 -29 114 0 64 3 77 30 115 63 89 167 113 266 61z m605 9 c145 -66 159
-262 25 -356 -37 -26 -51 -30 -111 -30 -60 0 -74 4 -112 30 -166 117 -89 374
112 374 25 0 63 -8 86 -18z m646 -17 c62 -43 90 -104 86 -180 -5 -82 -38 -132
-109 -168 -60 -30 -113 -33 -167 -10 -105 44 -158 162 -116 262 51 122 202
170 306 96z m583 17 c70 -31 125 -113 125 -186 0 -43 -23 -98 -56 -135 -89
-98 -243 -89 -319 19 -27 38 -30 51 -30 116 0 65 3 78 30 116 57 81 161 111
250 70z"
            />
        </g>
    </svg>
);
