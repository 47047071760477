import { Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";

import "./dashboard.scss";
import { Col, Form, Input, InputRef, Row, Select } from "antd";
import "antd/dist/antd.css";
import { ProductService } from "~/services/ProductService";
import type { ColumnsType } from "antd/es/table";
import Table from "antd/es/table";
import { formatNumber } from "~/utils/formatHelper";
import {
  DealCloseBySaleInchargeModel,
  DealCloseBySaleInchargeResponse,
  DealCloseByYearModel,
  DealCloseByYearResponse,
  DealCloseModel,
  enquirySiteVisitByYearsModel,
  enquirySiteVisitByYearsResponse,
  productEnquirySiteVisitsModel,
  productEnquirySiteVisitsResponse,
  ReportGLAResponse,
} from "~/models/TypeChartModel";
import { FormInstance } from "antd/es/form";
import { CommonService } from "~/services/CommonService";
import { EmployeeModel, LocationModel } from "~/models/CommonModel";
import ChartProductSummary from "~/components/Chart/chartProductSummary";
import ChartProjectSummary from "~/components/Chart/chartProjectSummary";
import ChartLeasingStatus from "~/components/Chart/chartLeasingStatus";
import TableCompletedProject from "~/components/TableReport/tableCompletedProject";
import TableDealClose from "~/components/TableReport/tableDealClose";
import TableProductEnquiry from "~/components/TableReport/tableProductEnquiry";
import TableProductEnquirySum from "~/components/TableReport/tableProductEnquirySum";
import ChartHistoricalSales from "~/components/Chart/chartHistoricalSales";
import ChartWeighted from "~/components/Chart/chartWeighted";
import ChartEnquiryByMonth from "~/components/Chart/chartEnquiryByMonth";
import ChartEnquiryBySize from "~/components/Chart/chartEnquiryBySize";
import ChartTotalDemand from "~/components/Chart/chartTotalDemand";
import ChartLocalExpansion from "~/components/Chart/chartLocalExpansion";
import ChartMapVN from "~/components/Chart/chartMapVN";
import style from "../../pages/Dashboard/Dashboard.module.scss";
import classNames from "classnames/bind";
import Table1DealCloseByYear from "~/components/TableReport/table1DealCloseByYear";
import mock from "./mock";
import TableDealCloseBySaleIncharge from "~/components/TableReport/tableDealCloseBySaleIncharge";
import TableDetailProjectSummary from "~/components/TableReport/tableDetailProjectSummary";
import ChartLandBank from "~/components/Chart/chartLandBank";
import ChartPercentageEnquiriesByRegion from "~/components/Chart/chartPercentageEnquiriesByRegion";
import ChartConstructionSalesTracking from "~/components/Chart/chartConstructionSalesTracking";
import ChartEnquiriesByIndustry from "~/components/Chart/chartEnquiriesByIndustry";
import ChartEnquiriesByMarket from "~/components/Chart/chartEnquiriesByMarket";
import TableIPAsSigned from "~/components/TableReport/tableIPAsSigned";
import { YEAR } from "~/utils/constants";
import TablePresentLeasingTransaction from "~/components/TableReport/tablePresentLeasingTransaction";

export type columns3Model = {
  key: string;
  siteVisit: number;
  enquiry: number;
  demand: number;
  deal: number;
  rateEnquiry: number;
  rateSite: number;
  available: number;
  year: number;
};

type yearSearch = {
  yearProjectOccu: number[];
  yearRBFDealClose: number[];
  yearBTSDealClose: number[];
  yearProductEnquiry: number[];
  yearProductEnquirySum: number[];
  yearDealCloseByYear: number[];
  yearDealCloseBTSByYear: number[];
  yearDealCloseBySaleIncharge: number[];
  yearDealCloseBTSBySaleIncharge: number[];
};

// Function Components EditableRow

const columns5: ColumnsType<DealCloseModel> = [
  {
    title: "Project",
    dataIndex: "project",
    key: "project",
    className: "",
    width: "20%",
    render(text, record) {
      return {
        children: <div className="list-symbol">{text}</div>,
      };
    },
    defaultSortOrder: "ascend",
  },
  {
    title: "GLA (sqm)",
    dataIndex: "gla",
    key: "gla",
    render(text, record) {
      return {
        children: (
          <div
            dangerouslySetInnerHTML={{
              __html: text === 0 ? "-&ensp;" : `${formatNumber(text, 0)}`,
            }}
          ></div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "No. of deal",
    dataIndex: "numberOfDeal",
    key: "numberOfDeal",
    render(text, record) {
      return {
        children: (
          <div>
            {text !== 0 ? formatNumber(parseInt(text), 0, false, true) : "-"}
          </div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "Target (sqm)",
    dataIndex: "target",
    key: "target",
    render(text, record) {
      return {
        children: (
          <div>
            {text !== 0 ? formatNumber(parseInt(text), 0, false, true) : "-"}
          </div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "YTD Target",
    dataIndex: "ytd",
    key: "ytd",
    render(text, record) {
      return {
        children: (
          <div>
            {text !== 0 ? formatNumber(parseInt(text), 0, false, true) : "-"}
          </div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "% vs YE Target",
    dataIndex: "yeTarget",
    key: "yeTarget",
    render(text, record) {
      return {
        children: (
          <div>
            {text !== 0 ? formatNumber(parseInt(text), 0, false, true) : "-"}
          </div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "% vs YTD Target",
    dataIndex: "ytdTarget",
    key: "ytdTarget",
    width: 130,
    render(text, record) {
      return {
        children: (
          <div>
            {text !== 0 ? formatNumber(parseInt(text), 0, false, true) : "-"}
          </div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
];
const columns2: ColumnsType<productEnquirySiteVisitsModel> = [
  // project
  {
    title: "Project",
    dataIndex: "project",
    key: "project",
    width: "18%",
    render(text, record) {
      return {
        children: <div className="list-symbol">{text}</div>,
      };
    },
  },
  // enquiry
  {
    title: "Enquiry",
    dataIndex: "enquiry",
    key: "enquiry",
    width: "12%",
    render(text, record) {
      return {
        children: (
          <div
            dangerouslySetInnerHTML={{
              __html: text === 0 ? "-&ensp;" : `${formatNumber(text, 0)}`,
            }}
          ></div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  // site visit
  {
    title: "Site visit",
    dataIndex: "siteVisit",
    key: "siteVisit",
    width: "9%",
    render(text, record) {
      return {
        children: (
          <div>
            {text !== 0 ? formatNumber(parseInt(text), 0, false, true) : "-"}
          </div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  // demand
  {
    title: "Demand GLA(sqm)",
    dataIndex: "demand",
    key: "demand",
    render(text, record) {
      return {
        children: (
          <div>
            {text !== 0 ? formatNumber(parseInt(text), 0, false, true) : "-"}
          </div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  // deal
  {
    title: "Deal",
    dataIndex: "deal",
    key: "deal",
    width: "8%",
    render(text, record) {
      return {
        children: (
          <div>
            {text !== 0 ? formatNumber(parseInt(text), 0, false, true) : "-"}
          </div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  // rateEnquiry
  {
    title: "Conv. Rate - Enquiry",
    dataIndex: "rateEnquiry",
    key: "rateEnquiry",
    render(text, record) {
      return {
        children: (
          <div>{text !== 0 ? `${formatNumber(text * 100, 0)}%` : "-"}</div>
        ),
      };
    },

    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  // rateSite
  {
    title: "Conv. Rate - Site visit",
    dataIndex: "rateSite",
    key: "rateSite",
    render(text, record) {
      return {
        children: (
          <div>{text !== 0 ? `${formatNumber(text * 100, 0)}%` : "-"}</div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  // Available Area (sqm)
  {
    title: "Available Area (sqm)",
    dataIndex: "available",
    key: "available",
    render(text, record) {
      return {
        children: (
          <div
            dangerouslySetInnerHTML={{
              __html: text === 0 ? "-&ensp;" : `${formatNumber(text, 0)}`,
            }}
          ></div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
];
const columns3: ColumnsType<enquirySiteVisitByYearsModel> = [
  {
    title: "Year",
    dataIndex: "year",
    key: "year",
    width: "15%",
    render(text, record) {
      return {
        children: <div className="list-symbol ">{text}</div>,
      };
    },
  },
  // enquiry
  {
    title: "Enquiry",
    dataIndex: "enquiry",
    key: "enquiry",
    render(text, record) {
      return {
        children: (
          <div
            dangerouslySetInnerHTML={{
              __html: text === 0 ? "-&ensp;" : `${formatNumber(text, 0)}`,
            }}
          ></div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  // site visit
  {
    title: "Site visit",
    dataIndex: "siteVisit",
    key: "siteVisit",
    width: "9%",
    render(text, record) {
      return {
        children: (
          <div
            dangerouslySetInnerHTML={{
              __html: text === 0 ? "-&ensp;" : `${formatNumber(text, 0)}`,
            }}
          ></div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  // demand gla
  {
    title: "Demand GLA (sqm)",
    dataIndex: "demand",
    key: "demand",
    render(text, record) {
      return {
        children: (
          <div
            dangerouslySetInnerHTML={{
              __html: text === 0 ? "-&ensp;" : `${formatNumber(text, 0)}`,
            }}
          ></div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "Deal",
    dataIndex: "deal",
    key: "deal",
    width: "8%",
    render(text, record) {
      return {
        children: (
          <div
            dangerouslySetInnerHTML={{
              __html: text === 0 ? "-&ensp;" : `${formatNumber(text, 0)}`,
            }}
          ></div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "Conv. Rate - Enquiry",
    dataIndex: "rateEnquiry",
    key: "rateEnquiry",
    render(text, record) {
      return {
        children: (
          <div
            dangerouslySetInnerHTML={{
              __html:
                text === 0 ? "-&ensp;" : `${formatNumber(text * 100, 0)}%`,
            }}
          ></div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "Conv. Rate - Site visit",
    dataIndex: "rateSite",
    key: "rateSite",
    render(text, record) {
      return {
        children: (
          <div
            dangerouslySetInnerHTML={{
              __html:
                text === 0 ? "-&ensp;" : `${formatNumber(text * 100, 0)}%`,
            }}
          ></div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "Available Area (sqm)",
    dataIndex: "available",
    key: "available",
    render(text, record) {
      return {
        children: (
          <div
            dangerouslySetInnerHTML={{
              __html: text === 0 ? "-&ensp;" : `${formatNumber(text, 0)}`,
            }}
          ></div>
        ),
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
];
const columnDealCloseByYear: ColumnsType<DealCloseByYearModel> = [
  // year
  {
    title: "Year",
    dataIndex: "year",
    key: "year",
    render(text, record) {
      return {
        children: <div className="list-symbol">{text}</div>,
      };
    },
  },
  // gla
  {
    title: "GLA(sqm)",
    dataIndex: "gla",
    key: "gla",
    render(text, record) {
      return {
        children: <div>{formatNumber(text, 0)}</div>,
      };
    },
    onCell: (record) => ({
      style: { textAlign: "right" },
    }),
  },
  // no of deal
  {
    title: "No. of deal",
    dataIndex: "noOfdeal",
    key: "noOfdeal",
    render(text, record) {
      return {
        children: <div>{formatNumber(text, 0)}</div>,
      };
    },
    onCell: (record) => ({
      style: { textAlign: "right" },
    }),
  },
  // target
  {
    title: "Target",
    dataIndex: "target",
    key: "target",
    render(text, record) {
      return {
        children: <div>{formatNumber(text, 0)}</div>,
      };
    },
    onCell: (record) => ({
      style: { textAlign: "right" },
    }),
  },
  // {
  //     title: 'YTD Target',
  //     dataIndex: 'ytdTarget',
  //     key: 'ytdTarget',
  //     render(text, record) {
  //         return {
  //             children: <div>{formatNumber(text, 0)}</div>,
  //         };
  //     },
  //     onCell: (record) => ({
  //         style: { textAlign: 'right' },
  //     }),
  // },
  {
    title: "% vs YE Target",
    dataIndex: "percentTarget",
    key: "percentTarget",
    render(text, record) {
      return {
        children: <div>{formatNumber(text, 0)}</div>,
      };
    },
    onCell: (record) => ({
      style: { textAlign: "right" },
    }),
  },
  // {
  //     title: '% vs YTD Target',
  //     dataIndex: 'percentYtdTarget',
  //     key: 'percentYtdTarget',
  //     render(text, record) {
  //         return {
  //             children: <div>{formatNumber(text, 0)}</div>,
  //         };
  //     },
  //     width: 130,
  //     onCell: (record) => ({
  //         style: { textAlign: 'right' },
  //     }),
  // },
];
const columnDealCloseBySaleIncharge: ColumnsType<DealCloseBySaleInchargeModel> = [
  // sales incharge
  {
    title: "Sale Incharge",
    dataIndex: "saleIncharge",
    key: "saleIncharge",
    render(text, record) {
      return {
        children: <div className="list-symbol">{text}</div>,
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "left" },
      };
    },
  },
  // gla
  {
    title: "GLA(sqm)",
    dataIndex: "gla",
    key: "gla",
    render(text, record) {
      return {
        children: <div>{formatNumber(text, 0)}</div>,
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  // no of deal
  {
    title: "No. of deal",
    dataIndex: "noOfdeal",
    key: "noOfdeal",
    render(text, record) {
      return {
        children: <div>{formatNumber(text, 0)}</div>,
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  // target
  {
    title: "Target",
    dataIndex: "target",
    key: "target",
    render(text, record) {
      return {
        children: <div>{formatNumber(text, 0)}</div>,
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "YTD Target",
    dataIndex: "ytdTarget",
    key: "ytdTarget",
    render(text, record) {
      return {
        children: <div>{formatNumber(text, 0)}</div>,
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "% vs YE Target",
    dataIndex: "percentTarget",
    key: "percentTarget",
    render(text, record) {
      return {
        children: <div>{formatNumber(text, 0)}</div>,
      };
    },
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
  {
    title: "% vs YTD Target",
    dataIndex: "percentYtdTarget",
    key: "percentYtdTarget",
    render(text, record) {
      return {
        children: <div>{formatNumber(text, 0)}</div>,
      };
    },
    width: 130,
    onCell(record) {
      return {
        style: { textAlign: "right" },
      };
    },
  },
];

const Dashboard = () => {
  //=============================== STATE ====================================

  const [listLocation, setListLocation] = useState<LocationModel[]>([]);
  const [yearEnquirySiteVisit, setYearEnquirySiteVisit] = useState<yearSearch>({
    yearProjectOccu: [],
    yearRBFDealClose: [],
    yearBTSDealClose: [],
    yearProductEnquiry: [],
    yearProductEnquirySum: [],
    yearDealCloseByYear: [],
    yearDealCloseBTSByYear: [],
    yearDealCloseBySaleIncharge: [],
    yearDealCloseBTSBySaleIncharge: [],
  });

  // C. enquiries analysis
  const [searchPIC, setSearchPIC] = useState<string | null>(null);
  // Thời gian hoàn thành
  const [dateCompleteFrom, setdateCompleteFrom] = useState<string | null>(null);
  const [dateCompleteTo, setdateCompleteTo] = useState<string | null>(null);
  const [project, setProject] = useState<string | null>(null);

  const [listEmployees, setListEmployees] = useState<EmployeeModel[]>([]);
  const [keyWorkSearch, setKeyWorkSearch] = useState("");

  // COMPLETED PROJECTS' OCCUPANCY RATIO
  const [totalGLAByProjectType, setTotalGLAByProjectType] = useState<
    ReportGLAResponse
  >();
  const [productEnquiry, setProductEnquiry] = useState<
    productEnquirySiteVisitsResponse
  >();
  const [enquirySiteVisit, setEnquirySiteVisit] = useState<
    enquirySiteVisitByYearsResponse
  >();
  const [dataSource, setDataSource] = useState<DealCloseModel[]>([]);
  const [dataSource5, setDataSource5] = useState<DealCloseModel[]>([]);

  // table dealClosebyYear
  const [dealCloseByYear, setDealCloseByYear] = useState<
    DealCloseByYearResponse
  >();
  const [dealCloseBTSByYear, setDealCloseBTSByYear] = useState<
    DealCloseByYearResponse
  >();
  // table dealClosebySaleIncharge
  const [dealCloseBySaleIncharge, setDealCloseBySaleIncharge] = useState<
    DealCloseBySaleInchargeResponse
  >();
  const [dealCloseBTSBySaleIncharge, setDealCloseBTSBySaleIncharge] = useState<
    DealCloseBySaleInchargeResponse
  >();

  const cx = classNames.bind(style);

  // ==============================FUNCTIONS ==================================

  // ==============================FUNCTIONS ==================================
  // handle save value input

  const handleChange = (key?: string, newValue?: [number]) => {
    if (key) {
      setYearEnquirySiteVisit({
        ...yearEnquirySiteVisit,
        [key]: newValue,
      });
    }
    // setYearEnquirySiteVisit({ ...yearEnquirySiteVisit, yearProductEnquirySum: newValue });
  };

  // handle Search key work employees
  const handleChangeSearch = async (value: string) => {
    setKeyWorkSearch(value);
  };
  const handleGetValuePIC = async (
    keyPIC: string,
    dateCompleteFrom: string | null,
    dateCompleteTo: string | null,
    project: string | null
  ) => {
    setSearchPIC(keyPIC);
    setdateCompleteFrom(dateCompleteFrom);
    setdateCompleteTo(dateCompleteTo);
    setProject(project);
  };

  // get data chart GetProductSummary
  useEffect(() => {
    // get total gla by project type
    const getTotalGLAByProjectType = async () => {
      const api = new ProductService();
      const result = await api.GetTotalGLAByProjectType();
      if (result) {
        setTotalGLAByProjectType(result);
      }
    };
    // get list location
    const getListLocation = async () => {
      const api = new CommonService();
      const result = await api.getListLocation();
      if (result) {
        setListLocation(result.data);
      }
    };
    getTotalGLAByProjectType();
    getListLocation();
  }, []);

  useEffect(() => {
    // get enquiry site visit by year
    const getEnquirySiteVisitByYear = async () => {
      const api = new ProductService();
      const result = await api.getEnquirySiteVisitByYear(
        yearEnquirySiteVisit.yearProductEnquirySum
      );
      if (result) {
        setEnquirySiteVisit(result);
      }
    };
    // get data deal close
    const getDealClose = async () => {
      const api = new ProductService();
      const result = await api.GetDealClose(
        yearEnquirySiteVisit.yearRBFDealClose
      );
      if (result) {
        setDataSource(result.data);
        // setDataSource5(result.data.rbW_RGHDealCloses);
      }
    };
    // get data deal close bts
    const getDealCloseBts = async () => {
      const api = new ProductService();
      const result = await api.GetDealCloseBts(
        yearEnquirySiteVisit.yearBTSDealClose
      );
      if (result) {
        setDataSource5(result.data);
      }
    };

    // get product enquiry site visit
    const getProductEnquirySiteVisit = async () => {
      const api = new ProductService();
      const result = await api.getProductEnquirySiteVisit(
        yearEnquirySiteVisit.yearProductEnquiry
      );
      if (result) {
        setProductEnquiry(result);
      }
    };
    const getDealCloseByYear = async () => {
      const api = new ProductService();
      const result = await api.getDealCloseByYear(
        yearEnquirySiteVisit.yearDealCloseByYear
      );
      if (result) {
        setDealCloseByYear(result);
      }
    };
    const getDealCloseBTSByYear = async () => {
      const api = new ProductService();
      const result = await api.getDealCloseBTSByYear(
        yearEnquirySiteVisit.yearDealCloseBTSByYear
      );
      if (result) {
        setDealCloseBTSByYear(result);
      }
    };
    const getDealCloseBySaleIncharge = async () => {
      const api = new ProductService();
      const result = await api.getDealCloseBySaleIncharge(
        yearEnquirySiteVisit.yearDealCloseBySaleIncharge
      );
      if (result) {
        setDealCloseBySaleIncharge(result);
      }
    };
    const getDealCloseBTSBySaleIncharge = async () => {
      const api = new ProductService();
      const result = await api.getDealCloseBTSBySaleIncharge(
        yearEnquirySiteVisit.yearDealCloseBTSBySaleIncharge
      );
      if (result) {
        setDealCloseBTSBySaleIncharge(result);
      }
    };
    getDealCloseByYear();
    getDealCloseBTSByYear();
    getDealCloseBySaleIncharge();
    getDealCloseBTSBySaleIncharge();
    getDealClose();
    getDealCloseBts();
    getEnquirySiteVisitByYear();
    getProductEnquirySiteVisit();
  }, [yearEnquirySiteVisit]);

  // useEffect(() => {}, [yearEnquirySiteVisit]);

  useEffect(() => {
    const getListEmployees = async () => {
      const api = new CommonService();
      const result = await api.getListEmployees(keyWorkSearch);
      if (result) {
        listEmployees.unshift({ key: "", value: "All" });
        setListEmployees(result.data);
      }
    };
    getListEmployees();
  }, [keyWorkSearch]);

  return (
    <Box padding={"20px"} paddingTop={"0"} className={cx("main-wrapper")}>
      <Grid container spacing={"12px"}>
        {/* chart 5 */}
        <Grid item xs={12}>
          {/* <h3 className={cx("headingMain")}>A. SUMMARY</h3> */}
          <h3 className={cx("headingMain")}>A. KCN SUMMARY</h3>
          <Grid container spacing={"12px"}>
            <Grid item xs={12} lg={4} display={"flex"} width={"100%"}>
              {/* <ChartMapVN title="MAP" /> */}
              <ChartMapVN title="MAP KCN" />
            </Grid>

            <Grid item xs={12} lg={8} display="flex">
              <Grid container spacing={"12px"}>
                <Grid item xs={12} lg={12} height={"100%"}>
                  <ChartLandBank title="LAND BANK STATUS" />
                </Grid>
              </Grid>
            </Grid>
            <Grid justifySelf={"end"} item xs={12} lg={6}>
              <ChartConstructionSalesTracking title="CONSTRUCTION & SALES TRACKING" />
            </Grid>
            <Grid item xs={12} lg={6} display="flex">
              <Grid container spacing={"12px"}>
                <Grid item xs={12} lg={12} height={"100%"}>
                  <ChartPercentageEnquiriesByRegion title="PERCENTAGE OF ENQUIRIES BY REGION" />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12} display="flex">
              <ChartProductSummary title="GLA OF PRODUCT" subTitle="(ksqm)" />
            </Grid>

            <Grid item xs={12} lg={12}>
              <TableCompletedProject
                title="PROJECT'S OCCUPANCY RATIO"
                description={
                  <span style={{ color: "red" }}>{"RED: Occ.rate < 50%"}</span>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Table */}
        <Grid item xs={12} lg={12}>
          <h3 className={cx("headingMain")}>B. SALES PERFORMANCE</h3>
          <Box paddingBottom={"12px"}>
            <Select
              mode="tags"
              size="middle"
              placeholder="Year"
              style={{ width: 300 }}
              onChange={(value: number[]) => {
                if (value.length) {
                  setYearEnquirySiteVisit({
                    yearProjectOccu: value.map((v) => Number(v)),
                    yearRBFDealClose: value.map((v) => Number(v)),
                    yearBTSDealClose: value.map((v) => Number(v)),
                    yearProductEnquiry: value.map((v) => Number(v)),
                    yearProductEnquirySum: value.map((v) => Number(v)),
                    yearDealCloseByYear: value.map((v) => Number(v)),
                    yearDealCloseBTSByYear: value.map((v) => Number(v)),
                    yearDealCloseBySaleIncharge: value.map((v) => Number(v)),
                    yearDealCloseBTSBySaleIncharge: value.map((v) => Number(v)),
                  });
                } else {
                  setYearEnquirySiteVisit({
                    yearProjectOccu: [],
                    yearRBFDealClose: [],
                    yearBTSDealClose: [],
                    yearProductEnquiry: [],
                    yearProductEnquirySum: [],
                    yearDealCloseByYear: [],
                    yearDealCloseBTSByYear: [],
                    yearDealCloseBySaleIncharge: [],
                    yearDealCloseBTSBySaleIncharge: [],
                  });
                }
              }}
              // options={year}
              options={YEAR.map((value, i) => ({
                value: value.value + "",
                label: value.value,
              }))}
              allowClear
            />
          </Box>
          <Grid container spacing={"12px"}>
            <TableDealClose
              dataSource={dataSource}
              columns={columns5}
              onChange={handleChange}
              name={"yearRBFDealClose"}
              title="DEAL CLOSE - YTD"
              year={yearEnquirySiteVisit.yearRBFDealClose}
            />
            {/* <TableDealClose
                            onChange={handleChange}
                            dataSource={dataSource5}
                            columns={columns5}
                            name={'yearBTSDealClose'}
                            title="BTS DEAL CLOSE - YTD"
                            year={yearEnquirySiteVisit.yearBTSDealClose}
                        /> */}
            <Table1DealCloseByYear
              dataSource={dealCloseByYear?.data.dealCloses}
              columns={columnDealCloseByYear}
              dataTotal={dealCloseByYear}
              onChange={handleChange}
              name={"yearDealCloseByYear"}
              title="DEAL CLOSE BY YEAR"
              year={yearEnquirySiteVisit.yearDealCloseByYear}
            />
            {/* <Table1DealCloseByYear
                            dataSource={dealCloseBTSByYear?.data.dealCloses}
                            columns={columnDealCloseByYear}
                            dataTotal={dealCloseBTSByYear}
                            onChange={handleChange}
                            name={'yearDealCloseBTSByYear'}
                            title="BTS DEAL CLOSE BY YEAR"
                            year={yearEnquirySiteVisit.yearDealCloseBTSByYear}
                        /> */}
            <TableDealCloseBySaleIncharge
              dataSource={dealCloseBySaleIncharge?.data.dealCloses}
              columns={columnDealCloseBySaleIncharge}
              dataTotal={dealCloseBySaleIncharge}
              onChange={handleChange}
              name={"yearDealCloseBySaleIncharge"}
              title="RBF/RBH/RBW DEAL CLOSE BY SALES INCHARGE"
              year={yearEnquirySiteVisit.yearDealCloseBySaleIncharge}
            />
            <TableDealCloseBySaleIncharge
              dataSource={dealCloseBTSBySaleIncharge?.data.dealCloses}
              columns={columnDealCloseBySaleIncharge}
              dataTotal={dealCloseBTSBySaleIncharge}
              onChange={handleChange}
              name={"yearDealCloseBTSBySaleIncharge"}
              title="BTS DEAL CLOSE BY SALES INCHARGE"
              year={yearEnquirySiteVisit.yearDealCloseBTSBySaleIncharge}
            />
            <TableProductEnquiry
              dataSource={productEnquiry}
              columns={columns2}
              name={"yearProductEnquiry"}
              onChange={handleChange}
              title="ENQUIRY & SITE VISIT BY PROJECT"
              year={yearEnquirySiteVisit.yearProductEnquiry}
              description={
                <>
                  <span style={{ color: "black" }}>
                    Conv. Rate - Enquiry = No. of deal close/No. of Enquiry
                  </span>
                  <span style={{ color: "black" }}>
                    Conv. Rate - Site visit = No. of deal close/No. of Site
                    visit
                  </span>
                </>
              }
            />
            <TableProductEnquirySum
              dataSource={enquirySiteVisit}
              columns={columns3}
              onChange={handleChange}
              name={"yearProductEnquirySum"}
              title="ENQUIRY & SITE VISIT BY YEAR"
              year={yearEnquirySiteVisit.yearProductEnquirySum}
            />
            <TableIPAsSigned
              year={yearEnquirySiteVisit.yearBTSDealClose}
              title="IPAs SIGNED"
            />
          </Grid>
        </Grid>
        {/*HISTORIAL SALES */}
        <Grid item xs={12} display={"flex"}>
          <Grid container spacing={"12px"}>
            <ChartHistoricalSales
              title="HISTORICAL SALES"
              listLocation={listLocation}
            />
            {/* WEIGHTED AVERAGE LEASE EXPIRY (WALE) STATUS */}
            <ChartWeighted
              title="PLC EXPIRED TRACKING"
              listLocation={listLocation}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <h3 className={cx("headingMain")}>C. ENQUIRIES ANALYSIS</h3>
          <Grid container spacing={"12px"}>
            <ChartEnquiryByMonth
              title="NO. OF ENQUIRIES BY MONTH"
              onChange={handleChangeSearch}
              handleGetValuePIC={handleGetValuePIC}
              listEmployees={listEmployees}
              listLocation={listLocation}
            />
            <ChartEnquiriesByMarket
              title="NO. OF ENQUIRIES BY MARKET"
              searchPIC={searchPIC}
              dateCompleteFrom={dateCompleteFrom}
              dateCompleteTo={dateCompleteTo}
              project={project}
            />
            <ChartEnquiriesByIndustry
              title="NO. OF ENQUIRIES BY INDUSTRY"
              searchPIC={searchPIC}
              dateCompleteFrom={dateCompleteFrom}
              dateCompleteTo={dateCompleteTo}
              project={project}
            />
            <ChartEnquiryBySize
              title="NO. OF ENQUIRIES BY SIZE"
              searchPIC={searchPIC}
              listEmployees={listEmployees}
              dateCompleteFrom={dateCompleteFrom}
              dateCompleteTo={dateCompleteTo}
              project={project}
            />
            <ChartTotalDemand
              title="TOTAL DEMAND AREA OF THE ENQUIRIES BY MARKET"
              searchPIC={searchPIC}
              listEmployees={listEmployees}
              dateCompleteFrom={dateCompleteFrom}
              dateCompleteTo={dateCompleteTo}
              project={project}
              subTitle="(sqm)"
            />
            <ChartLocalExpansion
              title="LOCAL EXPANSION VS NEW ESTABLISH ENQUIRIES"
              searchPIC={searchPIC}
              listEmployees={listEmployees}
              dateCompleteFrom={dateCompleteFrom}
              dateCompleteTo={dateCompleteTo}
              project={project}
            />
            <TablePresentLeasingTransaction title="COMPETITORS: PRESENT LEASING TRANSACTION" />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
