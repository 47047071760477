import { Input, InputProps } from 'antd';
import { memo, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import style from './inputString.module.scss';

interface CustomInputProps extends InputProps {}

function CustomInputString(props: CustomInputProps) {
    const cx = classNames.bind(style);
    let size = '';
    switch (props.size) {
        case 'small':
            size = `!py-0.6 `;
            break;
        default:
            size = '';
    }
    return <Input {...props} className={cx('custom') + ' ' + size + props.className} />;
}

export default memo(CustomInputString);
