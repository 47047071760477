import { Box, Grid } from '@mui/material';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import classNames from 'classnames/bind';
import { formatNumber } from '~/utils/formatHelper';
import Table from 'antd/es/table';
import { productEnquirySiteVisitsModel, productEnquirySiteVisitsResponse } from '~/models/TypeChartModel';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import { Select } from 'antd';
import CollapseWrapper from '../CollapseWrapper';

interface TableProductEnquiryProps {
    dataSource: productEnquirySiteVisitsResponse | undefined;
    columns: ColumnsType<productEnquirySiteVisitsModel>;
    title: string;
    onChange: (key?: string, newValue?: [number]) => void;
    name: string;
    year: number[];
    description: React.ReactNode;
}

const TableProductEnquiry = (props: TableProductEnquiryProps) => {
    const { title, dataSource, columns, onChange, name, year: yearValue, description } = props;
    const cx = classNames.bind(style);

    return (
        <Grid item xs={12} lg={6}>
            <CollapseWrapper title={title} description={description}>
                <Box paddingTop={'12px'}>
                    <Table
                        size="small"
                        scroll={{ y: 200 }}
                        dataSource={dataSource?.data.productEnquirySiteVisits}
                        columns={columns}
                        pagination={false}
                        summary={(pageData) => {
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row className={cx('total-row-dark')}>
                                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                        {/* enquiry */}
                                        <Table.Summary.Cell index={1}>
                                            <div className="text-right">
                                                {formatNumber(dataSource?.data.enquiry, 0, false, true)}
                                            </div>
                                        </Table.Summary.Cell>
                                        {/* site visit */}
                                        <Table.Summary.Cell index={5}>
                                            <div className="text-right">{dataSource?.data.siteVisit}</div>
                                        </Table.Summary.Cell>
                                        {/* demand GLA */}
                                        <Table.Summary.Cell index={3}>
                                            <div className="text-right " style={{ whiteSpace: 'nowrap' }}>
                                                {formatNumber(dataSource?.data.demand, 0, false, true)}
                                            </div>
                                        </Table.Summary.Cell>
                                        {/* deal */}
                                        <Table.Summary.Cell index={4}>
                                            <div className="text-right">
                                                {formatNumber(dataSource?.data.deal, 0, false, true)}
                                            </div>
                                        </Table.Summary.Cell>
                                        {/* rate Enquiry */}
                                        <Table.Summary.Cell index={2}>
                                            <div className="text-right">
                                                {formatNumber(
                                                    Number(dataSource?.data.rateEnquiry) * 100,
                                                    0,
                                                    false,
                                                    true,
                                                )}
                                                %
                                            </div>
                                        </Table.Summary.Cell>
                                        {/* rate Site */}
                                        <Table.Summary.Cell index={6}>
                                            <div className="text-right">
                                                {formatNumber(Number(dataSource?.data.rateSite) * 100, 0, false, true)}%
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={7}>
                                            <div className="text-right">
                                                {formatNumber(dataSource?.data.available, 0, false, true)}
                                            </div>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                </Box>
            </CollapseWrapper>
        </Grid>
    );
};

export default React.memo(TableProductEnquiry);
