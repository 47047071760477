import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '~/pages/Home';
import Dashboard from '~/pages/Dashboard';
import Highcharts from 'highcharts';
import ProductPage from './pages/Product/product';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);

function App() {
    return (
        <BrowserRouter>
            <UserAgentProvider ua={window.navigator.userAgent}>
                <Routes>
                    <Route path="/*" element={<Home />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="product/:id" element={<ProductPage />} />
                </Routes>
            </UserAgentProvider>
        </BrowserRouter>
    );
}

export default App;
