import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import style from "../../pages/Dashboard/Dashboard.module.scss";
import classNames from "classnames/bind";
import { Select } from "antd";
import { formatNumber } from "~/utils/formatHelper";
import { ProductService } from "~/services/ProductService";
import { DualAxes, Line } from "@ant-design/charts";
import { LocationModel } from "~/models/CommonModel";
import { enquiryLeaseExpiryStatusModel } from "~/models/TypeChartModel";
import mock from "~/pages/Dashboard/mock";
import CollapseWrapper from "../CollapseWrapper";

import { EChartsOption } from "echarts";
import ReactECharts from "echarts-for-react";
const { Option } = Select;

export declare type IntervalGeometryLabelPosition =
  | "top"
  | "bottom"
  | "middle"
  | "left"
  | "right";
interface ChartWeightedProps {
  title: string;
  listLocation: LocationModel[];
}

const ChartWeighted = (props: ChartWeightedProps) => {
  const { title, listLocation } = props;
  const cx = classNames.bind(style);
  const [
    locationDefaultLeasingExpiry,
    setLocationDefaultLeasingExpiry,
  ] = useState<string>("");
  const [leasingExpiry, setLeasingExpiry] = useState<
    enquiryLeaseExpiryStatusModel[]
  >([]);
  const colorDefaults = [
    "#A5A5A5",
    "#274822",
    "#90a955",
    "#4f772d",
    "#DFDBD6",
    "#A5A5A5",
  ];
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };
  const [windowSize, setWindowSize] = useState(getWindowSize());
  // weighted
  const config9 = {
    // data: leasingExpiry,
    data: [leasingExpiry, leasingExpiry],
    xField: "year",
    yField: ["totalPLC", "plc"],
    // color: '#274822',
    color: colorDefaults,
    meta: {
      plc: {
        alias: "PLC EXPIRED(sqm)",
        formatter: (v: any) => {
          return `${formatNumber(v, 0)}`;
        },
      },
      totalPLC: {
        alias: "Total PLC(sqm)",
        formatter: (v: any) => {
          return `${formatNumber(v, 0)}`;
        },
      },
    },
    toolTip: {
      autoHide: true,
    },

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
        style: {
          fontSize: windowSize.innerWidth > 2500 ? 25 : 15,
        },
      },
    },
    // yAxis: {
    //     plc: {
    //         tickCount: 0,
    //     },
    //     totalPLC: {
    //         tickCount: 0,
    //     },
    // },
    geometryOptions: [
      {
        geometry: "column",
        color: "#A5A5A5",
        label: {},
      },
      {
        geometry: "line",
        lineStyle: {
          lineWidth: 2,
        },
        color: "#274822",
        label: { position: "bottom" as IntervalGeometryLabelPosition },
      },
    ],

    // meta: {
    //     GLA: {
    //         alias: 'PLC(sqm)',
    //         formatter: (v: any) => {
    //             return `${formatNumber(v, 2)}`;
    //         },
    //     },
    //     PLC: {
    //         alias: 'PLC EXPIRED',
    //         formatter: (v: any) => {
    //             return `${formatNumber(v, 2)}`;
    //         },
    //     },
    // },
  };

  var option: EChartsOption;
  //   Option của chart
  option = {
    color: colorDefaults,
    tooltip: {
      trigger: "axis",

      textStyle: {
        fontFamily: "inherit",
        fontSize: 13,
      },
      borderRadius: 3,
      borderWidth: 0,
      shadowOffsetX: 0,
      shadowOffsetY: 1,
      shadowBlur: 3,
      shadowColor: "rgba(0, 0, 0, 0.25)",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#DFDBD6",
          color: "black",
          fontFamily: "Brown",
          fontSize: 13,
          fontWeight: 400,
        },
      },
    },
    legend: {
      textStyle: {
        fontFamily: "Brown",
        fontSize: 13,
        color: "black",
        fontWeight: "bold",
      },
      right: 0,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      // name: 'Year',
      // boundaryGap: [0, 0.01],
      data: leasingExpiry.length ? leasingExpiry.map((i) => i.year) : [],
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        fontSize: 13,
        fontFamily: "Brown",
        fontWeight: 400,
        color: "#77838F",
        lineHeight: 12,
      },
      nameTextStyle: {
        align: "center",
        fontSize: 14,
        fontFamily: "Brown",
        fontWeight: "bolder",
        color: "#77838F",
        lineHeight: 12,
      },
      nameLocation: "start",
    },
    yAxis: [
      {
        type: "value",
        // name: 'Giá trị',
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: "#F2F2F2",
          },
        },
        axisTick: {
          show: false,
        },
        nameTextStyle: {
          color: "#000",
        },
        axisLabel: {
          show: false,
          fontSize: 13,
          fontFamily: "Brown",
          fontWeight: 400,
          color: "#77838F",
          lineHeight: 12,
        },
      },
      {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#F2F2F2",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          fontSize: 13,
          fontFamily: "Brown",
          fontWeight: 400,
          color: "#77838F",
          lineHeight: 12,
        },
      },
    ],
    series: [
      {
        name: "Total PLC(sqm)",
        type: "bar",
        tooltip: {
          valueFormatter: function (value) {
            return formatNumber(value as number, 0) + "";
          },
        },
        label: {
          show: true,
          position: "outside",
          valueAnimation: true,
          fontFamily: "Brown",
          fontSize: 13,
          fontWeight: 500,
          color: "black",
          formatter: function (value) {
            return value.data ? formatNumber(value.data as number, 0) + "" : "";
          },
        },
        // data: [292, 952, 320],
        data: leasingExpiry.length ? leasingExpiry.map((i) => i.totalPLC) : [],
      },

      {
        name: "PLC EXPIRED(sqm)",
        type: "line",
        yAxisIndex: 1,
        symbolSize: 8,
        symbol: "circle",
        itemStyle: {
          borderColor: "#FFFFFF",
          borderWidth: 1,
        },

        tooltip: {
          valueFormatter: function (value) {
            return formatNumber(value as number, 0) + "";
          },
        },
        label: {
          show: true,
          position: "top",
          valueAnimation: true,
          fontFamily: "Brown",
          fontSize: 13,
          fontWeight: 500,
          color: "#4A4F57",
          formatter: function (value) {
            return value.data ? formatNumber(value.data as number, 0) + "" : "";
          },
        },
        // data: [2620935140, 96332874002, 2007326110],
        data: leasingExpiry.length ? leasingExpiry.map((i) => i.plc) : [],
      },
    ],
  };
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    // get Lease Expiry Status
    const getLeaseExpiryStatus = async () => {
      const api = new ProductService();
      const result = await api.getLeasingExpiry(locationDefaultLeasingExpiry);
      if (result) {
        setLeasingExpiry(result.data);
      }
    };
    getLeaseExpiryStatus();
  }, [locationDefaultLeasingExpiry]);

  return (
    <Grid item xs={12} lg={6}>
      <CollapseWrapper title={title}>
        <Box paddingTop={"12px"}>
          <Select
            onChange={(value) => setLocationDefaultLeasingExpiry(value)}
            placeholder="Project/Location"
            style={{ width: 200 }}
          >
            <Option label="All" value={null}>
              All
            </Option>
            {listLocation.map((item, index) => {
              return (
                <Option key={index} label={item.value} value={item.key}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
          {/* <DualAxes {...config9} /> */}
          <ReactECharts option={option} />
        </Box>
      </CollapseWrapper>
    </Grid>
  );
};

export default React.memo(ChartWeighted);
