import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import classNames from 'classnames/bind';
import { Select } from 'antd';
import { formatNumber } from '~/utils/formatHelper';
import { ProductService } from '~/services/ProductService';
import { HistoricalSalesModel } from '~/models/TypeChartModel';
import { Column, DualAxes } from '@ant-design/charts';
import { LocationModel } from '~/models/CommonModel';
import mock from '~/pages/Dashboard/mock';
import { each, groupBy } from '@antv/util';
import CollapseWrapper from '../CollapseWrapper';

import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { YEAR } from '~/utils/constants';
const { Option } = Select;

interface ChartHistoricalSalesProps {
    title: string;
    listLocation: LocationModel[];
}

type HistoricalSales = {
    ipa: number;
    target: number;
    quarter: string;
};

const ChartHistoricalSales = (props: ChartHistoricalSalesProps) => {
    const { title, listLocation } = props;
    const cx = classNames.bind(style);
    const [yearHistorial, setYearHistorial] = useState(new Date().getFullYear());
    const [locationDefaultHistorial, setLocationDefaultHistorial] = useState('');
    // historical sales
    const [historialSales, setHistorialSales] = useState<HistoricalSales[]>([]);
    const colorDefaults = ['#90a955', '#274822', '#4f772d', '#DFDBD6', '#A5A5A5'];
    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    };
    const [windowSize, setWindowSize] = useState(getWindowSize());

    var option: EChartsOption;
    //   Option của chart
    option = {
        color: colorDefaults,
        tooltip: {
            trigger: 'axis',

            textStyle: {
                fontFamily: 'inherit',
                fontSize: 13,
            },
            borderRadius: 3,
            borderWidth: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 1,
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#DFDBD6',
                    color: 'black',
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 400,
                },
            },
            // Custom chart sử dụng className (tailwind)
            // className:
            //     '[&>div>:nth-child(1)>:nth-child(2)>div>:nth-child(1)>:nth-child(1)]:!rounded-[1px] ' +
            //     '[&>div>:nth-child(1)>:nth-child(2)>div>:nth-child(1)>:nth-child(1)]:!w-[19px] ' +
            //     '[&>div>:nth-child(1)>:nth-child(2)>div>:nth-child(1)>:nth-child(1)]:!h-[12px] ' +
            //     '[&>div>:nth-child(1)>:nth-child(1)]:!font-semibold ' +
            //     '[&>div>:nth-child(1)>:nth-child(1)]:!text-textColor',
        },
        legend: {
            textStyle: {
                fontFamily: 'Brown',
                fontSize: 13,
                color: 'black',
                fontWeight: 'bold',
            },
            right: 0,
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            name: 'Quarter',
            // boundaryGap: [0, 0.01],
            data: historialSales.length ? historialSales.map((i) => i.quarter) : [],
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                fontSize: 13,
                fontFamily: 'Brown',
                fontWeight: 400,
                color: '#77838F',
                lineHeight: 12,
            },
            nameTextStyle: {
                padding: [0, -60, -20, 0],
                align: 'right',
                verticalAlign: 'bottom',
                fontSize: 14,
                fontFamily: 'Brown',
                fontWeight: 'bolder',
                color: '#77838F',
                lineHeight: 12,
            },
            nameLocation: 'start',
        },
        yAxis: [
            {
                type: 'value',
                // name: 'Giá trị',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#F2F2F2',
                    },
                },
                axisTick: {
                    show: false,
                },
                nameTextStyle: {
                    color: '#000',
                },
                axisLabel: {
                    show: false,
                    fontSize: 13,
                    fontFamily: 'Brown',
                    fontWeight: 400,
                    color: '#77838F',
                    lineHeight: 12,
                },
            },
            {
                type: 'value',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#F2F2F2',
                    },
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                    fontSize: 13,
                    fontFamily: 'Brown',
                    fontWeight: 400,
                    color: '#77838F',
                    lineHeight: 12,
                },
            },
        ],
        series: [
            {
                name: 'IPA GLA(sqm)',
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                label: {
                    show: true,
                    position: 'outside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: 'black',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
                // data: [292, 952, 320],
                data: historialSales.length ? historialSales.map((i) => i.ipa) : [],
            },

            {
                name: 'TARGET GLA(sqm)',
                type: 'line',
                yAxisIndex: 1,
                symbolSize: 8,
                symbol: 'circle',
                itemStyle: {
                    borderColor: '#FFFFFF',
                    borderWidth: 1,
                },

                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                label: {
                    show: true,
                    position: 'top',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: '#4A4F57',
                    formatter: function (value) {
                        return formatNumber(value.data as number, 0) + '';
                    },
                },
                // data: [2620935140, 96332874002, 2007326110],
                data: historialSales.length ? historialSales.map((i) => i.target) : [],
            },
        ],
    };

    // FUNCTIONS ==================
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    useEffect(() => {
        // getHistorialSales
        const getHistorialSales = async () => {
            const api = new ProductService();
            const result = await api.GetHistoricalSales(locationDefaultHistorial, yearHistorial);
            if (result) {
                const temp = result.data.map((item, indx) => {
                    return {
                        ipa: item.ipa,
                        target: item.target,
                        quarter: `${item.quarter}`,
                    };
                });
                setHistorialSales(temp);
            }
        };
        getHistorialSales();
    }, [locationDefaultHistorial, yearHistorial]);

    const defaultYear = new Date().getFullYear();

    return (
        <Grid item xs={12} lg={6}>
            <CollapseWrapper title={title}>
                <Box paddingTop={'12px'}>
                    <div>
                        <Select
                            onChange={(value) => setLocationDefaultHistorial(value)}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            placeholder="Project/Location"
                            style={{ width: 200, marginRight: '20px' }}
                        >
                            <Option label="All" value="">
                                All
                            </Option>
                            {listLocation.map((item, indx) => {
                                return (
                                    <Option key={indx} label={item.value} value={item.key}>
                                        {item.value}
                                    </Option>
                                );
                            })}
                        </Select>
                        <Select
                            onChange={(value) => {
                                setYearHistorial(Number(value));
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            defaultValue={defaultYear}
                            placeholder="Year"
                            style={{ width: 200 }}
                        >
                            {YEAR.map((item, indx) => {
                                return (
                                    <Option key={indx} label={item} value={item}>
                                        {item.value}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                    {/* @ts-ignore */}
                    {/* <DualAxes {...config} /> */}
                    <ReactECharts option={option} />
                </Box>
            </CollapseWrapper>
        </Grid>
    );
};

export default React.memo(ChartHistoricalSales);
