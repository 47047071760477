// Dev
const APIURL = 'https://kcn-api-dev.isdcorp.vn/api/v1';
const PRODUCT_DETAILS_URL = 'https://kcn-crm-dev.isdcorp.vn/Sale/Product/Edit/';

// Production
// const APIURL = 'https://kcn-api.isdcorp.vn/api/v1';
// const PRODUCT_DETAILS_URL = 'https://kcn-crm.isdcorp.vn/Sale/Product/Edit/';

export const constants = {
    API_URL: APIURL,
    PRODUCT_DETAILS_URL: PRODUCT_DETAILS_URL,
};

export const colorDefaults = ['#274822', '#90a955', '#4f772d', '#DFDBD6', '#A5A5A5'];
export const YEAR = [
    {
        value: 2020,
    },
    {
        value: 2021,
    },
    {
        value: 2022,
    },
    {
        value: 2023,
    },
    {
        value: 2024,
    },
    {
        value: 2025,
    },
    {
        value: 2026,
    },
    {
        value: 2027,
    },
    {
        value: 2028,
    },
];
