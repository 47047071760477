import { ReactElement } from 'react';
import './GlobalStyles.scss';

type props = {
    children: ReactElement;
};

function GlobalStyles({ children }: props) {
    return children;
}

export default GlobalStyles;
