import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Table from "antd/es/table";
import { formatNumber } from "~/utils/formatHelper";
import {
  IPASigned,
  IPASignedResponses,
  PercentLeasingsTransaction,
} from "~/models/TypeChartModel";
import type { ColumnsType } from "antd/es/table";
import CollapseWrapper from "../CollapseWrapper";
import moment from "moment";
import { ProductService } from "~/services/ProductService";

interface PercentLeasingsTransactionProps {
  title: string;
  description?: React.ReactNode;
}

const presentLeasingColumns: ColumnsType<PercentLeasingsTransaction> = [
  {
    title: "No.",
    dataIndex: "stt",
    key: "stt",
    width: 70,
    align: "center",
    render(text, record) {
      return {
        children: <div className="list-symbol">{text}</div>,
      };
    },
  },

  {
    title: "Developer",
    dataIndex: "profileName",
    key: "profileName",
    width: 100,
  },
  {
    title: "Project",
    dataIndex: "text1",
    key: "text1",
    width: 100,
  },
  {
    title: "Region",
    dataIndex: "saleOfficeCode",
    key: "saleOfficeCode",
    width: 150,
  },
  {
    title: "Province",
    dataIndex: "text7",
    key: "text7",
    width: 150,
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    width: 100,
  },
  {
    title: "Client",
    dataIndex: "client",
    key: "client",
    width: 100,
  },
  {
    title: "Industry",
    dataIndex: "dropdownList3",
    key: "dropdownList3",
    width: 100,
  },
  {
    title: "Nationality",
    dataIndex: "countryCode",
    key: "countryCode",
    width: 150,
  },
  {
    title: "Lease term",
    dataIndex: "text3",
    key: "text3",
    width: 100,
    align: "right",
  },
  {
    title: "Area (sqm)",
    dataIndex: "area",
    key: "area",
    width: 100,
    align: "right",
    render(value, record, index) {
      return value === "-" ? value : formatNumber(Number(value), 0);
    },
  },
  {
    title: "Asking rents\n(US$/sqm/month)",
    dataIndex: "askingRent",
    key: "askingRent",
    width: 150,
    align: "right",
  },
  {
    title: "Transacted rents\n(US$/sqm/month)",
    dataIndex: "transactedRents",
    key: "transactedRents",
    width: 150,
    align: "right",
  },
  {
    title: "Rent free\n(months)",
    dataIndex: "rentFree",
    key: "rentFree",
    width: 100,
    align: "right",
  },
  {
    title: "Broker",
    dataIndex: "broker",
    key: "broker",
    width: 100,
  },
  {
    title: "Update Date",
    dataIndex: "updateDate",
    key: "updateDate",
    width: 200,
    align: "center",
    render(value, record, index) {
      return value === "-" ? value : moment(value).format("DD/MM/YYYY");
    },
  },
];

const TablePresentLeasingTransaction = (
  props: PercentLeasingsTransactionProps
) => {
  const { title, description } = props;
  // COMPLETED PROJECTS' OCCUPANCY RATIO
  const [presentLeasing, setpresentLeasing] = useState<
    PercentLeasingsTransaction[]
  >([]);

  useEffect(() => {
    const getCompertitorTransaction = async () => {
      const api = new ProductService();
      const result = await api.getCompertitorTransaction();
      if (result) {
        const dataTable = result.map((item, index) => ({
          ...item,
          stt: index + 1,
        }));
        setpresentLeasing(dataTable);
      }
    };
    getCompertitorTransaction();
  }, []);

  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Grid item xs={12} lg={12}>
      <CollapseWrapper title={title} description={description}>
        <Box
          // minHeight={windowSize.innerWidth > 2500 ? 650 : 300}
          paddingTop={"12px"}
          // overflow={'auto'}
        >
          <Table
            size="small"
            columns={presentLeasingColumns}
            rowKey={"stt"}
            dataSource={presentLeasing}
            pagination={false}
            scroll={{ y: windowSize.innerWidth > 2500 ? 650 : 300 }}
          />
        </Box>
      </CollapseWrapper>
    </Grid>
  );
};

export default React.memo(TablePresentLeasingTransaction);
