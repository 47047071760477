export const formatNumber = (num: number | undefined, fixed: number, shorten?: boolean, floor?: boolean) => {
    if (!num) {
        return '0';
    }
    if (!shorten) {
        if (floor) {
            return Math.floor(num)
                .toFixed(0)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        } else {
            return num.toFixed(fixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        }
    } else {
        if (floor) {
            if (num < 1e3) return num + ' ';
            if (num >= 1e3 && num < 1e6) return Math.floor(+(num / 1e3)) + 'k';
            if (num >= 1e6 && num < 1e9) return Math.floor(+(num / 1e6)) + 'M';
            if (num >= 1e9 && num < 1e12) return Math.floor(+(num / 1e9)) + 'B';
            if (num >= 1e12) return Math.floor(+(num / 1e12)) + 'T';
        } else {
            if (num < 1e3) return num + ' ';
            if (num >= 1e3 && num < 1e6) return +(num / 1e3).toFixed(fixed) + 'k';
            if (num >= 1e6 && num < 1e9) return +(num / 1e6).toFixed(fixed) + 'M';
            if (num >= 1e9 && num < 1e12) return +(num / 1e9).toFixed(fixed) + 'B';
            if (num >= 1e12) return +(num / 1e12).toFixed(fixed) + 'T';
        }
    }
};
