import { Collapse, CollapseProps } from 'antd';
import React, { memo, useState } from 'react';
import { ChevronUp } from '~/assets/Icons';
import style from './collapseWrapper.module.scss';
import './collapseWrapper.scss';
import classNames from 'classnames/bind';

const { Panel } = Collapse;

interface CollapseWrapperProps extends CollapseProps {
    children: React.ReactNode;
    title: string;
    subtitle?: string;
    customClassName?: string;
    headerColor?: string;
    titleClassName?: string;
    extra?: React.ReactNode;
    description?: React.ReactNode;
}

const cx = classNames.bind(style);

function CollapseWrapper(props: CollapseWrapperProps) {
    const [active, setActive] = useState<string[] | string>(['']);
    return (
        <Collapse
            {...props}
            onChange={(key) => setActive(key)}
            defaultActiveKey={'1'}
            expandIconPosition={'end'}
            expandIcon={({ isActive }) => {
                // setActive(isActive);
                return (
                    //  <ChevronUp className={`${isActive ? '' : cx('active')} !fill-black/40`} />
                    <ChevronUp
                        style={{ fill: 'rgba(0, 0, 0, 0.4)' }}
                        className={cx(`${isActive ? 'rotate-0' : 'active'}`)}
                    />
                );
            }}
            bordered={props.bordered === undefined ? false : props.bordered}
            className={cx('box-content') + `${props.extra ? ' has-extra-padding' : ''} ${props.className}`}
            style={{ minHeight: active.length ? '100%' : '0%', transitionDuration: '500ms' }}
        >
            <Panel
                header={
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <h2 className={cx('collapse-title')} style={{ lineHeight: '20px' }}>
                            {props.title}{' '}
                            {props.description && (
                                <>
                                    <br />
                                    <span style={{ fontWeight: '500', display: 'grid', fontSize: '13px' }}>
                                        {props.description}
                                    </span>
                                </>
                            )}
                        </h2>
                        {props.subtitle && <p className={cx('collapse-subtitle')}>{props.subtitle}</p>}
                    </div>
                }
                key="1"
                className={cx('rounded')}
                extra={props.extra}
            >
                {props.children}
            </Panel>
        </Collapse>
    );
}

export default memo(CollapseWrapper);
