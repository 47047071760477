import { ActivitiesModel, ProductDetailResponse, chartVNModel } from '~/models/ProductModel';
import {
    ConstructionSalesTrackingResponse,
    CountEnquiriesByDemandResponse,
    DealClose,
    DealCloseBySaleInchargeResponse,
    DealCloseByYearResponse,
    DemandByCountryResponse,
    EnquiryByMarketResponse,
    enquiryLeaseExpiryStatusResponse,
    enquirySiteVisitByYearsResponse,
    HistoricalSalesResponse,
    IPASignedResponses,
    LandbankResponse,
    LeasingStatus,
    NoOfEnquiriesByMonthResponse,
    NoOfEnquiriesBySizeResponse,
    PercentEnquiriesByRegionResponse,
    PercentLeasingsTransaction,
    ProductDetailSummaryResponse,
    productEnquirySiteVisitsResponse,
    projectSummaryResponse,
    ReportGLAResponse,
} from './../models/TypeChartModel';
import { ProductResponse, ProjectModel } from '~/models/ProjectModel';
import { chartTotalGLA, ProductSummary } from '~/models/TypeChartModel';
import { constants } from '~/utils/constants';

export class ProductService {
    private rootURL = `${constants.API_URL}/MasterData/Product`;

    public async getProducts(id: string): Promise<ProductResponse | null> {
        const url = `${this.rootURL}/GetInfomationProject?ProjectId=${id}`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                // console.log(json.data);
                return json as ProductResponse;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    public async getDataChartTotalGLA(): Promise<chartTotalGLA | null> {
        const url = `${constants.API_URL}/Work/ReportGLA/get-data-chart-totalgla`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json.data as chartTotalGLA;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // Get Product Summary
    public async GetProductSummary(): Promise<ProductSummary | null> {
        const url = `${constants.API_URL}/Work/ProductSummary/GetProductSummary`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as ProductSummary;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // Get Product Summary Detail
    public async GetProductDetailSummary(): Promise<ProductDetailSummaryResponse | null> {
        const url = `${constants.API_URL}/Work/ProductSummary/GetDetailProjectSummary`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as ProductDetailSummaryResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // Get COMPLETED PROJECTS' OCCUPANCY RATIO
    public async GetTotalGLAByProjectType(date?: string): Promise<ReportGLAResponse | null> {
        const url = `${constants.API_URL}/Work/ReportGLA/get-totalgla-by-project-type${date ? '?Date=' + date : ''}`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as ReportGLAResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // Get LeasingStatus
    public async GetLeasingStatus(): Promise<LeasingStatus | null> {
        const url = `${constants.API_URL}/Work/ProductSummary/GetLeasingStatus`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as LeasingStatus;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // Get LeasingStatus
    public async GetDealClose(data: any): Promise<DealClose | null> {
        const url = `${constants.API_URL}/Work/SalePerformance/get-deal-close`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as DealClose;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // Get LeasingStatus
    public async GetDealCloseBts(data: any): Promise<DealClose | null> {
        const url = `${constants.API_URL}/Work/SalePerformance/get-deal-close-bts`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as DealClose;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // Sale Performance Historical Sales
    public async GetHistoricalSales(categoryId: string, year?: number): Promise<HistoricalSalesResponse | null> {
        const url = `${constants.API_URL}/Work/SalePerformance/get-historical-sales?${
            categoryId ? `categoryId=${categoryId}&` : ''
        }${year ? `year=${year}` : ''}`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as HistoricalSalesResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // Sale Performance Historical Sales
    public async getProductEnquirySiteVisit(data: any): Promise<productEnquirySiteVisitsResponse | null> {
        const url = `${constants.API_URL}/Work/SalePerformance/get-product-enquiry-site-visit`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as productEnquirySiteVisitsResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // Sale Performance Historical Sales
    public async getEnquirySiteVisitByYear(data: number[]): Promise<enquirySiteVisitByYearsResponse | null> {
        const url = `${constants.API_URL}/Work/SalePerformance/get-enquiry-site-visit-by-year`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as enquirySiteVisitByYearsResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // Weighted Lease Expiry Status
    public async getLeasingExpiry(categoryId: string | null): Promise<enquiryLeaseExpiryStatusResponse | null> {
        const url = `${constants.API_URL}/Work/SalePerformance/get-lease-expiry-status?${
            categoryId ? `categoryId=${categoryId}` : ''
        }`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as enquiryLeaseExpiryStatusResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    public async getProjectSummary(): Promise<projectSummaryResponse | null> {
        const url = `${constants.API_URL}/Work/ProductSummary/GetProjectSummary`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as projectSummaryResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // no of enquiries by month
    public async getNoOfEnquiriesByMonth(data: object): Promise<NoOfEnquiriesByMonthResponse | null> {
        const url = `${constants.API_URL}/Report/EnquiryAnalysis/GetNoOfEnquiriesByMonth`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as NoOfEnquiriesByMonthResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    //no of enquiries by size
    public async getNoOfEnquiriesBySize(data: object): Promise<NoOfEnquiriesBySizeResponse | null> {
        const url = `${constants.API_URL}/Report/EnquiryAnalysis/GetNoOfEnquiriesBySize`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as NoOfEnquiriesBySizeResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    public async getDemandByCountry(data: object): Promise<DemandByCountryResponse | null> {
        const url = `${constants.API_URL}/Report/EnquiryAnalysis/GetDemandByCountry`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as DemandByCountryResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // Get Count Enquiry By Demand
    public async GetCountEnquiryByDemand(data: object): Promise<CountEnquiriesByDemandResponse | null> {
        const url = `${constants.API_URL}/Report/EnquiryAnalysis/GetCountEnquiryByDemand`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as CountEnquiriesByDemandResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // Deal close by year
    public async getDealCloseByYear(data: any): Promise<DealCloseByYearResponse | null> {
        const url = `${constants.API_URL}/Work/SalePerformance/get-deal-close-by-year`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as DealCloseByYearResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // Deal close bts by year
    public async getDealCloseBTSByYear(data: any): Promise<DealCloseByYearResponse | null> {
        const url = `${constants.API_URL}/Work/SalePerformance/get-deal-close-bts-by-year`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as DealCloseByYearResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // Deal close by sale incharge
    public async getDealCloseBySaleIncharge(data: any): Promise<DealCloseBySaleInchargeResponse | null> {
        const url = `${constants.API_URL}/Work/SalePerformance/get-deal-close-by-sale-incharge`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as DealCloseBySaleInchargeResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // Deal close bts by sale incharge
    public async getDealCloseBTSBySaleIncharge(data: any): Promise<DealCloseBySaleInchargeResponse | null> {
        const url = `${constants.API_URL}/Work/SalePerformance/get-deal-close-bts-by-sale-incharge`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    // console.log(json.data);
                    return json as DealCloseBySaleInchargeResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // Chart No. of enquiries by Market
    public async getEnquiryByMarket(data: any): Promise<EnquiryByMarketResponse | null> {
        const url = `${constants.API_URL}/Report/EnquiryAnalysis/get-enquiry-by-market`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    return json as EnquiryByMarketResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // Chart No. of enquiries by Market
    public async getEnquiryByIndustry(data: any): Promise<EnquiryByMarketResponse | null> {
        const url = `${constants.API_URL}/Report/EnquiryAnalysis/get-enquiry-by-industry`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    return json as EnquiryByMarketResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // Chart ConstructionSalesTracking
    public async getConstructionSalesTracking(): Promise<ConstructionSalesTrackingResponse | null> {
        const url = `${constants.API_URL}/Work/ProductSummary/get-construction-sales-tracking`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    return json as ConstructionSalesTrackingResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // Chart Percent Enquiries By Region
    public async getPercentEnquiriesByRegion(
        fromDate?: string,
        toDate?: string,
    ): Promise<PercentEnquiriesByRegionResponse | null> {
        const url = `${constants.API_URL}/Work/ProductSummary/get-percent-enquiries-by-region?${
            fromDate ? 'FromDate=' + fromDate + '&' : ''
        }${toDate ? 'ToDate=' + toDate : ''}`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    return json as PercentEnquiriesByRegionResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    // Chart landbank
    public async getLandbankStatus(): Promise<LandbankResponse | null> {
        const url = `${constants.API_URL}/Work/ProductSummary/get-landbank-report`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    return json as LandbankResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    //Màn hình product
    public async getproductById(id: string): Promise<ProductDetailResponse | null> {
        const url = `${constants.API_URL}/MasterData/Product/get-product-by-id?ProductId=${id}`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    return json as ProductDetailResponse;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
    public async getActivitiesByProduct(id: string): Promise<ActivitiesModel | null> {
        const url = `${constants.API_URL}/MasterData/Product/get-activities-by-product?ProductId=${id}`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    return json.data as ActivitiesModel;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    // lấy data chart Việt Nam
    public async getMapReport(): Promise<chartVNModel[]> {
        const url = `${constants.API_URL}/Work/ProductSummary/map-report`;

        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    return json.data as chartVNModel[];
                }
                return [];
            })
            .catch((e) => {
                console.log(e);
                return [];
            });

        return result;
    }

    // phần B Table ipa signed
    public async getIPAsSignedReport(data: number[]): Promise<IPASignedResponses | null> {
        const url = `${constants.API_URL}/Work/SalePerformance/ipa-signed-report`;

        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    return json.data as IPASignedResponses;
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }

    public async getCompertitorTransaction(): Promise<PercentLeasingsTransaction[] | null> {
        const url = `${constants.API_URL}/Report/EnquiryAnalysis/get-compertitor-transaction`;

        const result = await fetch(url, {
            method: 'POST',
            // body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then((text) => text.json())
            .then((json: any) => {
                if (json.isSuccess) {
                    return json.data as PercentLeasingsTransaction[];
                }
                return null;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return result;
    }
}
