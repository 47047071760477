import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import Table from 'antd/es/table';
import { formatNumber } from '~/utils/formatHelper';
import { ProductService } from '~/services/ProductService';
import { ReportGLAModel, ReportGLAResponse } from '~/models/TypeChartModel';
import type { ColumnsType } from 'antd/es/table';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import classNames from 'classnames/bind';
import { DatePicker, Select } from 'antd';
import CollapseWrapper from '../CollapseWrapper';

interface TableCompletedProjectProps {
    title: string;
    description?: React.ReactNode;
}

const projectOccupancyColumns: ColumnsType<ReportGLAModel> = [
    {
        title: 'Phase',
        dataIndex: 'projectName',
        width: '30%',
        render(text, record) {
            return {
                children: <div className="list-symbol">{text}</div>,
            };
        },
        onCell: (record) => ({
            style: {
                color: record.handoverOccRate > 80 ? '#274822' : record.handoverOccRate < 50 ? 'red' : '#90a955',
                textAlign: 'left',
                fontWeight: 'bold',
            },
        }),
    },

    {
        title: 'Total GLA (sqm)',
        dataIndex: 'totalGLA',
        sorter: (a, b) => a.totalGLA - b.totalGLA,
        render(text, record) {
            return {
                children: <div>{formatNumber(text, 0, false, true)}</div>,
            };
        },
        onCell: (record) => ({
            style: {
                color: record.handoverOccRate > 80 ? '#274822' : record.handoverOccRate < 50 ? 'red' : '#90a955',
                textAlign: 'right',
                fontWeight: 'bold',
            },
        }),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render(text, record) {
            return {
                children: (
                    <div
                        dangerouslySetInnerHTML={{ __html: text === 0 || text === null ? '-&ensp;' : `${text}` }}
                    ></div>
                ),
            };
        },
        onCell: (record) => ({
            style: {
                color: record.handoverOccRate > 80 ? '#274822' : record.handoverOccRate < 50 ? 'red' : '#90a955',
                textAlign: 'left',
                fontWeight: 'bold',
            },
        }),
    },
    {
        title: 'Committed Occ. rate(%)',
        dataIndex: 'commitedOccRate',
        render(text, record) {
            return {
                children: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: text === 0 || text === null ? '-&ensp;' : `${formatNumber(text, 0, false, true)}%`,
                        }}
                    ></div>
                ),
            };
        },
        onCell: (record) => ({
            style: {
                color: record.handoverOccRate > 80 ? '#274822' : record.handoverOccRate < 50 ? 'red' : '#90a955',
                textAlign: 'right',
                fontWeight: 'bold',
            },
        }),
    },

    {
        title: 'Handover Occ. rate (%)',
        dataIndex: 'handoverOccRate',
        sorter: (a, b) => a.handoverOccRate - b.handoverOccRate,
        render(text, record) {
            return {
                children: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: text === 0 || text === null ? '-&ensp;' : `${formatNumber(text, 0, false, true)}%`,
                        }}
                    ></div>
                ),
            };
        },
        onCell: (record) => ({
            style: {
                color: record.handoverOccRate > 80 ? '#274822' : record.handoverOccRate < 50 ? 'red' : '#90a955',
                textAlign: 'right',
                fontWeight: 'bold',
            },
        }),
    },
];

const TableCompletedProject = (props: TableCompletedProjectProps) => {
    const { title, description } = props;
    const cx = classNames.bind(style);
    // COMPLETED PROJECTS' OCCUPANCY RATIO
    const [totalGLAByProjectType, setTotalGLAByProjectType] = useState<ReportGLAResponse>();

    const getTotalGLAByProjectType = async (date?: string) => {
        const api = new ProductService();
        const result = await api.GetTotalGLAByProjectType(date);
        if (result) {
            setTotalGLAByProjectType(result);
        }
    };

    useEffect(() => {
        getTotalGLAByProjectType();
    }, []);

    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    };
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return (
        <Grid item xs={12} lg={12}>
            <CollapseWrapper
                title={title}
                description={description}
                collapsible="icon"
                extra={
                    // <Select
                    //     mode="tags"
                    //     size="middle"
                    //     placeholder="Year"
                    //     style={{ width: windowSize.innerWidth > 2500 ? 500 : 300 }}
                    //     // onChange={handleChange}
                    //     options={year}
                    //     onClick={(event) => {
                    //         event.stopPropagation();
                    //     }}
                    //     allowClear
                    // />
                    <DatePicker
                        onChange={async (date, dateString) => await getTotalGLAByProjectType(dateString)}
                        onClick={(e) => e.stopPropagation()}
                    />
                }
            >
                <Box
                    // minHeight={windowSize.innerWidth > 2500 ? 650 : 300}
                    paddingTop={'12px'}
                    // overflow={'auto'}
                >
                    <Table
                        size="small"
                        columns={projectOccupancyColumns}
                        dataSource={totalGLAByProjectType?.data.totalGLAByProjectTypeList}
                        pagination={false}
                        scroll={{ y: windowSize.innerWidth > 2500 ? 650 : 300 }}
                        summary={(pageData) => {
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row className={cx('total-row-dark')}>
                                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                        {/* <Table.Summary.Cell index={1}></Table.Summary.Cell> */}
                                        <Table.Summary.Cell index={1}>
                                            <div className="text-right">
                                                {formatNumber(totalGLAByProjectType?.data.totalGLA, 0, false, true)}
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>
                                            <div className="text-right"></div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>
                                            <div className="text-right">
                                                {formatNumber(
                                                    totalGLAByProjectType?.data.totalCommittedRate,
                                                    0,
                                                    false,
                                                    true,
                                                )}
                                                %
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>
                                            <div className="text-right">
                                                {/* {formatNumber(totalHandover, 2)}% */}
                                                {formatNumber(
                                                    totalGLAByProjectType?.data.totalHandoverRate,
                                                    0,
                                                    false,
                                                    true,
                                                )}
                                                %
                                            </div>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                </Box>
            </CollapseWrapper>
        </Grid>
    );
};

export default React.memo(TableCompletedProject);
