import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { formatNumber } from '~/utils/formatHelper';
import classNames from 'classnames/bind';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import { ProductService } from '~/services/ProductService';
import { EnquiryByMarketModel, projectSummaryModel } from '~/models/TypeChartModel';
import TableDetailProjectSummary from '../TableReport/tableDetailProjectSummary';
import CollapseWrapper from '../CollapseWrapper';

import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import { DatePicker } from 'antd';
import moment from 'moment';

interface ChartEnquiriesByIndustryProps {
    title: string;
    searchPIC: string | null;
    dateCompleteFrom: string | null;
    dateCompleteTo: string | null;
    project: string | null;
}

const ChartEnquiriesByIndustry = (props: ChartEnquiriesByIndustryProps) => {
    var option: echarts.EChartsOption;
    const { title, searchPIC, dateCompleteFrom, dateCompleteTo, project } = props;
    const colorDefaults = [
        '#0F4D2A',
        '#A8C0B1',
        '#BF9000',
        '#ED7D31',
        '#9DC3E6',
        '#255E91',
        '#843C0C',
        '#FFD966',
        '#7F6000',
        '#7F7F7F',
    ];

    //=================== state ======================
    const [noOfenquiriesByIndustry, setNoOfenquiriesByIndustry] = useState<EnquiryByMarketModel[]>([]);
    // From date => to date (sử dụng cho chart, chỉ áp dụng cho chart này)
    const [dateFrom, setdateFrom] = useState<string | null>(null);
    const [dateTo, setdateTo] = useState<string | null>(null);
    //   =================================================

    const data = [
        { value: 65, name: 'Electronics' },
        { value: 28, name: 'Warehouse' },
        { value: 16, name: 'Supporting Industries' },
        { value: 16, name: 'Metal' },
        { value: 13, name: 'Packaging' },
        { value: 9, name: 'Packaging 1' },
        { value: 9, name: 'Packaging 2' },
        { value: 8, name: 'Packaging 3' },
        { value: 7, name: 'Packaging 4' },
        { value: 7, name: 'Packaging 5' },
        { value: 6, name: 'Packaging 6' },
        { value: 4, name: 'Packaging 7' },
        { value: 3, name: 'Packaging 8' },
        { value: 3, name: 'Packaging 9 ' },
        { value: 3, name: 'Packaging 10' },
        { value: 3, name: 'Packaging 11' },
        { value: 3, name: 'Packaging 12' },
        { value: 2, name: 'Packaging 13' },
        { value: 2, name: 'Packaging 14' },
        { value: 1, name: 'Laboritory' },
        { value: 1, name: 'Custommer gooods' },
        { value: 1, name: 'Animal feed' },
        { value: 1, name: 'Heavy Industries' },
        { value: 1, name: 'TBC' },
    ];

    option = {
        tooltip: {
            trigger: 'axis',

            textStyle: {
                fontFamily: 'inherit',
                fontSize: 13,
            },
            borderRadius: 3,
            borderWidth: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 1,
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#DFDBD6',
                    color: 'black',
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 400,
                },
            },
        },
        legend: {
            textStyle: {
                fontFamily: 'Brown',
                fontSize: 13,
                color: 'black',
                fontWeight: 'bold',
            },
            itemGap: 40,
        },

        xAxis: {
            type: 'value',
            show: false,
        },
        yAxis: {
            type: 'category',
            // data: [
            //     'Electronics',
            //     'Warehouse',
            //     'Supporting Industries',
            //     'Metal',
            //     'Packaging',
            //     'Packaging 1',
            //     'Packaging 2',
            //     'Packaging 3',
            //     'Packaging 4',
            //     'Packaging 5',
            //     'Packaging 6',
            //     'Packaging 7',
            //     'Packaging 8',
            //     'Packaging 9 ',
            //     'Packaging 10',
            //     'Packaging 11',
            //     'Packaging 12',
            //     'Packaging 13',
            //     'Packaging 14',
            //     'Laboritory',
            //     'Custommer gooods',
            //     'Animal feed',
            //     'Heavy Industries',
            //     'TBC',
            // ],
            data: noOfenquiriesByIndustry.map((item) => item.name),
            axisTick: {
                show: false,
            },
            axisLabel: {
                hideOverlap: false,
                fontFamily: 'Brown',
            },
            axisLine: {
                show: true,
            },
        },
        grid: {
            top: 0,
            left: '3%',
            right: '4%',
            bottom: 0,
            containLabel: true,
        },
        color: '#385722',
        series: [
            {
                type: 'bar',
                // barWidth: '60%',

                label: {
                    show: true,
                    position: 'outside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 12,
                    fontWeight: 500,
                    color: 'black',
                },
                data: noOfenquiriesByIndustry,
            },
        ],
    };

    useEffect(() => {
        // gọi api lấy data chart
        const getEnquiryByIndustry = async () => {
            const api = new ProductService();
            const result = await api.getEnquiryByIndustry({
                pic: searchPIC,
                dateCompleteFrom: dateCompleteFrom,
                dateCompleteTo: dateCompleteTo,
                project: project,
            });
            if (result) {
                setNoOfenquiriesByIndustry(result.data.sort((a, b) => b.value - a.value));
            }
        };

        getEnquiryByIndustry();
    }, [searchPIC, dateCompleteFrom, dateCompleteTo, project]);
    // set lại value date from to khi dateComplete thay đổi
    // dateComplete lấy làm mặc định cho các chart nếu datefrom/dateTo không chỉ sửa thì lấy mặc định là dateCompleteFrom/to
    useEffect(() => {
        setdateFrom(dateCompleteFrom);
        setdateTo(dateCompleteTo);
    }, [dateCompleteFrom, dateCompleteTo]);

    return (
        <Grid item xs={12} lg={6}>
            <CollapseWrapper title={title}>
                <Box paddingTop={'12px'}>
                    {/* <div style={{ display: 'flex', gap: '10px', paddingBottom: '12px' }}>
                        <DatePicker.RangePicker
                            onChange={async (values: any, dateStrings) => {
                                setdateFrom(dateStrings[0] ? dateStrings[0] : null);
                                setdateTo(dateStrings[1] ? dateStrings[1] : null);
                                const api = new ProductService();
                                const result = await api.getEnquiryByIndustry({
                                    pic: searchPIC,
                                    dateCompleteFrom: dateStrings[0] ? dateStrings[0] : dateCompleteFrom,
                                    dateCompleteTo: dateStrings[1] ? dateStrings[1] : dateCompleteTo,
                                    project: project,
                                });
                                if (result) {
                                    setNoOfenquiriesByIndustry(result.data.sort((a, b) => b.value - a.value));
                                }
                            }}
                            placeholder={['From Date', 'To Date']}
                            value={dateFrom ? [moment(dateFrom), moment(dateTo)] : undefined}
                            picker="date"
                            bordered={true}
                            style={{ fontSize: '20px' }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        />
                    </div> */}
                    <ReactECharts
                        // style={{ minHeight: 500 }}
                        option={option}
                    />
                </Box>
            </CollapseWrapper>
        </Grid>
    );
};

export default React.memo(ChartEnquiriesByIndustry);
