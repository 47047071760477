import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { DatePicker, Select } from 'antd';
import mock from '~/pages/Dashboard/mock';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import classNames from 'classnames/bind';
import { Column } from '@ant-design/charts';
import { EmployeeModel } from '~/models/CommonModel';
import { CommonService } from '~/services/CommonService';
import { ProductService } from '~/services/ProductService';
import { CountEnquiriesByDemandModel } from '~/models/TypeChartModel';
import { each, groupBy } from '@antv/util';
import { formatNumber } from '~/utils/formatHelper';
import CollapseWrapper from '../CollapseWrapper';

import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';

interface ChartLocalExpansionProps {
    title: string;
    listEmployees: EmployeeModel[];
    searchPIC: string | null;
    dateCompleteFrom: string | null;
    dateCompleteTo: string | null;
    project: string | null;
}

const ChartLocalExpansion = (props: ChartLocalExpansionProps) => {
    const cx = classNames.bind(style);
    const { title, listEmployees, searchPIC, dateCompleteFrom, dateCompleteTo, project } = props;

    const [countEnquririesByDemand, setCountEnquririesByDemand] = useState<CountEnquiriesByDemandModel[]>([]);
    const [listMonth, setListMonth] = useState<string[]>([]);
    const [keyMonth, setKeyMonth] = useState<number[]>();
    const [keyYear, setKeyYear] = useState<number>(new Date().getFullYear());
    const [keyPIC, setKeyPIC] = useState('');

    // From date => to date (sử dụng cho chart, chỉ áp dụng cho chart này)
    const [dateFrom, setdateFrom] = useState<string | null>(null);
    const [dateTo, setdateTo] = useState<string | null>(null);
    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    };
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const colorDefaults = ['#274822', '#90a955', '#4f772d', '#DFDBD6', '#A5A5A5'];

    var option: EChartsOption;
    //   Option của chart
    option = {
        color: colorDefaults,
        tooltip: {
            trigger: 'axis',

            textStyle: {
                fontFamily: 'inherit',
                fontSize: 13,
            },
            borderRadius: 3,
            borderWidth: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 1,
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#DFDBD6',
                    color: 'black',
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 400,
                },
            },
            formatter(params) {
                const dataParams = params as any[];

                // nội dung
                let tooltipContent = '';
                dataParams.forEach((dataParam) => {
                    tooltipContent += `<div style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: ${
                        dataParam.color
                    }; margin-right: 5px;"></div>${dataParam.seriesName}: <strong>${formatNumber(
                        dataParam.data,
                        0,
                    )}</strong><br>`;
                });

                return tooltipContent
                    ? dataParams[0].axisValueLabel +
                          ', ' +
                          formatNumber(
                              countEnquririesByDemand.filter(
                                  (value, index, self) =>
                                      index === self.findIndex((other) => value.month === other.month),
                              )[dataParams[0].dataIndex].total,
                              0,
                          ) +
                          '<br>' +
                          tooltipContent
                    : '';
            },
        },
        legend: {
            textStyle: {
                fontFamily: 'Brown',
                fontSize: 13,
                color: 'black',
                fontWeight: 'bold',
            },
            right: '2%',
        },
        label: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            // data: countEnquririesByDemand.length
            //     ? Array.from(new Set(countEnquririesByDemand.map((i) => i.month)))
            //     : [],
            data: listMonth,
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                fontSize: 13,
                fontFamily: 'Brown',
                fontWeight: 400,
                color: '#77838F',
                lineHeight: 12,
                hideOverlap: false,
                interval: 0,
                rotate: 30,
            },
        },
        yAxis: [
            {
                type: 'value',
                // name: 'Giá trị',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#F2F2F2',
                    },
                },
                axisTick: {
                    show: false,
                },
                nameTextStyle: {
                    color: '#000',
                },
                axisLabel: {
                    show: false,
                    fontSize: 13,
                    fontFamily: 'Brown',
                    fontWeight: 400,
                    color: '#77838F',
                    lineHeight: 12,
                },
            },
            {
                type: 'value',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#F2F2F2',
                    },
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                    fontSize: 13,
                    fontFamily: 'Brown',
                    fontWeight: 400,
                    color: '#77838F',
                    lineHeight: 12,
                },
            },
        ],
        series: [
            {
                name: countEnquririesByDemand.length ? countEnquririesByDemand[0].type : '',
                type: 'bar',
                stack: 'one',
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                label: {
                    show: true,
                    position: 'inside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: 'white',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
                // data: [292, 952, 320],
                data: countEnquririesByDemand.length
                    ? countEnquririesByDemand
                          .filter((v) => v.type === countEnquririesByDemand[0].type)
                          .map((i) => i.value)
                    : [],
            },
            {
                name: countEnquririesByDemand.length ? countEnquririesByDemand[1].type : '',
                type: 'bar',
                stack: 'one',
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                label: {
                    show: true,
                    position: 'inside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: 'black',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
                // data: [292, 952, 320],
                data: countEnquririesByDemand.length
                    ? countEnquririesByDemand
                          .filter((v) => v.type === countEnquririesByDemand[1].type)
                          .map((i) => i.value)
                    : [],
            },
            {
                name: countEnquririesByDemand.length ? countEnquririesByDemand[2].type : '',
                type: 'bar',
                stack: 'one',
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                label: {
                    show: true,
                    position: 'inside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: 'black',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
                // data: [292, 952, 320],
                data: countEnquririesByDemand.length
                    ? countEnquririesByDemand
                          .filter((v) => v.type === countEnquririesByDemand[2].type)
                          .map((i) => i.value)
                    : [],
            },
            {
                type: 'bar',
                stack: 'one',
                barMinHeight: 5,
                tooltip: {
                    show: false,
                },
                color: 'transparent',
                label: {
                    show: true,
                    position: 'outside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 'bold',
                    color: 'black',

                    formatter: function (value) {
                        const group = countEnquririesByDemand
                            .filter(
                                (value, index, self) =>
                                    index === self.findIndex((other) => value.month === other.month),
                            )
                            .map((i) => i.total);
                        return group[value.dataIndex] ? formatNumber(group[value.dataIndex], 0) + '' : '';
                    },
                },
                // Lấy ra số lượng type yAxis
                data: countEnquririesByDemand
                    .filter((value, index, self) => index === self.findIndex((other) => value.month === other.month))
                    .map((i) => 1),
            },
        ],
    };

    // FUNCTIONS ==================
    const handleChange = (value: number[]) => {
        // setKeyWeek(value);
        setKeyMonth(value);
    };
    const handleChangeEmployees = (value: string, option: EmployeeModel | EmployeeModel[]) => {
        if (!Array.isArray(option)) {
            setKeyPIC(option.key);
        }
    };
    const handleChangeSearch = async (value: string) => {
        const api = new CommonService();
        await api.getListEmployees(value);
    };
    const handleChangeSearchWeek = async (value: string) => {
        const api = new CommonService();
        const result = await api.getListWeek(Number(value));
    };
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const GetCountEnquiryByDemand = async () => {
            const api = new ProductService();
            const result = await api.GetCountEnquiryByDemand({
                pic: searchPIC,
                dateCompleteFrom: dateCompleteFrom,
                dateCompleteTo: dateCompleteTo,
                project: project,
                month: keyMonth,
                year: keyYear,
            });
            if (result) {
                const data = result.data;
                const mount = data.length ? Array.from(new Set(data.map((i) => i.month))) : [];
                const monthArray = Array.from({ length: Math.max(mount.length, 24) }, (_, index) => {
                    return mount[index] || '';
                });
                setListMonth(monthArray);
                setCountEnquririesByDemand(data);
            }
        };

        GetCountEnquiryByDemand();
    }, [keyYear, keyMonth, searchPIC, dateCompleteFrom, dateCompleteTo, project]);

    // set lại value date from to khi dateComplete thay đổi
    // dateComplete lấy làm mặc định cho các chart nếu datefrom/dateTo không chỉ sửa thì lấy mặc định là dateCompleteFrom/to
    useEffect(() => {
        setdateFrom(dateCompleteFrom);
        setdateTo(dateCompleteTo);
    }, [dateCompleteFrom, dateCompleteTo]);

    return (
        <Grid item xs={12} lg={12}>
            <CollapseWrapper title={title}>
                <Box paddingTop={'12px'}>
                    {/* <div style={{ display: 'flex', gap: '10px' }}>
                      
                        <DatePicker.RangePicker
                            onChange={async (values: any, dateStrings) => {
                                setdateFrom(dateStrings[0] ? dateStrings[0] : null);
                                setdateTo(dateStrings[1] ? dateStrings[1] : null);
                                const api = new ProductService();
                                const result = await api.GetCountEnquiryByDemand({
                                    pic: searchPIC,
                                    dateCompleteFrom: dateStrings[0] ? dateStrings[0] : dateCompleteFrom,
                                    dateCompleteTo: dateStrings[1] ? dateStrings[1] : dateCompleteTo,
                                    project: project,
                                    month: keyMonth,
                                    year: keyYear,
                                });
                                if (result) {
                                    setCountEnquririesByDemand(result.data);
                                }
                            }}
                            placeholder={['From Date', 'To Date']}
                            value={dateFrom ? [moment(dateFrom), moment(dateTo)] : undefined}
                            picker="date"
                            bordered={true}
                            style={{ fontSize: '20px' }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        />
                    </div> */}

                    <ReactECharts option={option} />
                    <div style={{ width: '100%', textAlign: 'end', fontSize: '14px', fontWeight: 'bolder' }}>
                        Maximine: 24 columns
                    </div>
                </Box>
            </CollapseWrapper>
        </Grid>
    );
};

export default React.memo(ChartLocalExpansion);
