import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Table from "antd/es/table";
import { formatNumber } from "~/utils/formatHelper";
import { ProductService } from "~/services/ProductService";
import { IPASigned, IPASignedResponses } from "~/models/TypeChartModel";
import type { ColumnsType } from "antd/es/table";
import style from "../../pages/Dashboard/Dashboard.module.scss";
import classNames from "classnames/bind";
import { DatePicker, Select } from "antd";
import CollapseWrapper from "../CollapseWrapper";
import moment from "moment";

interface TableIPAsSignedProps {
  title: string;
  year: number[];
  description?: React.ReactNode;
}

const ipaSignedColumns: ColumnsType<IPASigned> = [
  {
    title: "No",
    dataIndex: "stt",
    width: 100,
    align: "center",
    render(text, record) {
      return {
        children: <div className="list-symbol">{text}</div>,
      };
    },
    onCell: (record) => ({
      style: {
        textAlign: "center",
      },
    }),
  },

  {
    title: "Client",
    dataIndex: "client",

    onCell: (record) => ({
      style: {
        textAlign: "left",
      },
    }),
  },
  {
    title: "Project",
    dataIndex: "project",

    onCell: (record) => ({
      style: {
        textAlign: "left",
      },
    }),
  },
  {
    title: "GLA (sqm)",
    dataIndex: "gla",
    width: 150,
    render(text, record) {
      return {
        children: formatNumber(Number(text), 0),
      };
    },
    align: "right",

    // onCell: (record) => ({
    //     style: {
    //         textAlign: 'right',
    //     },
    // }),
  },

  {
    title: "IPA date",
    dataIndex: "ipaDate",
    render(text, record) {
      return text !== undefined && text !== "-"
        ? moment(text).format("DD/MM/YYYY")
        : "";
    },
    // onCell: (record) => ({
    //     style: {
    //         textAlign: 'center',
    //     },
    // }),
    width: 200,
    align: "right",
  },
  {
    title: "Agency",
    dataIndex: "agency",
    onCell: (record) => ({
      style: {},
    }),
  },
  {
    title: "Start date",
    dataIndex: "startDate",
    render(text, record) {
      return text !== undefined && text !== "-" && text !== null
        ? moment(text).format("DD/MM/YYYY")
        : "";
    },
    //
  },
];

const TableIPAsSigned = (props: TableIPAsSignedProps) => {
  const { title, description, year } = props;
  const cx = classNames.bind(style);
  // COMPLETED PROJECTS' OCCUPANCY RATIO
  const [ipaSigned, setIPASigned] = useState<IPASignedResponses | null>(null);

  const getIPASigned = async (date?: number[]) => {
    const api = new ProductService();
    const result = await api.getIPAsSignedReport(date || []);

    if (result) {
      setIPASigned(result);
    }
  };

  useEffect(() => {
    getIPASigned(year);
  }, [year]);

  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Grid item xs={12} lg={12}>
      <CollapseWrapper title={title} description={description}>
        <Box
          // minHeight={windowSize.innerWidth > 2500 ? 650 : 300}
          paddingTop={"12px"}
          // overflow={'auto'}
        >
          <Table
            size="small"
            columns={ipaSignedColumns}
            dataSource={ipaSigned?.ipaSignedResponses}
            pagination={false}
            scroll={{ y: windowSize.innerWidth > 2500 ? 650 : 300 }}
            summary={(pageData) => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row className={cx("total-row-dark")}>
                    <Table.Summary.Cell
                      colSpan={2}
                      index={0}
                      align="center"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={1} index={1} align="left">
                      <span style={{ fontWeight: "bold" }}>Total</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} className="cx">
                      <div
                        className=""
                        style={{
                          // borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
                          fontWeight: "bold",
                          textAlign: "right",
                          paddingLeft: 8,
                        }}
                      >
                        {formatNumber(ipaSigned?.total, 0)} sqm
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      colSpan={3}
                      index={3}
                      className="cx"
                    ></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </Box>
      </CollapseWrapper>
    </Grid>
  );
};

export default React.memo(TableIPAsSigned);
