import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { formatNumber } from "~/utils/formatHelper";
import classNames from "classnames/bind";
import { ProductService } from "~/services/ProductService";
import {
  EnquiryByMarketModel,
  projectSummaryModel,
} from "~/models/TypeChartModel";
import CollapseWrapper from "../CollapseWrapper";

import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { DatePicker } from "antd";
import moment from "moment";

interface ChartEnquiriesByMarketProps {
  title: string;
  searchPIC: string | null;
  // listEmployees: EmployeeModel[];
  dateCompleteFrom: string | null;
  dateCompleteTo: string | null;
  project: string | null;
}

const ChartEnquiriesByMarket = (props: ChartEnquiriesByMarketProps) => {
  const { title, searchPIC, dateCompleteFrom, dateCompleteTo, project } = props;
  var option: echarts.EChartsOption;
  const colorDefaults = [
    "#BD912D",
    "#ED7D2F",
    "#245E92",
    "#9DC3E5",
    "#F23841",
    "#EFA3A7",
    "#70AD45",
    "#A9D388",
    "#41376b",
    "#b56089",
    "#6538a1",
    "#9753ca",
    "#dfadf6",
    "#047fff",
    "#dff04c",
    "#04ba70",
  ];

  //=================== state ======================
  const [noOfenquiriesByMarket, setNoOfenquiriesByMarket] = useState<
    EnquiryByMarketModel[]
  >([]);
  // From date => to date (sử dụng cho chart, chỉ áp dụng cho chart này)
  const [dateFrom, setdateFrom] = useState<string | null>(null);
  const [dateTo, setdateTo] = useState<string | null>(null);
  // ================================================
  const data = [
    { value: 167, name: "China" },
    { value: 95, name: "Vietnam" },
    { value: 88, name: `Int'l` },
    { value: 70, name: "Japan" },
    { value: 73, name: "Korea" },
    { value: 36, name: "EU" },
    { value: 20, name: "US" },
  ];

  option = {
    tooltip: {
      trigger: "axis",

      textStyle: {
        fontFamily: "inherit",
        fontSize: 13,
      },
      borderRadius: 3,
      borderWidth: 0,
      shadowOffsetX: 0,
      shadowOffsetY: 1,
      shadowBlur: 3,
      shadowColor: "rgba(0, 0, 0, 0.25)",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#DFDBD6",
          color: "black",
          fontFamily: "Brown",
          fontSize: 13,
          fontWeight: 400,
        },
      },
    },
    legend: {
      textStyle: {
        fontFamily: "Brown",
        fontSize: 13,
        color: "black",
        fontWeight: "bold",
      },
      itemGap: 40,
    },

    xAxis: {
      type: "value",
      show: false,
    },
    yAxis: {
      type: "category",
      data: noOfenquiriesByMarket.map((item) => item.name),
      axisTick: {
        show: false,
      },
      axisLabel: {
        hideOverlap: false,
        fontFamily: "Brown",
      },
      axisLine: {
        show: true,
      },
    },
    grid: {
      top: 0,
      left: "3%",
      right: "4%",
      bottom: 0,
      containLabel: true,
    },
    color: colorDefaults,
    series: [
      {
        type: "bar",
        // barWidth: '60%',

        label: {
          show: true,
          position: "outside",
          valueAnimation: true,
          fontFamily: "Brown",
          fontSize: 12,
          fontWeight: 500,
          color: "black",
        },
        data: noOfenquiriesByMarket,
      },
    ],
  };

  useEffect(() => {
    // gọi api lấy data chart
    const getEnquiryByMarket = async () => {
      const api = new ProductService();
      const result = await api.getEnquiryByMarket({
        pic: searchPIC,
        dateCompleteFrom: dateCompleteFrom,
        dateCompleteTo: dateCompleteTo,
        project: project,
      });
      if (result) {
        const data = result.data;
        data.forEach((d, index) => {
          Object.assign(d, { itemStyle: { color: colorDefaults.at(index) } });
        });
        setNoOfenquiriesByMarket(data);
      }
    };

    getEnquiryByMarket();
  }, [searchPIC, dateCompleteFrom, dateCompleteTo, project]);
  // set lại value date from to khi dateComplete thay đổi
  // dateComplete lấy làm mặc định cho các chart nếu datefrom/dateTo không chỉ sửa thì lấy mặc định là dateCompleteFrom/to
  useEffect(() => {
    setdateFrom(dateCompleteFrom);
    setdateTo(dateCompleteTo);
  }, [dateCompleteFrom, dateCompleteTo]);
  return (
    <Grid item xs={12} lg={6}>
      <CollapseWrapper title={title}>
        <Box paddingTop={"12px"}>
          {/* <div style={{ display: 'flex', gap: '10px', paddingBottom: '12px' }}>
                        <DatePicker.RangePicker
                            onChange={async (values: any, dateStrings) => {
                                setdateFrom(dateStrings[0] ? dateStrings[0] : null);
                                setdateTo(dateStrings[1] ? dateStrings[1] : null);
                                const api = new ProductService();
                                const result = await api.getEnquiryByMarket({
                                    pic: searchPIC,
                                    dateCompleteFrom: dateStrings[0] ? dateStrings[0] : dateCompleteFrom,
                                    dateCompleteTo: dateStrings[1] ? dateStrings[1] : dateCompleteTo,
                                    project: project,
                                });
                                if (result) {
                                    setNoOfenquiriesByMarket(result.data);
                                }
                            }}
                            placeholder={['From Date', 'To Date']}
                            value={dateFrom ? [moment(dateFrom), moment(dateTo)] : undefined}
                            picker="date"
                            bordered={true}
                            style={{ fontSize: '20px' }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        />
                    </div> */}
          <ReactECharts option={option} />
        </Box>
      </CollapseWrapper>
    </Grid>
  );
};

export default React.memo(ChartEnquiriesByMarket);
