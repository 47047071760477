import { Box, Grid } from '@mui/material';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import classNames from 'classnames/bind';
import { formatNumber } from '~/utils/formatHelper';
import Table from 'antd/es/table';
import { productDetailSummary, ProductDetailSummaryResponse } from '~/models/TypeChartModel';
import type { ColumnsType } from 'antd/es/table';
import { Select } from 'antd';
import { ProductService } from '~/services/ProductService';
import React, { useEffect, useMemo, useState } from 'react';

interface TableDetailProjectSummaryProps {
    onToTalChange?: (total: number) => void;
}

const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
};

const TableDetailProjectSummary = (props: TableDetailProjectSummaryProps) => {
    const cx = classNames.bind(style);
    const [detailProjectSummary, setDetailProjectSummary] = useState<productDetailSummary[]>();
    const [totalProjectSum, setTotalProjectSum] = useState<ProductDetailSummaryResponse>();
    // total landbank
    const [totalLandbank, setTotalLandbank] = useState(0);

    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    };
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const columns: ColumnsType<productDetailSummary> = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // width: '9%',
            render(text, record) {
                return {
                    children: <div style={{ whiteSpace: 'nowrap' }}>{text}</div>,
                };
            },
            onCell: (record) => ({
                style: { fontSize: windowSize.innerWidth > 2500 ? '20px' : '20px', padding: '4px 10px' },
            }),
        },
        {
            title: 'Land Bank(sqm)',
            dataIndex: 'landbank',
            key: 'landbank',
            // width: '9%',
            render(text, record) {
                return {
                    children: (
                        <div
                            style={{ whiteSpace: 'nowrap' }}
                            dangerouslySetInnerHTML={{ __html: text === 0 ? '-&ensp;' : `${formatNumber(text, 0)}` }}
                        ></div>
                    ),
                };
            },
            onCell: (record) => ({
                style: {
                    fontSize: windowSize.innerWidth > 2500 ? '20px' : '20px',
                    padding: '4px 10px',
                    textAlign: 'right',
                },
            }),
        },
        {
            title: 'Percentage(%)',
            dataIndex: 'landbankPercent',
            key: 'landbankPercent',
            // width: '9%',
            render(text, record) {
                return {
                    children: (
                        <div
                            style={{ whiteSpace: 'nowrap' }}
                            dangerouslySetInnerHTML={{ __html: text === 0 ? '-&ensp;' : `${formatNumber(text, 2)}` }}
                        ></div>
                    ),
                };
            },
            onCell: (record) => ({
                style: {
                    fontSize: windowSize.innerWidth > 2500 ? '20px' : '20px',
                    padding: '4px 10px',
                    textAlign: 'right',
                },
            }),
        },
    ];

    useEffect(() => {
        const GetDetailProjectSummary = async () => {
            const api = new ProductService();
            const result = await api.GetProductDetailSummary();
            if (result) {
                setDetailProjectSummary(result.data.dataItemResponses);
                setTotalProjectSum(result);
                setTotalLandbank(result.data.totalLandbank);
            }
        };
        GetDetailProjectSummary();
    }, []);
    useEffect(() => {
        if (props.onToTalChange) {
            props.onToTalChange(totalLandbank);
        }
    }, [totalLandbank]);
    return (
        <Box minHeight={180} minWidth={'400px'} boxShadow={'0px 1px 2px rgba(0, 0, 0, 0.25)'} borderRadius={'3px'}>
            <Table
                dataSource={detailProjectSummary}
                style={{
                    borderRadius: '3px',
                }}
                columns={columns}
                pagination={false}
                className={`detailTable`}
                scroll={{}}
                summary={(pageData) => {
                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row className={cx('total-row')}>
                                <Table.Summary.Cell index={0} className="text-left padding-table-foodter">
                                    <div
                                        className="text-center whitespace-nowrap"
                                        style={{ fontSize: windowSize.innerWidth > 2500 ? '25px' : '20px' }}
                                    >
                                        Total
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} align="right" className="padding-table-foodter">
                                    <div style={{ fontSize: windowSize.innerWidth > 2500 ? '25px' : '20px' }}>
                                        {formatNumber(totalProjectSum?.data.totalLandbank, 0)}
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2} align="right" className="padding-table-foodter">
                                    <div style={{ fontSize: windowSize.innerWidth > 2500 ? '25px' : '20px' }}>
                                        {formatNumber(totalProjectSum?.data.totalPercent, 2)}
                                    </div>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    );
                }}
            />
        </Box>
    );
};

export default React.memo(TableDetailProjectSummary);
