import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, CircularProgress, Grid } from '@mui/material';
import { Table, Tabs } from 'antd';
import CustomInputString from '~/components/CustomInput/inputString';

import moment from 'moment';

import classNames from 'classnames/bind';
import style from './product.module.scss';
import './product.scss';

import { ProductDetailModel, ItemModel, ActivitiesModel } from '~/models/ProductModel';
import { ProductService } from '~/services/ProductService';
import { ColumnsType } from 'antd/lib/table';

const columnsSiteVisit: ColumnsType<ItemModel> = [
    {
        title: 'Index',
        dataIndex: 'stt',
        key: 'stt',
        className: 'whitespace-nowrap',
        width: 75,
        // render(text, record) {
        //     return {
        //         children: <div className="list-symbol">{text}</div>,
        //     };
        // },
    },
    {
        title: 'ID',
        dataIndex: 'taskCode',
        key: 'taskCode',
        width: 150,
    },
    {
        title: 'Created by',
        dataIndex: 'createBy',
        key: 'createBy',
    },
    {
        title: 'Executor',
        dataIndex: 'executor',
        key: 'executor',
    },
    {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
    },
    {
        title: 'Phase',
        dataIndex: 'phase',
        key: 'phase',
    },
    {
        title: 'Occupancy status',
        dataIndex: 'occupancyStatus',
        key: 'occupancyStatus',
    },
];

function ProductPage() {
    const { id = '' } = useParams();
    const cx = classNames.bind(style);
    const [loading, setLoading] = useState(true);
    const [dataGeneral, setDataGeneral] = useState<ProductDetailModel | null>(null);
    const [dataActivities, setDataActivities] = useState<ActivitiesModel | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const api = new ProductService();
            // lấy data genaral
            const result = await api.getproductById(id);
            if (result && result.isSuccess) {
                setDataGeneral(result.data);
            }
            // lấy data activities
            const result2 = await api.getActivitiesByProduct(id);
            if (result2) {
                setDataActivities(result2);
            }

            if (result && result2) {
                setLoading(false);
            }
        };
        fetchData();
        (document.body.style as any).zoom = '1.0';
    }, []);
    return (
        <Box padding={'20px'} className={cx('main-wrapper')}>
            {loading ? (
                <div className={cx('loading')}>
                    <CircularProgress color="success" className={cx('progress')} />
                </div>
            ) : (
                <>
                    <h3 className={cx('page-title')}>Item details</h3>
                    <Box className={cx('content')}>
                        <Tabs
                            defaultActiveKey="1"
                            size="small"
                            className=""
                            type="line"
                            items={[
                                {
                                    label: <h3 className={cx('tab-title')}>General</h3>,
                                    key: '1',
                                    children: (
                                        <Grid container spacing={'10px'}>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label htmlFor="productCode" className={cx('form-label')}>
                                                            Item code
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            id="productCode"
                                                            value={dataGeneral?.productCode || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Internal ID</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            id="erpProductCode"
                                                            value={dataGeneral?.erpProductCode || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Item name</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.productName || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Subsidiary</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.subsidiary || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Project/Location</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.parentCategoryName || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Phase</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.categoryName || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Item type</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.productType || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Area (sqm)</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.area || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Item status</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.productStatus || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Land area</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.landArea || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>GFA</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.gfa || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>GLA</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.gla || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Rental fee</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.rentalFee || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Fit out</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.fitOut || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Acquisition date</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={
                                                                dataGeneral?.acquisitionDate
                                                                    ? moment(dataGeneral?.acquisitionDate).format(
                                                                          'DD/MM/YYYY',
                                                                      )
                                                                    : ''
                                                            }
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Land handover date</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={
                                                                dataGeneral?.landHandoverDate
                                                                    ? moment(dataGeneral?.landHandoverDate).format(
                                                                          'DD/MM/YYYY',
                                                                      )
                                                                    : ''
                                                            }
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Start date</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={
                                                                dataGeneral?.start_Date
                                                                    ? moment(dataGeneral?.start_Date).format(
                                                                          'DD/MM/YYYY',
                                                                      )
                                                                    : ''
                                                            }
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>End date</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={
                                                                dataGeneral?.end_Date
                                                                    ? moment(dataGeneral?.end_Date).format('DD/MM/YYYY')
                                                                    : ''
                                                            }
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={12} md={3}>
                                                        <label className={cx('form-label')}>Status</label>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputString
                                                            value={dataGeneral?.status2 || ''}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ),
                                },

                                {
                                    label: <h3 className={cx('tab-title')}>Activities</h3>,
                                    key: '3',
                                    children: (
                                        <Grid container spacing={'20px'}>
                                            <Grid item xs={12}>
                                                <h3>Site visit</h3>
                                                <Table
                                                    size="small"
                                                    scroll={{ x: 1200 }}
                                                    dataSource={dataActivities?.listSiteVisit || []}
                                                    columns={columnsSiteVisit}
                                                    pagination={false}
                                                    className="product-table"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <h3>IPA</h3>
                                                <Table
                                                    size="small"
                                                    scroll={{ x: 1200 }}
                                                    dataSource={dataActivities?.listIPA || []}
                                                    columns={columnsSiteVisit}
                                                    pagination={false}
                                                    className="product-table"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <h3>PLC</h3>
                                                <Table
                                                    size="small"
                                                    scroll={{ x: 1200 }}
                                                    dataSource={dataActivities?.listPLC || []}
                                                    columns={columnsSiteVisit}
                                                    pagination={false}
                                                    className="product-table"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <h3>Sales order</h3>
                                                <Table
                                                    size="small"
                                                    scroll={{ x: 1200 }}
                                                    dataSource={dataActivities?.listSO || []}
                                                    columns={columnsSiteVisit}
                                                    pagination={false}
                                                    className="product-table"
                                                />
                                            </Grid>
                                        </Grid>
                                    ),
                                },
                            ]}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
}

export default ProductPage;
