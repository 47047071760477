import { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import style from './Home.module.scss';
import Header from '~/components/Header';
import Tag from '~/components/Tag';
import { ProductService } from '~/services/ProductService';
import { ProjectModel } from '~/models/ProjectModel';
import { CircularProgress } from '@mui/material';
import { SmileOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';

const cx = classNames.bind(style);

function Home() {
    const [nofi, contextHolder] = notification.useNotification();
    const [project, setProject] = useState<ProjectModel>();
    const [loading, setLoading] = useState(true);

    // Gọi Api
    useEffect(() => {
        const callapi = async () => {
            setLoading(true);
            const startTime = performance.now();
            const queryString = window.location.search;

            // Lấy id từ url
            const urlParams = new URLSearchParams(queryString);
            const id: string = urlParams.get('id') + '';
            // console.log(id);
            const api = new ProductService();
            const result = await api.getProducts(id);
            const endTime = performance.now();
            // Kiểm tra dữ liệu lấy từ api có hay không nếu có thì setProject
            // cho loading
            if (result?.isSuccess) {
                setProject(result.data);
                setTimeout(() => {
                    setLoading(false);
                }, 1000 - endTime - startTime);
            } else {
                nofi.open({
                    message: 'Error message',
                    description: result?.message,
                    duration: null,
                    icon: <SmileOutlined style={{ color: '#108ee9' }} />,
                });
            }
        };
        callapi();
    }, []);

    return (
        <>
            {/* Nếu loading=true thì cho load */}
            {contextHolder}
            {!loading ? (
                <div className={cx('wrapper')}>
                    <Header data={project!} />
                    <div style={{ minHeight: '80vh', display: 'flex', alignItems: 'center' }} className={cx('map')}>
                        <div className={cx('container')}>
                            <div className={cx('content')}>
                                <img src={project!.imageUrl} className={cx('image-bg')} />
                                <Tag data={project!.products} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={cx('loading')}>
                    <CircularProgress color="success" className={cx('progress')} />
                </div>
            )}
        </>
    );
}

export default Home;
