import { Box, Grid } from '@mui/material';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import classNames from 'classnames/bind';
import { formatNumber } from '~/utils/formatHelper';
import Table from 'antd/es/table';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { DealCloseByYearModel, DealCloseByYearResponse } from '~/models/TypeChartModel';
import { Select } from 'antd';
import CollapseWrapper from '../CollapseWrapper';

interface Table1DealCloseByYearProps {
    dataSource: DealCloseByYearModel[] | undefined;
    columns: ColumnsType<DealCloseByYearModel>;
    title: string;
    dataTotal: DealCloseByYearResponse | undefined;
    onChange: (key?: string, newValue?: [number]) => void;
    name: string;
    year: number[];
}

const Table1DealCloseByYear = (props: Table1DealCloseByYearProps) => {
    const { title, dataSource, columns, dataTotal, onChange, name, year: yearValue } = props;
    const cx = classNames.bind(style);

    return (
        <Grid item xs={12} lg={6}>
            <CollapseWrapper title={title}>
                <Box paddingTop={'12px'}>
                    <Table
                        size="small"
                        // style={{ height: '270px' }}
                        scroll={{ y: 200 }}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        summary={(pageData) => {
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row className={cx('total-row-dark')}>
                                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} className="text-right">
                                            <div>{formatNumber(dataTotal?.data.totalGla, 0)}</div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} className="text-right">
                                            <div>{formatNumber(dataTotal?.data.totalDeal, 0)}</div>
                                            {/* {name === 'BTS' ? dataTotal?.data. : dataTotal?.data.rbf_Rbw_RbhGla} */}
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={3} className="text-right">
                                            <div>{formatNumber(dataTotal?.data.totalTarget, 0)}</div>
                                        </Table.Summary.Cell>
                                        {/* <Table.Summary.Cell index={4} className="text-right">
                                            <div>{formatNumber(dataTotal?.data.totalYtdTarget, 0)}</div>
                                        </Table.Summary.Cell> */}
                                        <Table.Summary.Cell index={5} className="text-right">
                                            <div>{formatNumber(dataTotal?.data.totalPercentTarget, 0)}</div>
                                        </Table.Summary.Cell>
                                        {/* <Table.Summary.Cell index={6} className="text-right">
                                            <div>{formatNumber(dataTotal?.data.totalPercentYtdTarget, 0)}</div>
                                        </Table.Summary.Cell> */}
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                </Box>
            </CollapseWrapper>
        </Grid>
    );
};

export default React.memo(Table1DealCloseByYear);
