import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { formatNumber } from '~/utils/formatHelper';
import classNames from 'classnames/bind';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import { ProductService } from '~/services/ProductService';
import { PercentEnquiriesByRegionModel, projectSummaryModel } from '~/models/TypeChartModel';
import TableDetailProjectSummary from '../TableReport/tableDetailProjectSummary';
import CollapseWrapper from '../CollapseWrapper';

import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import { DatePicker } from 'antd';
type Mode = number;
interface TreeNode {
    name: string;
    id: string;
    value: number[];

    children?: TreeNode[];
}
interface ChartPercentageEnquiriesByRegionProps {
    title: string;
}

const ChartPercentageEnquiriesByRegion = (props: ChartPercentageEnquiriesByRegionProps) => {
    var option: echarts.EChartsOption;
    const { title } = props;
    const colorDefaults = [
        '#0F4D2A',
        '#A8C0B1',
        '#BF9000',
        '#ED7D31',
        '#9DC3E6',
        '#255E91',
        '#843C0C',
        '#FFD966',
        '#7F6000',
        '#7F7F7F',
    ];
    // màu sắc của status
    const colorStatus = ['#FFC000', '#92D050', '#C00000'];

    //=================== state ======================
    const [dataChart, setDataChart] = useState<PercentEnquiriesByRegionModel[]>([]);

    function getLevelOption(): echarts.TreemapSeriesOption['levels'] {
        return [
            {
                colorMappingBy: 'index',
                itemStyle: {
                    // borderWidth: 3,
                    gapWidth: 2,
                },
            },
            {
                // colorAlpha: [0.5, 1],
                itemStyle: {
                    gapWidth: 1,
                },
            },
        ];
    }
    function getTooltipFormatter() {
        return function (info: any) {
            return [
                '<div style="font-family: `Brown`" class="tooltip-title">' +
                    echarts.format.encodeHTML(info.name) +
                    ', ' +
                    `${formatNumber(info.value[0], 0)}` +
                    '%</div>',
                '<div style="display: flex ;align-items: center; gap: 10px ;font-family: `Brown` "><div style="width: 10px; height: 10px;border-radius: 50%; background-color: ' +
                    colorStatus[0] +
                    '; display: block"></div> No. of Enquiries: ' +
                    '<strong>' +
                    echarts.format.encodeHTML(`${formatNumber(info.value[1], 0)}`) +
                    '</strong>' +
                    '</div>',
                '<div style="display: flex ;align-items: center; gap: 10px; font-family: `Brown`"><div style="width: 10px; height: 10px;border-radius: 50%; background-color: ' +
                    colorStatus[1] +
                    '; display: block ;font-family: `Brown`"></div> No. of site inspection: ' +
                    '<strong>' +
                    echarts.format.encodeHTML(`${formatNumber(info.value[2], 0)}`) +
                    '</strong>' +
                    '</div>',
                '<div style="display: flex ;align-items: center; gap: 10px ;font-family: `Brown`"><div style="width: 10px; height: 10px;border-radius: 50%; background-color: ' +
                    colorStatus[2] +
                    '; display: block ;font-family: `Brown`"></div> No. of new closed deals: ' +
                    '<strong>' +
                    echarts.format.encodeHTML(`${formatNumber(info.value[3], 0)}`) +
                    '</strong>' +
                    '</div>',
            ].join('');
        };
    }

    option = {
        color: colorDefaults,
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontFamily: 'Brown',
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        legend: {
            type: 'scroll',
            orient: 'horizontal',
            selectedMode: false,
            textStyle: {
                fontFamily: 'Brown',
                fontSize: 13,
                color: 'black',
                fontWeight: 'bold',
            },
            itemGap: 25,
            right: '2%',
        },
        series: [
            {
                type: 'pie',
                radius: [0, 0],
                top: 0,
                itemStyle: {
                    color: (params) => {
                        const colors = colorStatus;
                        return colors[params.dataIndex];
                    },
                },
                label: { show: false },
                emphasis: {
                    scale: false,
                },
                data: [
                    { name: 'No. of Enquiries', value: 0 },
                    { name: 'No. of Site Inspection', value: 0 },
                    { name: 'No. of New Closed Deals', value: 0 },
                ],
            },
            {
                type: 'treemap',
                tooltip: {
                    formatter: getTooltipFormatter(),
                },
                left: 0,
                right: 0,
                label: {
                    position: 'insideTopLeft',
                    verticalAlign: 'top',
                    formatter: function (params: any) {
                        let arr = [
                            '{name|' + params.name + ', }' + '{percent|' + params.value[0] + '%' + ' }',
                            '{hr|}',
                            '{label1| ' +
                                echarts.format.addCommas(params.value[1]) +
                                '}{tab|}' +
                                '{label2| ' +
                                echarts.format.addCommas(params.value[2]) +
                                '}{tab|}' +
                                '{label3| ' +
                                echarts.format.addCommas(params.value[3]) +
                                '}',
                        ];

                        return arr.join('\n');
                    },
                    rich: {
                        label1: {
                            fontSize: 13,
                            backgroundColor: colorStatus[0],
                            color: '#fff',
                            padding: 5,
                            fontFamily: `Brown`,
                        },
                        label2: {
                            fontSize: 13,
                            backgroundColor: colorStatus[1],
                            color: '#fff',
                            padding: 5,
                            fontFamily: `Brown`,
                        },
                        label3: {
                            fontSize: 13,
                            backgroundColor: colorStatus[2],
                            color: '#fff',
                            padding: 5,
                            fontFamily: `Brown`,
                        },
                        name: {
                            fontSize: 12,
                            color: '#fff',
                            // verticalAlign: 'bottom',
                            fontFamily: `Brown`,
                            fontWeight: 'bolder',
                        },
                        percent: {
                            fontSize: 12,
                            color: '#fff',
                            // verticalAlign: 'bottom',
                            fontFamily: `Brown`,
                        },
                        hr: {
                            width: '100%',
                            borderColor: 'transparent',
                            borderWidth: 0.5,
                            height: 0,
                            lineHeight: 10,
                        },
                        tab: {
                            padding: [2, 1],
                        },
                    },
                },
                itemStyle: {
                    borderColor: 'white',
                },
                levels: getLevelOption(),
                selectedMode: false,
                nodeClick: false,
                roam: false,
                data: dataChart,
                breadcrumb: {
                    show: false,
                },
            },
        ],
    };

    const getPercentEnquiriesByRegion = async (fromDate?: string, toDate?: string) => {
        const api = new ProductService();
        const result = await api.getPercentEnquiriesByRegion(fromDate, toDate);
        if (result) {
            setDataChart(result.data);
        }
    };
    useEffect(() => {
        getPercentEnquiriesByRegion();
    }, []);

    return (
        <CollapseWrapper
            title={title}
            collapsible="icon"
            extra={
                <DatePicker.RangePicker
                    placeholder={['From Date', 'To Date']}
                    picker="date"
                    bordered={true}
                    style={{ fontSize: '20px' }}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    onChange={async (values: any, dateStrings) => {
                        await getPercentEnquiriesByRegion(
                            dateStrings[0] ? dateStrings[0] : undefined,
                            dateStrings[1] ? dateStrings[1] : undefined,
                        );
                    }}
                />
            }
        >
            <Box width="100%" style={{ padding: '0px 16px' }}>
                <ReactECharts style={{ minHeight: '500px' }} option={option} />
            </Box>
        </CollapseWrapper>
    );
};

export default React.memo(ChartPercentageEnquiriesByRegion);
