import { Box, Grid } from '@mui/material';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import classNames from 'classnames/bind';
import { formatNumber } from '~/utils/formatHelper';
import Table from 'antd/es/table';
import { DealCloseModel } from '~/models/TypeChartModel';
import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import CollapseWrapper from '../CollapseWrapper';

interface TableDealCloseProps {
    dataSource: DealCloseModel[];
    columns: ColumnsType<DealCloseModel>;
    title: string;
    name: string;
    onChange: (key?: string, newValue?: [number]) => void;
    year: number[];
}

const TableDealClose = (props: TableDealCloseProps) => {
    const { title, dataSource, columns, onChange, name, year: yearValue } = props;
    const cx = classNames.bind(style);

    return (
        <Grid item xs={12} lg={6}>
            <CollapseWrapper title={title}>
                <Box paddingTop={'12px'}>
                    <Table
                        size="small"
                        style={{ height: '270px' }}
                        sticky={true}
                        scroll={{ y: '200px' }}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                    />
                </Box>
            </CollapseWrapper>
        </Grid>
    );
};

export default React.memo(TableDealClose);
