import { useEffect, useState } from 'react';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';

import TagDetail from '../TagDetail';
import classNames from 'classnames/bind';
import style from './Tag.module.scss';
import { ProductModel } from '~/models/ProductModel';
import { ClickAwayListener } from '@mui/material';
import { formatNumber } from '~/utils/formatHelper';
interface ProductInfo {
    data: ProductModel[];
}

const cx = classNames.bind(style);

function Tag({ data }: ProductInfo) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState<PopperPlacementType>();
    const [selectedProduct, setSelectedProduct] = useState<ProductModel>();
    // parse size, polygon, position sang array
    const [dataRender, setDataRender] = useState(
        data
            ? data.map((item) => {
                  return {
                      ...item,
                      size: JSON.parse(item.size),
                      polygon: JSON.parse(item.polygon),
                      position: JSON.parse(item.position),
                  };
              })
            : [],
    );
    // click vô product thì lấy index và detail của product đó
    const handleClick =
        (newPlacement: PopperPlacementType, index: number, area: ProductModel) =>
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            setOpen((prev) => !prev);
            setPlacement(newPlacement);
            setSelectedIndex(index);
            setSelectedProduct(area);
        };

    useEffect(() => {
        if (!anchorEl) {
            setOpen(false);
        } else {
            const isEl = Boolean(anchorEl);
            setOpen((prev) => isEl || !prev);
        }
    }, [anchorEl]);

    // chuyển array polygon sang string với định dạng vd: 'polygon(0% 0%, 100% 0%, 100% 30%, 100% 50%, 100% 50%, 100% 100%, 0% 100%, 0% 50%)'
    const getShape = (area: any) => {
        let text = '';
        area.polygon.forEach((shape: any) => {
            shape.forEach((i: any) => {
                text += i + '% ';
            });
            text += ',';
        });
        text = text.slice(0, text.length - 1);
        return `polygon(${text})`;
    };
    return (
        // component của material ui. Bắt sự kiện lick ra ngoài để ẩn detail
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                    modifiers={[
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 10],
                            },
                        },
                    ]}
                    className={cx('popper-wrapper')}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <TagDetail data={selectedProduct!} />
                            </Paper>
                        </Fade>
                    )}
                </Popper>
                {/* kiểm tra nếu không rỗng thì render */}
                {dataRender.length > 0
                    ? dataRender.map((area: ProductModel, i: number) => {
                          return area.position ? (
                              <button
                                  className={cx('box', open && i === selectedIndex ? 'active' : '')}
                                  key={i}
                                  style={{
                                      top: `${area.position[0]}%`,
                                      left: `${area.position[1]}%`,
                                      width: `${area.size[0]}%`,
                                      height: `${area.size[1]}%`,
                                      filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 1))',
                                  }}
                                  onClick={handleClick('bottom-start', i, area)}
                              >
                                  <div
                                      className={cx('content')}
                                      style={{
                                          clipPath: `${getShape(area)}`,
                                          backgroundColor: `${area.color}`,
                                      }}
                                  ></div>
                                  <div className={cx('content')} style={{ position: 'absolute' }}>
                                      <span className={cx('name')}>
                                          {/* {area.productType ? `${area.productType}-` : null} */}
                                          {area.productName}
                                      </span>
                                      <span className={cx('area')}>
                                          {area.gla ? formatNumber(area.gla, 0) : 0}
                                          m²
                                      </span>
                                  </div>
                              </button>
                          ) : null;
                      })
                    : null}
            </div>
        </ClickAwayListener>
    );
}

export default Tag;
