import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { formatNumber } from '~/utils/formatHelper';
import classNames from 'classnames/bind';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import { ProductService } from '~/services/ProductService';
import { ConstructionSalesTrackingModel, projectSummaryModel } from '~/models/TypeChartModel';
import TableDetailProjectSummary from '../TableReport/tableDetailProjectSummary';
import CollapseWrapper from '../CollapseWrapper';

import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import { types } from 'util';

interface ChartConstructionSalesTrackingProps {
    title: string;
    subTitle?: string;
}

const ChartConstructionSalesTracking = (props: ChartConstructionSalesTrackingProps) => {
    var option: echarts.EChartsOption;
    const { title, subTitle } = props;
    const colorDefaults = [
        '#A6A6A6',
        '#A9D18E',
        '#385722',
        '#9DC3E6',
        '#ED7D31',
        '#255E91',
        '#843C0C',
        '#FFD966',
        '#7F6000',
        '#7F7F7F',
    ];

    //====================== USE STATE ========================
    const [constructionSalesTracking, setConstructionSalesTracking] = useState<ConstructionSalesTrackingModel[]>([]);
    const [listType, setListType] = useState<string[]>([]);
    // lưu danh sách project để hiển thị trục x
    const [listProject, setListProject] = useState<ConstructionSalesTrackingModel[]>([]);
    // lưu danh sách vùng miền
    const [listRegion, setListRegion] = useState<string[]>([]);

    option = {
        color: colorDefaults,
        tooltip: {
            trigger: 'axis',

            textStyle: {
                fontFamily: 'inherit',
                fontSize: 13,
            },
            borderRadius: 3,
            borderWidth: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 1,
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#DFDBD6',
                    color: 'black',
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 400,
                },
            },
            // custom tooltip
            // kiểm tra xem data (project) có bị trống hay k
            // + nếu trống thì đó là separator (không hiện tooltip)
            formatter(params) {
                const dataParams = params as any[];
                // nội dung
                let tooltipContent = '';
                dataParams.forEach((dataParam) => {
                    if (dataParam.data !== '' && dataParam.data !== undefined) {
                        tooltipContent += `<div style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: ${
                            dataParam.color
                        }; margin-right: 5px;"></div>${dataParam.seriesName}: <strong>${formatNumber(
                            dataParam.data,
                            0,
                        )}</strong><br>`;
                    }
                });

                return tooltipContent ? dataParams[0].axisValueLabel + '<br>' + tooltipContent : '';
            },
        },
        legend: {
            textStyle: {
                fontFamily: 'Brown',
                fontSize: 13,
                color: 'black',
                fontWeight: 'bold',
            },
            itemGap: 20,
            right: 0,
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: [0, 0.01],
                // hiển thị tên các project nếu là Separator thì k hiển thị
                data: listProject.map((i) => i.project),
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    hideOverlap: false,
                    fontSize: 13,
                    fontFamily: 'Brown',
                    fontWeight: 400,
                    color: '#77838F',
                    lineHeight: 12,
                    interval: 0,
                    rotate: 30,
                },
                nameTextStyle: {
                    padding: 10,
                    fontSize: 14,
                    fontFamily: 'Brown',
                    fontWeight: 'bolder',
                    color: '#77838F',
                    lineHeight: 12,
                },
                nameLocation: 'start',
            },
        ],
        yAxis: [
            {
                type: 'value',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#F2F2F2',
                    },
                },
                axisTick: {
                    show: false,
                },
                nameTextStyle: {
                    color: '#000',
                },
                axisLabel: {
                    show: false,
                    fontSize: 13,
                    fontFamily: 'Brown',
                    fontWeight: 400,
                    color: '#77838F',
                    lineHeight: 12,
                },
            },
        ],

        series: [
            //làm đường line phân cách
            {
                type: 'bar',
                // lấy data bỏ các project trùng sau đó map ra nếu là phân cách (separator) thì lấy value còn không thì để rỗng
                // data có dạng như sau :  // data: [100000, '', '', '', '', '', '', '', '', '', '', ''],
                data: constructionSalesTracking.length
                    ? constructionSalesTracking
                          .filter(
                              (value, index, self) =>
                                  index === self.findIndex((other) => value.project === other.project),
                          )
                          .map((i) => (i.region === 'Separator' ? i.value : ''))
                    : [],
                // data: [250000, '', '', '', '', '', '', ''],
                itemStyle: {
                    color: 'transparent',
                    borderColor: '#385722',
                    borderWidth: 1,
                },
                barWidth: 1,
                barGap: '0',
                label: {
                    show: true,
                    formatter: function (param) {
                        // dùng list project để lấy region
                        // tạo ra 1 column với chiều rộng bằng 1 và dùng label để làm đường phân cách miền nam bắc
                        return listProject[param.dataIndex].region;
                    },
                    position: 'insideTopLeft',
                    width: 40,
                    padding: 5,
                    color: '#fff',
                    backgroundColor: '#385722',
                    fontFamily: 'Brown',
                },
                tooltip: {
                    show: false,
                },
            },

            {
                name: 'GLA (sqm) ' + listType[0],
                type: 'bar',
                // data: ['', 71408, 0, 0, '', 62798, 0, 44807],
                data: constructionSalesTracking
                    .filter((value, index, self) => value.type === listType[0] || value.region === 'Separator')
                    .map((i) => (i.region === 'Separator' ? '' : i.value)),
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                // color: colorDefaults[0],
                label: {
                    show: true,
                    position: 'insideBottom',
                    align: 'left',
                    rotate: 90,
                    verticalAlign: 'middle',
                    distance: 10,
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: 'black',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
            },
            {
                name: 'GLA (sqm) ' + listType[1],
                type: 'bar',
                // data: ['', 0, 0, 13177, 0, 0, '', 41803, 12214, 0, 0, 0],
                data: constructionSalesTracking
                    .filter((value, index, self) => value.type === listType[1] || value.region === 'Separator')
                    .map((i) => (i.region === 'Separator' ? '' : i.value)),
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                label: {
                    show: true,
                    position: 'insideBottom',
                    align: 'left',
                    rotate: 90,
                    verticalAlign: 'middle',
                    distance: 10,
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: 'black',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
            },
            {
                name: 'GLA (sqm) ' + listType[2],
                type: 'bar',
                data: constructionSalesTracking
                    .filter((value, index, self) => value.type === listType[2] || value.region === 'Separator')
                    .map((i) => (i.region === 'Separator' ? '' : i.value)),
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                label: {
                    show: true,
                    position: 'insideBottom',
                    align: 'left',
                    rotate: 90,
                    verticalAlign: 'middle',
                    distance: 10,
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: 'black',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
            },
        ],
    };

    useEffect(() => {
        const getDataConstruction = async () => {
            const api = new ProductService();
            const result = await api.getConstructionSalesTracking();
            if (result) {
                // lấy danh sách type
                const types = result.data
                    .filter((value, index, self) => index === self.findIndex((other) => value.type === other.type))
                    .map((i) => i.type);
                setListType(types);
                // ==================================================================================================
                // lưu danh sách vùng miền
                setListRegion(
                    result.data
                        .filter(
                            (value, index, self) => index === self.findIndex((other) => value.region === other.region),
                        )
                        .map((i) => i.region),
                );

                // ===================================================================================================
                // tạo ra phân cách giữa các miền
                const data = result.data;
                // lấy danh sách project để hiển thị ra xAxis
                const project = result.data.filter(
                    (value, index, self) => index === self.findIndex((other) => value.project === other.project),
                );
                for (let i = 1; i < project.length; i++) {
                    if (project[i].region !== project[i - 1].region) {
                        // lưu 1 dòng có region = separator , project = index và type = region
                        project.splice(i, 0, { region: project[i].region, project: '', type: '', value: 0 });
                        i++; // Skip the separator
                    }
                }
                project.splice(0, 0, { region: project[0].region, project: '', type: '', value: 0 });
                setListProject(project);
                // ===================================================================================================
                // Tìm giá trị lớn nhất
                const maxValue = Math.max(...data.map((i) => i.value || 0));
                const separator: ConstructionSalesTrackingModel = {
                    region: 'Separator',
                    project: '',
                    type: '',
                    value: Math.ceil(maxValue / 50000) * 50000,
                };
                // Để chèn một đối tượng phân cách vào đầu mỗi khi có một vùng mới,
                //  bạn có thể sử dụng vòng lặp để duyệt qua mảng và kiểm tra khi nào giá trị region thay đổi.
                //  Khi có sự thay đổi, chèn đối tượng phân cách vào vị trí đó.

                for (let i = 1; i < data.length; i++) {
                    if (data[i].region !== data[i - 1].region) {
                        // lưu 1 dòng có region = separator , project = index và type = region
                        data.splice(i, 0, { ...separator, project: i + '', type: data[i].region });
                        i++; // Skip the separator
                    }
                }
                // phân cách đầu tiên nằm ở vị trí thứ 0
                data.splice(0, 0, { ...separator, type: data[0].region });

                setConstructionSalesTracking(data);
            }
        };

        getDataConstruction();
    }, []);

    return (
        <Grid item xs={12} lg={12}>
            <CollapseWrapper title={title} subtitle={subTitle}>
                <Box width="100%" style={{ padding: '6px 16px' }}>
                    <ReactECharts style={{ minHeight: 500 }} option={option} />
                </Box>
            </CollapseWrapper>
        </Grid>
    );
};

export default React.memo(ChartConstructionSalesTracking);
