import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import style from '../../pages/Dashboard/Dashboard.module.scss';
import classNames from 'classnames/bind';
import { DatePicker, Select } from 'antd';
import { ProductService } from '~/services/ProductService';
import { NoOfEnquiriesBySizeModel } from '~/models/TypeChartModel';
import { Column } from '@ant-design/charts';
import { EmployeeModel, SizeModel } from '~/models/CommonModel';
import { CommonService } from '~/services/CommonService';
import { formatNumber } from '~/utils/formatHelper';
import { each, groupBy } from '@antv/util';
import CollapseWrapper from '../CollapseWrapper';

import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
const { Option } = Select;

interface ChartEnquiryBySizeProps {
    title: string;
    searchPIC: string | null;
    listEmployees: EmployeeModel[];
    dateCompleteFrom: string | null;
    dateCompleteTo: string | null;
    project: string | null;
}

const ChartEnquiryBySize = (props: ChartEnquiryBySizeProps) => {
    const cx = classNames.bind(style);
    const { title, listEmployees, searchPIC, dateCompleteFrom, dateCompleteTo, project } = props;
    // useState ===========================================
    const [listSize, setListSize] = useState<SizeModel[]>([]);
    const [noOfenquiriesBySize, setNoOfenquiriesBySize] = useState<NoOfEnquiriesBySizeModel[]>([]);
    const [keySize, setKeySize] = useState<string | null>(null);
    const [currentYears, setCurrentYears] = useState<number[]>([]);

    // From date => to date (sử dụng cho chart, chỉ áp dụng cho chart này)
    const [dateFrom, setdateFrom] = useState<string | null>(null);
    const [dateTo, setdateTo] = useState<string | null>(null);

    const colorDefaults = ['#BD912D', '#ED7D2F', '#245E92', '#9DC3E5', '#F23841', '#EFA3A7', '#70AD45', '#A9D388'];
    const colorDefaults1 = ['#245E92', '#9DC3E5', '#BD912D', '#EFA3A7'];
    const colorDefaults2 = ['#245E92', '#9DC3E5', '#BD912D', '#EFA3A7'];
    const colorDefaults3 = ['#245E92', '#9DC3E5', '#70AD45', '#A9D388'];
    const colorDefaults4 = ['#BD912D', '#245E92', '#F23841', '#70AD45'];
    const colorDefaults5 = ['#ED7D2F', '#9DC3E5', '#EFA3A7', '#A9D388'];
    const colorDefaults6 = ['#245E92', '#BD912D', '#9DC3E5', '#EFA3A7'];
    const colorDefaults7 = ['#BD912D', '#ED7D2F', '#245E92', '#9DC3E5'];
    const colorDefaults8 = ['#BD912D', '#ED7D2F', '#245E92', '#F23841'];
    const colorDefaults9 = ['#BD912D', '#ED7D2F', '#245E92', '#EFA3A7'];
    const colorDefaults10 = ['#BD912D', '#ED7D2F', '#245E92', '#70AD45'];
    const colorDefaults11 = ['#BD912D', '#ED7D2F', '#245E92', '#A9D388'];
    const colorDefaults12 = ['#BD912D', '#ED7D2F', '#9DC3E5', '#F23841'];
    const colorDefaults13 = ['#BD912D', '#ED7D2F', '#9DC3E5', '#EFA3A7'];
    const colorDefaults14 = ['#BD912D', '#ED7D2F', '#9DC3E5', '#70AD45'];
    const colorDefaults15 = ['#BD912D', '#ED7D2F', '#9DC3E5', '#A9D388'];
    const colorDefaults16 = ['#BD912D', '#ED7D2F', '#F23841', '#EFA3A7'];
    const colorDefaults17 = ['#BD912D', '#ED7D2F', '#F23841', '#70AD45'];
    const colorDefaults18 = ['#BD912D', '#ED7D2F', '#F23841', '#A9D388'];
    const colorDefaults19 = ['#BD912D', '#ED7D2F', '#EFA3A7', '#70AD45'];
    const colorDefaults20 = ['#BD912D', '#ED7D2F', '#EFA3A7', '#A9D388'];
    const colorDefaults21 = ['#BD912D', '#ED7D2F', '#70AD45', '#A9D388'];
    const colorDefaults22 = ['#BD912D', '#245E92', '#9DC3E5', '#F23841'];
    const colorDefaults23 = ['#BD912D', '#245E92', '#9DC3E5', '#EFA3A7'];
    const colorDefaults24 = ['#BD912D', '#245E92', '#9DC3E5', '#70AD45'];
    const colorDefaults25 = ['#BD912D', '#245E92', '#9DC3E5', '#A9D388'];
    const colorDefaults26 = ['#BD912D', '#245E92', '#F23841', '#EFA3A7'];
    const colorDefaults27 = ['#BD912D', '#245E92', '#F23841', '#70AD45'];
    const colorDefaults28 = ['#BD912D', '#245E92', '#F23841', '#A9D388'];
    const colorDefaults29 = ['#BD912D', '#245E92', '#EFA3A7', '#70AD45'];
    const colorDefaults30 = ['#BD912D', '#245E92', '#EFA3A7', '#A9D388'];
    const colorDefaults31 = ['#BD912D', '#245E92', '#70AD45', '#A9D388'];
    const colorDefaults32 = ['#BD912D', '#9DC3E5', '#F23841', '#EFA3A7'];
    const colorDefaults33 = ['#BD912D', '#9DC3E5', '#F23841', '#70AD45'];
    const colorDefaults34 = ['#BD912D', '#9DC3E5', '#F23841', '#A9D388'];
    const colorDefaults35 = ['#BD912D', '#9DC3E5', '#EFA3A7', '#70AD45'];
    const colorDefaults36 = ['#BD912D', '#9DC3E5', '#EFA3A7', '#A9D388'];
    const colorDefaults37 = ['#BD912D', '#9DC3E5', '#70AD45', '#A9D388'];
    const colorDefaults38 = ['#BD912D', '#F23841', '#EFA3A7', '#70AD45'];
    const colorDefaults39 = ['#BD912D', '#F23841', '#EFA3A7', '#A9D388'];
    const colorDefaults40 = ['#BD912D', '#F23841', '#70AD45', '#A9D388'];
    const colorDefaults41 = ['#BD912D', '#EFA3A7', '#70AD45', '#A9D388'];
    const colorDefaults42 = ['#ED7D2F', '#245E92', '#9DC3E5', '#F23841'];
    const colorDefaults43 = ['#ED7D2F', '#245E92', '#9DC3E5', '#EFA3A7'];
    const colorDefaults44 = ['#ED7D2F', '#245E92', '#9DC3E5', '#70AD45'];
    const colorDefaults45 = ['#ED7D2F', '#245E92', '#9DC3E5', '#A9D388'];
    const colorDefaults46 = ['#ED7D2F', '#245E92', '#F23841', '#EFA3A7'];
    const colorDefaults47 = ['#ED7D2F', '#245E92', '#F23841', '#70AD45'];
    const colorDefaults48 = ['#ED7D2F', '#245E92', '#F23841', '#A9D388'];
    const colorDefaults49 = ['#ED7D2F', '#245E92', '#EFA3A7', '#70AD45'];
    const colorDefaults50 = ['#ED7D2F', '#245E92', '#EFA3A7', '#A9D388'];
    const colorDefaults51 = ['#ED7D2F', '#245E92', '#70AD45', '#A9D388'];
    const colorDefaults52 = ['#ED7D2F', '#9DC3E5', '#F23841', '#EFA3A7'];
    const colorDefaults53 = ['#ED7D2F', '#9DC3E5', '#F23841', '#70AD45'];
    const colorDefaults54 = ['#ED7D2F', '#9DC3E5', '#F23841', '#A9D388'];
    const colorDefaults55 = ['#ED7D2F', '#9DC3E5', '#EFA3A7', '#70AD45'];
    const colorDefaults56 = ['#ED7D2F', '#9DC3E5', '#EFA3A7', '#A9D388'];
    const colorDefaults57 = ['#ED7D2F', '#9DC3E5', '#70AD45', '#A9D388'];
    const colorDefaults58 = ['#ED7D2F', '#F23841', '#EFA3A7', '#70AD45'];
    const colorDefaults59 = ['#ED7D2F', '#F23841', '#EFA3A7', '#A9D388'];
    const colorDefaults60 = ['#ED7D2F', '#F23841', '#70AD45', '#A9D388'];
    const colorDefaults61 = ['#ED7D2F', '#EFA3A7', '#70AD45', '#A9D388'];
    const colorDefaults62 = ['#245E92', '#9DC3E5', '#F23841', '#EFA3A7'];
    const colorDefaults63 = ['#245E92', '#9DC3E5', '#F23841', '#70AD45'];
    const colorDefaults64 = ['#245E92', '#9DC3E5', '#F23841', '#A9D388'];
    const colorDefaults65 = ['#245E92', '#9DC3E5', '#EFA3A7', '#70AD45'];
    const colorDefaults66 = ['#245E92', '#9DC3E5', '#EFA3A7', '#A9D388'];
    const colorDefaults67 = ['#245E92', '#9DC3E5', '#70AD45', '#A9D388'];
    const colorDefaults68 = ['#245E92', '#F23841', '#EFA3A7', '#70AD45'];
    const colorDefaults69 = ['#245E92', '#F23841', '#EFA3A7', '#A9D388'];
    const colorDefaults70 = ['#245E92', '#F23841', '#70AD45', '#A9D388'];
    const colorDefaults71 = ['#245E92', '#EFA3A7', '#70AD45', '#A9D388'];
    const colorDefaults72 = ['#9DC3E5', '#F23841', '#EFA3A7', '#70AD45'];
    const colorDefaults73 = ['#9DC3E5', '#F23841', '#EFA3A7', '#A9D388'];
    const colorDefaults74 = ['#9DC3E5', '#F23841', '#70AD45', '#A9D388'];
    const colorDefaults75 = ['#9DC3E5', '#EFA3A7', '#70AD45', '#A9D388'];

    const t = [
        ['#245E92', '#9DC3E5', '#BD912D', '#EFA3A7'],
        ['#245E92', '#9DC3E5', '#BD912D', '#EFA3A7'],
        ['#245E92', '#9DC3E5', '#70AD45', '#A9D388'],
        ['#BD912D', '#245E92', '#F23841', '#70AD45'],
        ['#ED7D2F', '#9DC3E5', '#EFA3A7', '#A9D388'],
        ['#245E92', '#BD912D', '#9DC3E5', '#EFA3A7'],
        ['#BD912D', '#ED7D2F', '#245E92', '#9DC3E5'],
        ['#BD912D', '#ED7D2F', '#245E92', '#F23841'],
        ['#BD912D', '#ED7D2F', '#245E92', '#EFA3A7'],
        ['#BD912D', '#ED7D2F', '#245E92', '#70AD45'],
        ['#BD912D', '#ED7D2F', '#245E92', '#A9D388'],
        ['#BD912D', '#ED7D2F', '#9DC3E5', '#F23841'],
        ['#BD912D', '#ED7D2F', '#9DC3E5', '#EFA3A7'],
        ['#BD912D', '#ED7D2F', '#9DC3E5', '#70AD45'],
        ['#BD912D', '#ED7D2F', '#9DC3E5', '#A9D388'],
        ['#BD912D', '#ED7D2F', '#F23841', '#EFA3A7'],
        ['#BD912D', '#ED7D2F', '#F23841', '#70AD45'],
        ['#BD912D', '#ED7D2F', '#F23841', '#A9D388'],
        ['#BD912D', '#ED7D2F', '#EFA3A7', '#70AD45'],
        ['#BD912D', '#ED7D2F', '#EFA3A7', '#A9D388'],
        ['#BD912D', '#ED7D2F', '#70AD45', '#A9D388'],
        ['#BD912D', '#245E92', '#9DC3E5', '#F23841'],
        ['#BD912D', '#245E92', '#9DC3E5', '#EFA3A7'],
        ['#BD912D', '#245E92', '#9DC3E5', '#70AD45'],
        ['#BD912D', '#245E92', '#9DC3E5', '#A9D388'],
        ['#BD912D', '#245E92', '#F23841', '#EFA3A7'],
        ['#BD912D', '#245E92', '#F23841', '#70AD45'],
        ['#BD912D', '#245E92', '#F23841', '#A9D388'],
        ['#BD912D', '#245E92', '#EFA3A7', '#70AD45'],
        ['#BD912D', '#245E92', '#EFA3A7', '#A9D388'],
        ['#BD912D', '#245E92', '#70AD45', '#A9D388'],
        ['#BD912D', '#9DC3E5', '#F23841', '#EFA3A7'],
        ['#BD912D', '#9DC3E5', '#F23841', '#70AD45'],
        ['#BD912D', '#9DC3E5', '#F23841', '#A9D388'],
        ['#BD912D', '#9DC3E5', '#EFA3A7', '#70AD45'],
        ['#BD912D', '#9DC3E5', '#EFA3A7', '#A9D388'],
        ['#BD912D', '#9DC3E5', '#70AD45', '#A9D388'],
        ['#BD912D', '#F23841', '#EFA3A7', '#70AD45'],
        ['#BD912D', '#F23841', '#EFA3A7', '#A9D388'],
        ['#BD912D', '#F23841', '#70AD45', '#A9D388'],
        ['#BD912D', '#EFA3A7', '#70AD45', '#A9D388'],
        ['#ED7D2F', '#245E92', '#9DC3E5', '#F23841'],
        ['#ED7D2F', '#245E92', '#9DC3E5', '#EFA3A7'],
        ['#ED7D2F', '#245E92', '#9DC3E5', '#70AD45'],
        ['#ED7D2F', '#245E92', '#9DC3E5', '#A9D388'],
        ['#ED7D2F', '#245E92', '#F23841', '#EFA3A7'],
        ['#ED7D2F', '#245E92', '#F23841', '#70AD45'],
        ['#ED7D2F', '#245E92', '#F23841', '#A9D388'],
        ['#ED7D2F', '#245E92', '#EFA3A7', '#70AD45'],
        ['#ED7D2F', '#245E92', '#EFA3A7', '#A9D388'],
        ['#ED7D2F', '#245E92', '#70AD45', '#A9D388'],
        ['#ED7D2F', '#9DC3E5', '#F23841', '#EFA3A7'],
        ['#ED7D2F', '#9DC3E5', '#F23841', '#70AD45'],
        ['#ED7D2F', '#9DC3E5', '#F23841', '#A9D388'],
        ['#ED7D2F', '#9DC3E5', '#EFA3A7', '#70AD45'],
        ['#ED7D2F', '#9DC3E5', '#EFA3A7', '#A9D388'],
        ['#ED7D2F', '#9DC3E5', '#70AD45', '#A9D388'],
        ['#ED7D2F', '#F23841', '#EFA3A7', '#70AD45'],
        ['#ED7D2F', '#F23841', '#EFA3A7', '#A9D388'],
        ['#ED7D2F', '#F23841', '#70AD45', '#A9D388'],
        ['#ED7D2F', '#EFA3A7', '#70AD45', '#A9D388'],
        ['#245E92', '#9DC3E5', '#F23841', '#EFA3A7'],
        ['#245E92', '#9DC3E5', '#F23841', '#70AD45'],
        ['#245E92', '#9DC3E5', '#F23841', '#A9D388'],
        ['#245E92', '#9DC3E5', '#EFA3A7', '#70AD45'],
        ['#245E92', '#9DC3E5', '#EFA3A7', '#A9D388'],
        ['#245E92', '#9DC3E5', '#70AD45', '#A9D388'],
        ['#245E92', '#F23841', '#EFA3A7', '#70AD45'],
        ['#245E92', '#F23841', '#EFA3A7', '#A9D388'],
        ['#245E92', '#F23841', '#70AD45', '#A9D388'],
        ['#245E92', '#EFA3A7', '#70AD45', '#A9D388'],
        ['#9DC3E5', '#F23841', '#EFA3A7', '#70AD45'],
        ['#9DC3E5', '#F23841', '#EFA3A7', '#A9D388'],
        ['#9DC3E5', '#F23841', '#70AD45', '#A9D388'],
        ['#9DC3E5', '#EFA3A7', '#70AD45', '#A9D388'],
    ];

    // search PIC individually
    const [PICvalue, setPICvalue] = useState<string | null>(searchPIC);
    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    };
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const annotations: any[] = [];
    each(groupBy(noOfenquiriesBySize, 'size'), (values, k) => {
        const value = values.reduce((a: any, b: any) => a + b.value, 0);
        annotations.push({
            type: 'text',
            position: [k, value],
            content: `${formatNumber(value, 0, true)}`,
            style: {
                textAlign: 'center',
                fontSize: windowSize.innerWidth > 2500 ? 25 : 15,
                fill: 'rgba(0,0,0,0.85)',
                fontWeight: 700,
            },
            offsetY: -10,
            // offsetX: 10,
        });
    });

    var option: EChartsOption;
    //   Option của chart
    option = {
        color: colorDefaults49,
        tooltip: {
            trigger: 'axis',

            textStyle: {
                fontFamily: 'inherit',
                fontSize: 13,
            },
            borderRadius: 3,
            borderWidth: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 1,
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#DFDBD6',
                    color: 'black',
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 400,
                },
            },
        },
        legend: {
            textStyle: {
                fontFamily: 'Brown',
                fontSize: 13,
                color: 'black',
                fontWeight: 'bold',
            },
            right: '2%',
        },
        label: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            // boundaryGap: [0, 0.01],
            // scale: true,
            data: noOfenquiriesBySize.length ? Array.from(new Set(noOfenquiriesBySize.map((i) => i.size))) : [],
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },

            axisLabel: {
                fontSize: 13,
                fontFamily: 'Brown',
                fontWeight: 400,
                color: '#77838F',
                lineHeight: 12,
                hideOverlap: false,
                interval: 0,
                rotate: 30,
            },
        },
        yAxis: [
            {
                type: 'value',
                // name: 'Giá trị',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#F2F2F2',
                    },
                },
                axisTick: {
                    show: false,
                },
                nameTextStyle: {
                    color: '#000',
                },
                axisLabel: {
                    show: false,
                    fontSize: 13,
                    fontFamily: 'Brown',
                    fontWeight: 400,
                    color: '#77838F',
                    lineHeight: 12,
                },
            },
            {
                type: 'value',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#F2F2F2',
                    },
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    fontSize: 13,
                    fontFamily: 'Brown',
                    fontWeight: 400,
                    color: '#77838F',
                    lineHeight: 12,
                },
            },
        ],
        series: [
            {
                name: noOfenquiriesBySize.length ? noOfenquiriesBySize[0].type : '',
                type: 'bar',
                stack: 'one',
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                barWidth: '30%',
                label: {
                    show: true,
                    position: 'inside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: 'white',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
                // data: [292, 952, 320],
                data: noOfenquiriesBySize.length
                    ? noOfenquiriesBySize.filter((v) => v.type === noOfenquiriesBySize[0].type).map((i) => i.value)
                    : [],
            },
            {
                name: noOfenquiriesBySize.length ? noOfenquiriesBySize[1].type : '',
                type: 'bar',
                stack: 'one',
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                barWidth: '30%',
                label: {
                    show: true,
                    position: 'inside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: 'black',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
                // data: [292, 952, 320],
                data: noOfenquiriesBySize.length
                    ? noOfenquiriesBySize.filter((v) => v.type === noOfenquiriesBySize[1].type).map((i) => i.value)
                    : [],
            },
            {
                name: noOfenquiriesBySize.length ? noOfenquiriesBySize[2].type : '',
                type: 'bar',
                stack: 'one',
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                barWidth: '30%',
                label: {
                    show: true,
                    position: 'inside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: 'black',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
                // data: [292, 952, 320],
                data: noOfenquiriesBySize.length
                    ? noOfenquiriesBySize.filter((v) => v.type === noOfenquiriesBySize[2].type).map((i) => i.value)
                    : [],
            },
            {
                name: noOfenquiriesBySize.length ? noOfenquiriesBySize[3].type : '',
                type: 'bar',
                stack: 'one',
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value as number, 0) + '';
                    },
                },
                barWidth: '30%',
                label: {
                    show: true,
                    position: 'inside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 500,
                    color: 'black',
                    formatter: function (value) {
                        return value.data ? formatNumber(value.data as number, 0) + '' : '';
                    },
                },
                // data: [292, 952, 320],
                data: noOfenquiriesBySize.length
                    ? noOfenquiriesBySize.filter((v) => v.type === noOfenquiriesBySize[3].type).map((i) => i.value)
                    : [],
            },
            {
                type: 'bar',
                stack: 'one',
                barMinHeight: 5,
                tooltip: {
                    show: false,
                },
                color: 'transparent',
                barWidth: '30%',
                label: {
                    show: true,
                    position: 'outside',
                    valueAnimation: true,
                    fontFamily: 'Brown',
                    fontSize: 13,
                    fontWeight: 'bold',
                    color: 'black',
                    formatter: function (value) {
                        const group = noOfenquiriesBySize
                            .filter(
                                (value, index, self) => index === self.findIndex((other) => value.size === other.size),
                            )
                            .map((i) => i.total);
                        return formatNumber(group[value.dataIndex], 0) + '';
                    },
                },
                // Lấy ra số lượng type yAxis
                // data: Array.from(new Set(noOfenquiriesBySize.map((s) => s.total))).map((v) => 1),
                data: noOfenquiriesBySize
                    .filter((value, index, self) => index === self.findIndex((other) => value.size === other.size))
                    .map((i) => 1),
            },
        ],
    };

    // FUNCTIONS ==================
    const handleChange = (value: [number]) => {
        setCurrentYears(value);
    };

    const handleChangeSearch = async (value: string) => {
        const api = new CommonService();
        await api.getListEmployees(value);
    };

    const handleChangeEmployees = (value: string, option: EmployeeModel | EmployeeModel[]) => {
        if (!Array.isArray(option)) {
            setPICvalue(option.key);
        }
    };
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        const getListSize = async () => {
            const api = new CommonService();
            const result = await api.getListSize();
            if (result) {
                setListSize(result.data);
            }
        };
        getListSize();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    useEffect(() => {
        const getNoOfEnquiriesBySize = async () => {
            const api = new ProductService();
            const result = await api.getNoOfEnquiriesBySize({
                pic: searchPIC,
                dateCompleteFrom: dateCompleteFrom,
                dateCompleteTo: dateCompleteTo,
                project: project,
                sizeCode: keySize,
                year: currentYears,
            });
            if (result) {
                setNoOfenquiriesBySize(result.data);
            }
        };

        getNoOfEnquiriesBySize();
    }, [searchPIC, keySize, currentYears, dateCompleteFrom, dateCompleteTo, project]);
    // set lại value date from to khi dateComplete thay đổi
    // dateComplete lấy làm mặc định cho các chart nếu datefrom/dateTo không chỉ sửa thì lấy mặc định là dateCompleteFrom/to
    useEffect(() => {
        setdateFrom(dateCompleteFrom);
        setdateTo(dateCompleteTo);
    }, [dateCompleteFrom, dateCompleteTo]);
    return (
        <>
            {/* {t.map((i) => ( */}
            <Grid item xs={12} lg={6}>
                <CollapseWrapper title={title}>
                    <Box paddingTop={'12px'}>
                        {/* <div style={{ display: 'flex', gap: '10px', paddingBottom: '12px' }}>
                                   
            
                                    <DatePicker.RangePicker
                                        onChange={async (values: any, dateStrings) => {
                                            setdateFrom(dateStrings[0] ? dateStrings[0] : null);
                                            setdateTo(dateStrings[1] ? dateStrings[1] : null);
                                            const api = new ProductService();
                                            const result = await api.getNoOfEnquiriesBySize({
                                                pic: searchPIC,
                                                dateCompleteFrom: dateStrings[0] ? dateStrings[0] : dateCompleteFrom,
                                                dateCompleteTo: dateStrings[1] ? dateStrings[1] : dateCompleteTo,
                                                project: project,
                                                sizeCode: keySize,
                                                year: currentYears,
                                            });
                                            if (result) {
                                                setNoOfenquiriesBySize(result.data);
                                            }
                                        }}
                                        placeholder={['From Date', 'To Date']}
                                        value={dateFrom ? [moment(dateFrom), moment(dateTo)] : undefined}
                                        picker="date"
                                        bordered={true}
                                        style={{ fontSize: '20px' }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                    />
                                </div> */}
                        <ReactECharts option={option} />
                    </Box>
                </CollapseWrapper>
            </Grid>
            {/* ))} */}
        </>
    );
};

export default React.memo(ChartEnquiryBySize);
